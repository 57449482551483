import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactECharts from 'echarts-for-react';
import { Select } from '../../../components/inputs';
import { APIWalletStatistics } from '../../../api/backendRoutes';

const Chart = ({
  xAxis,
  yAxis,
  bar = true,
  line = false,
  option,
  notMerge = true,
  lazyUpdate = true,
  onChartReady,
  onEvents,
  theme = '',
  ...rest
}) => {
  const options = useMemo(() => {
    const a = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        itemWidth: 10,
        itemHeight: 10,
        data: ['Clients', 'Agents', 'Partners'],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          axisLine: { color: '#999999' },
          axisTick: { show: false },
          type: 'category',
          data: xAxis,
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: 7,
          itemStyle: {
            emphasis: {
              barBorderRadius: [50, 50],
            },
            normal: {
              barBorderRadius: [50, 50, 50, 50],
            },
          },
          color: '#509FEF',
          emphasis: {
            focus: 'series',
          },
          data: yAxis,
        },
      ],
    };

    return a;
  }, [bar]);

  return (
    <ReactECharts
      option={option || options}
      notMerge={notMerge}
      lazyUpdate={lazyUpdate}
      theme={theme}
      onChartReady={onChartReady}
      onEvents={onEvents}
      opts={rest}
    />
  );
};

function WalletDetailChart() {
  const [stats, setStats] = useState([]);
  const [interval, setInterval] = useState('monthly');
  const { id } = useParams();
  const getWalletStatisctics = async (period) => {
    // eslint-disable-next-line no-useless-catch
    try {
      setStats([]);
      const resp = await APIWalletStatistics({ merchantOrganizationId: id, period });
      setStats(resp.data.data);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getWalletStatisctics(interval);
  }, [interval]);

  const handleChange = (e) => {
    setInterval(e.value);
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h4>Average Transactions</h4>
        <Select
          size="tiny"
          options={[
            { label: 'Monthly', value: 'monthly' },
            { label: 'Yearly', value: 'yearly' },
          ]}
          defaultValue="monthly"
          style={{ minWidth: 80 }}
          padding={0}
          onChange={handleChange}
        />
      </div>
      <div>
        {stats.length > 0 && (
          <>
            {interval === 'monthly' ? (
              <Chart xAxis={stats.map((dt) => dt?.month)} yAxis={stats.map((dt) => dt.count)} />
            ) : (
              <Chart xAxis={stats.map((dt) => `${dt?.year}`)} yAxis={stats.map((dt) => dt.count)} />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default WalletDetailChart;
