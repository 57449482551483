import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import Table from '../../components/Table';
import Button from '../../components/Button';
import { fetchAdminReportsAction } from '../../redux/actions/reports';
import { ROUTES } from '../../components/router/routes/routes';

const StyledServices = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }

  .MuiCheckbox-root {
    margin-right: 10px;
    .MuiSvgIcon-root {
      color: #bbbbbb !important;
    }
  }
`;

const mapStateToProps = ({ reports }) => {
  return {
    fetchingReports: reports.fetchingReports,
    reports: reports.reports,
    meta: reports.reportsMeta,
  };
};
const Services = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { fetchingReports, reports, meta } = useSelector(mapStateToProps);
  const fetchAdminReports = useCallback((params = { limit: 25 }) =>
    dispatch(fetchAdminReportsAction(params), [dispatch])
  );
  useEffect(() => {
    fetchAdminReports();
  }, []);

  return (
    <StyledServices>
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 30,
          }}
        >
          <h5>Service Trail</h5>

          <div style={{ display: 'flex' }}>
            {/* <div style={{ marginRight: 10 }}>
              <Select
                size="tiny"
                options={[{ label: 'Merchants', value: 'merchants' }]}
                defaultValue="merchants"
                style={{ minWidth: 90 }}
                padding={0}
              />
            </div>
            <div style={{ marginRight: 10 }}>
              <Select
                size="tiny"
                options={[{ label: 'Monthly', value: 'monthly' }]}
                defaultValue="monthly"
                style={{ minWidth: 80 }}
                padding={0}
              />
            </div> */}
            <Button value="Download CSV" size="sm" />
          </div>
        </div>

        <Row>
          <Col>
            <Table
              header={[
                {
                  key: 'createdAt',
                  label: 'Date',
                  sort: true,
                  formatter: (createdAt) => (
                    <div>
                      <Moment format="D MMM, YYYY" date={createdAt} />
                      <br />
                      <Moment format="h:mm:ssa" date={createdAt} />
                    </div>
                  ),
                },
                {
                  key: 'firstName',
                  label: 'Users',
                  formatter: (name, { firstName, lastName }) => (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                          <div>{firstName}</div>
                          <div className="text-muted">{lastName}</div>
                        </div>
                      </div>
                    </>
                  ),
                  sort: true,
                },
                { key: 'serviceType', label: 'Service Type', sort: true },
                { key: 'service', label: 'Service', sort: true },
                {
                  key: 'totalDelivered',
                  label: 'Total Delivered',
                  sort: true,
                },
                { key: 'totalReceipients', label: 'Total Receipients', sort: true },
                {
                  key: 'menu',
                  label: '',
                  formatter: (date, record) => (
                    <span
                      className="link"
                      // eslint-disable-next-line no-underscore-dangle
                      onClick={() => history.push(`${ROUTES.SERVICE_REPORT.path}/${record._id}`)}
                    >
                      View
                    </span>
                  ),
                },
              ]}
              filter={{
                Date: [
                  { value: 'Today', label: 'Today' },
                  { value: 'This Week', label: 'This Week' },
                  { value: 'This Month', label: 'This Month' },
                  { value: 'Last 30 Days', label: 'Last 30 Days' },
                  { value: 'This Year', label: 'This Year' },
                  { value: 'Period', label: 'Specific Period' },
                ],
                serviceType: [
                  { value: 'Campaign', label: 'Campaign' },
                  { value: 'Service', label: 'Service' },
                  { value: 'Schedulled Service', label: 'Schedulled Service' },
                ],
                service: [
                  { value: 'Bill Payment', label: 'Bill Payment' },
                  { value: 'Airtime', label: 'Airtime' },
                  { value: 'SMS', label: 'SMS' },
                  { value: 'Data', label: 'Data' },
                ],
                search: 'search',
              }}
              showFilter
              data={reports}
              loading={fetchingReports}
              paginate={Boolean(Object.keys(meta).length)}
              meta={meta}
              filterAction={fetchAdminReports}
            />
          </Col>
        </Row>
      </>
    </StyledServices>
  );
};

export default Services;
