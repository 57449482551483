import { useState } from 'react';
import toast from 'react-hot-toast';
import { APIapproveSendeIdRequest, APIrejectSendeIdRequest } from '../../../api/backendRoutes';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

function SenderIdApproveModal({ show, closeModal, action, row }) {
  const [loading, setLoading] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const handleRejectRequest = async () => {
    try {
      setRejecting(true);
      const resp = await APIrejectSendeIdRequest({
        // eslint-disable-next-line no-underscore-dangle
        senderId: row._id,
      });
      if (resp.data.message === 'ok') {
        toast.success('Sender Id Rejected');
        action();
        closeModal();
      } else {
        toast.error('Error processing request');
        throw resp?.data?.error;
      }
      setRejecting(false);
    } catch (err) {
      toast.error('Error processing request');
      setRejecting(false);
    }
  };

  const handleAcceptRequest = async () => {
    try {
      setLoading(true);
      const resp = await APIapproveSendeIdRequest({
        // eslint-disable-next-line no-underscore-dangle
        senderId: row._id,
      });
      if (resp.data.message === 'ok') {
        toast.success('Sender Id Approved');
        action();
        closeModal();
      } else {
        toast.error('Error processing request');
        throw resp?.data?.error;
      }
      setLoading(false);
    } catch (err) {
      toast.error('Error processing request');
      setLoading(false);
    }
  };
  return (
    <Modal show={show} onClose={closeModal} title="">
      <h3 className="text-center">Confirm Approval</h3>
      <div className="text-secondary my-4">
        <p>
          <span className="font-weight-bold">Name</span>: {row?.name}
        </p>
        <p>
          <span className="font-weight-bold">Merchant</span>: {row?.firstName} {row?.lastName}
        </p>
        <p>
          <span className="font-weight-bold">Email</span>: {row?.email}
        </p>
        <p>
          <span className="font-weight-bold">Request description</span>: {row?.messageSample}
        </p>
      </div>
      {row?.approval_status === 'approved' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0px',
            borderTop: '1px solid #F2F2F2',
            margin: '10px 0px',
          }}
        >
          <Button value="Approved" group="outline_danger" disabled />
        </div>
      ) : row?.approval_status === 'rejected' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0px',
            borderTop: '1px solid #F2F2F2',
            margin: '10px 0px',
          }}
        >
          <Button value="Rejected" group="outline_danger" disabled />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 0px',
            borderTop: '1px solid #F2F2F2',
            margin: '10px 0px',
          }}
        >
          <Button
            disabled={loading || rejecting}
            value="Reject"
            loading={rejecting}
            onClick={handleRejectRequest}
          />
          <Button
            disabled={loading || rejecting}
            value="Approve"
            group="outline_danger"
            loading={loading}
            onClick={handleAcceptRequest}
          />
        </div>
      )}
    </Modal>
  );
}

export default SenderIdApproveModal;
