import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Input, Switch } from '../../../../components/inputs';
import Button from '../../../../components/Button';

const StyledPassword = styled.div`
  width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const Password = () => {
  return (
    <StyledPassword>
      <Row>
        <Col>Change Password</Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Last Name"
            placeholder="Last Name"
            name="last_name"
            type="text"
            validate="required"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Last Name"
            placeholder="Last Name"
            name="last_name"
            type="text"
            validate="required"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            label="Last Name"
            placeholder="Last Name"
            name="last_name"
            type="text"
            validate="required"
          />
        </Col>
      </Row>
      <Row>
        <Col>Two Factor Authentication</Col>
        <Col>
          <Switch />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button value="Save Changes" />
        </Col>
      </Row>
    </StyledPassword>
  );
};

export default Password;
