import Toast from 'react-hot-toast';
import styled from 'styled-components';
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';

// Components
import Button from '../../../../components/Button';
import { Input, PhoneInput } from '../../../../components/inputs';

// Utils
import baseAPI from '../../../../api';
import { errorParser } from '../../../../utilities/helper';
import { updatePartner } from '../../../../api/partners';

const StyledAccordionBody = styled.div`
  padding-bottom: 20px;
`;

const RepresentativeProfileInfo = ({ partner }) => {
  const queryClient = useQueryClient();
  const [representative, setRepresentative] = useState({
    ...partner,
  });

  const { mutate, isLoading } = useMutation(
    async (payload) => {
      const response = await baseAPI.put(updatePartner(partner?.id), { ...payload });

      return response;
    },
    {
      onSuccess: ({ data }) => {
        if (data.success) {
          queryClient.invalidateQueries(['partners']);
          Toast.success(data?.message);
        } else Toast.error(data?.message);
      },
      onError: (error) => Toast.error(errorParser(error)),
    }
  );

  const handleInputChange = ({ name, value }) => {
    setRepresentative((v) => ({ ...v, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = { ...representative };

    delete payload?.id;
    delete payload?.role;
    delete payload?.creator;
    delete payload?.password;
    delete payload?.updatedAt;
    delete payload?.createdAt;
    delete payload?.is_active;
    delete payload?.userTypeId;
    delete payload?.userRoleId;
    delete payload?.accountType;
    delete payload?.deactivatedAt;
    delete payload?.bankInformation;
    delete payload?.companyInformation;

    Object.keys(payload).forEach((key) => {
      if (!payload?.[key]) {
        delete payload?.[key];
      }
    });

    mutate(payload);
  };

  return (
    <StyledAccordionBody>
      <form style={{ width: '100%', margin: 'auto' }} onSubmit={handleSubmit}>
        <Row>
          <Col md={6} sm={6}>
            <Input
              label="First Name"
              placeholder="First Name"
              name="firstName"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={representative.firstName}
            />
          </Col>
          <Col md={6} sm={6}>
            <Input
              label="Last Name"
              placeholder="Last Name"
              name="lastName"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={representative.lastName}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6}>
            <Input
              label="Email"
              placeholder="Enter Email"
              name="email"
              type="email"
              validate="required"
              onChange={handleInputChange}
              defaultValue={representative.email}
            />
          </Col>
          <Col md={6} sm={6}>
            <PhoneInput
              label="Phone Number"
              placeholder="Enter Phone Number"
              name="phoneNumber"
              validate="required"
              onChange={handleInputChange}
              defaultValue={representative.phoneNumber}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <Button type="submit" value="Apply Changes" size="lg" width={300} loading={isLoading} />
          </Col>
        </Row>
      </form>
    </StyledAccordionBody>
  );
};

export default RepresentativeProfileInfo;
