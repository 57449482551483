import Toast from 'react-hot-toast';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

// Components
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { ColorPicker, Input } from '../../../../components/inputs';

// Utils

import baseAPI from '../../../../api';
import { upgradeProvider } from '../../../../api/provider';
import { errorParser, validator } from '../../../../utilities/helper';
import Preview from './Preview';
import FileUpload from '../../../../components/inputs/File';

const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const ShowSuccessModal = ({ onClose }) => {
  return (
    <StyledSuccess>
      <Icon name="success" height="80px" />
      <div>Provider Successfully Upgraded</div>
      <div
        style={{
          color: '#17568B',
          fontSize: '16px',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        View Partners
      </div>
    </StyledSuccess>
  );
};

const UpgradeProviderForm = ({ provider }) => {
  const { push } = useHistory();
  const queryClient = useQueryClient();

  const companyInfo = provider?.companyInformation[0];

  const [formData, setFormData] = useState({});
  const [showSuccess, setSuccess] = useState(false);

  const handleCloseSuccessmodal = () => {
    setSuccess(false);
    push(`/admin/partners/${provider?.id}`);
  };

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };

  const { mutate, isLoading } = useMutation(
    async (payload) => {
      setSuccess(false);
      const response = await baseAPI.put(upgradeProvider(provider?.id), {
        ...payload,
      });

      return response;
    },
    {
      onSuccess: ({ data }) => {
        if (data.success) {
          queryClient.invalidateQueries(['partners']);
          setSuccess(true);
          setFormData({});
          Toast.success(data?.message);
        } else Toast.error("Error Can't Create Provider");
      },
      onError: (error) => Toast.error(errorParser(error)),
    }
  );

  const convertImage = (image) => {
    if (!image) return;
    if (typeof image === 'string') {
      return image;
    }

    const FR = new FileReader();

    return FR.readAsDataURL(image);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = { ...formData };

    payload.country = companyInfo?.country;
    payload.companyState = companyInfo?.state;
    payload.companyAddress = companyInfo?.address;
    payload.companyName = companyInfo?.companyName;
    payload.companyEmail = companyInfo?.companyEmail;
    payload.businessRegNumber = companyInfo?.businessRegNumber;
    payload.companyPhoneNumber = companyInfo?.companyPhoneNumber;

    payload.primaryColor = `#${payload?.primaryColor?.hex}`;
    payload.secondaryColor = `#${payload?.secondaryColor?.hex}`;

    delete payload?.forgetPasswordText;

    if (validator(payload?.backgroundImage, 'required')?.length > 0) {
      Toast.error('Background Image is required');
      return;
    }

    if (validator(payload?.logoUrl, 'required')?.length > 0) {
      Toast.error('Logo Image is required');
      return;
    }

    mutate(payload);
  };

  return (
    <>
      {!showSuccess && (
        <div className="px-5 py-3">
          <Row>
            <Col lg={6}>
              <form style={{ width: '100%', margin: 'auto' }} onSubmit={handleSubmit}>
                <Row>
                  <Col style={{ fontSize: 16, fontWeight: 200, marginBottom: 15, opacity: 0.6 }}>
                    Company Information
                  </Col>
                </Row>
                <>
                  <Row>
                    <Col>
                      <Input
                        label="Business Sector *"
                        placeholder="Enter Business Sector"
                        name="businessSector"
                        type="text"
                        validate="required"
                        defaultValue={formData.businessSector}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Input
                        label="Company Website URL"
                        placeholder="Enter URL"
                        name="companyWebsite"
                        type="url"
                        validate="required"
                        defaultValue={formData.companyWebsite}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col>
                      <Input
                        label="Copyright Info"
                        placeholder="Copyright Info"
                        name="copyright"
                        type="text"
                        validate="required"
                        defaultValue={formData.copyright}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FileUpload
                        label="Background Image"
                        placeholder="Select Image"
                        name="backgroundImage"
                        accept="image/png, image/jpeg"
                        value={formData.backgroundImage}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col>
                      <FileUpload
                        label="Logo"
                        placeholder="Select File"
                        name="logoUrl"
                        value={formData.logoUrl}
                        accept="image/png, image/jpeg"
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Input
                        label="Domain"
                        placeholder="Domain URL"
                        name="domain"
                        validate="required"
                        defaultValue={formData.domain}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col>
                      <Input
                        label="Domain Email"
                        placeholder="Domain Email"
                        name="domainEmail"
                        type="email"
                        validate="required"
                        defaultValue={formData.domainEmail}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <ColorPicker
                        label="Primary Color"
                        placeholder="Select Primary Color"
                        name="primaryColor"
                        validate="required"
                        defaultValue={formData.primaryColor}
                        onChange={handleInputChange}
                      />
                    </Col>
                    <Col>
                      <ColorPicker
                        label="Secondary Color"
                        placeholder="Select Secondary Color"
                        name="secondaryColor"
                        validate="required"
                        defaultValue={formData.secondaryColor}
                        onChange={handleInputChange}
                      />
                    </Col>
                  </Row>
                </>

                <Row>
                  <Col style={{ marginTop: 20 }}>
                    <Button
                      loading={isLoading}
                      type="submit"
                      value="Submit"
                      size="md"
                      width={120}
                    />
                  </Col>
                </Row>
              </form>
            </Col>
            <Col lg={6}>
              <Row>
                <Col
                  xs={12}
                  style={{ fontSize: 14, fontWeight: 500, marginBottom: 6, color: '#17568B' }}
                >
                  Preview
                </Col>
                <Col
                  xs={12}
                  style={{ fontSize: 16, fontWeight: 200, marginBottom: 13, color: '#505050' }}
                >
                  This is how your dashboard will look like
                </Col>
              </Row>
              <Preview formData={{ ...formData, logoUrl: convertImage(formData?.logoUrl) }} />
            </Col>
          </Row>
        </div>
      )}
      {showSuccess && (
        <Modal show={showSuccess} onClose={handleCloseSuccessmodal}>
          <ShowSuccessModal onClose={handleCloseSuccessmodal} />
        </Modal>
      )}
    </>
  );
};

export default UpgradeProviderForm;
