import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Loader from '../Loader';
import Header from './Header';
import Footer from './Footer';
import SideMenu from './SideMenu';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  height: 100%;
  background: #f2f2f2;
  position: relative;

  main {
    flex: 1 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    > section {
      padding: 20px 40px;
    }
  }
`;

const Container = ({
  children,
  showHeader = false,
  showFooter = false,
  showSideMenu = false,
  meta = {},
}) => {
  return (
    <>
      <Helmet>
        <title>BluInTouch Administrator Dashboard</title>
        <meta name="description" content={meta.description} />
      </Helmet>
      <StyledContainer>
        {showSideMenu && <SideMenu />}
        <main aria-hidden>
          {showHeader && <Header {...meta} />}
          <Suspense fallback={<Loader />}>
            <section className="scrollbar">{children}</section>
          </Suspense>
          {showFooter && <Footer />}
        </main>
      </StyledContainer>
    </>
  );
};

export default Container;
