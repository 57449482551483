import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Toast from 'react-hot-toast';
import Modal from '../../../components/Modal';
import countries from '../../../assets/data/countries.json';
import Button from '../../../components/Button';
import { Input, Select } from '../../../components/inputs';
import { APICreatePrice, APIlistService } from '../../../api/backendRoutes';
import { errorParser } from '../../../utilities/helper';

const CreatePriceModal = ({ show, onClose, listPrice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [formData, setFormData] = useState({
    service_name: '',
    price: '',
    country_code: '',
    failover_price: '',
    category: null,
    charge_type: 'flat',
    default_name: '',
  });

  const fetchServices = async () => {
    const resp = await APIlistService();
    setServices(resp.data.data)
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const countryName = countries.find((c) => c.value === formData.country_code).label;
    if (`${formData.failover_price}`.length !== 2) {
      Toast.error('Failover has to be two characters');
      return;
    }
    setIsLoading(true);
    try {
      const resp = await APICreatePrice({
        ...formData,
        country_name: countryName,
      });
      if (resp.data.message === 'ok') {
        Toast.success('Price created successfully');
        listPrice();
        onClose();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // eslint-disable-next-line no-console
      Toast.error(errorParser(error) || 'error');
    }
  };
  return (
    <Modal show={show} onClose={onClose} title="Create Price">
      <form style={{ width: '100%', margin: 'auto' }} onSubmit={(e) => handleSubmit(e)}>
        <Row>
          <Col style={{ fontSize: 18, fontWeight: 200, marginBottom: 15, opacity: 0.6 }}>
            Price Table Information
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              style={{}}
              label="Service Name"
              placeholder="Data Service"
              name="service_name"
              validate="required"
              options={services.map((dt) => ({
                label: dt.serviceTypeCategory,
                value: dt.serviceTypeCategory,
              }))}
              onChange={handleInputChange}
              value={formData.service_name}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              label="Default Name"
              placeholder="Data Service"
              name="default_name"
              type="text"
              validate="required"
              onChange={handleInputChange}
              value={formData.default_name}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Input
              label="Price (NGN)"
              placeholder="4"
              name="price"
              type="number"
              validate="required"
              onChange={handleInputChange}
              value={formData.price}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              label="Fail Over Price (NGN)"
              placeholder="5"
              name="failover_price"
              type="number"
              validate="required"
              onChange={handleInputChange}
              value={formData.failover_price}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <PhoneInput
              label="Phone Number"
              placeholder="Enter Phone Number"
              name="phone_number"
              validate="required"
            />
          </Col>
        </Row> */}
        <Row>
          <Col>
            <Select
              style={{}}
              label="Select Country *"
              placeholder="Select Country"
              name="country_code"
              validate="required"
              options={countries}
              onChange={handleInputChange}
              value={formData.country_code}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <Button type="submit" value="Create" size="md" loading={isLoading} width={100} />
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

export default CreatePriceModal;
