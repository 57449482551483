import styled from 'styled-components';
import { Button as BButton } from 'react-bootstrap';
import Loader from './Loader';
import Icon from './Icon';

const StyledButton = styled.div`
  ${({ center }) => (center ? `display: flex; justify-content: center;` : '')};

  button {
    font-weight: 500 !important;
    font-size: 13px;
    margin: ${(props) => props.margin};
    padding: ${(props) => props.padding || '0 12px'};
    height: ${(props) =>
      props.size === 'lg'
        ? '42px'
        : props.size === 'sm'
        ? '30px'
        : props.size === 'md'
        ? '38px'
        : '32px'};
    width: ${({ width }) => (width ? (typeof width === 'string' ? width : `${width}px`) : 'auto')};
    border-radius: 6px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button.primary {
    background: #17568b;
    color: #ffffff;

    &.danger {
      background: #df374b;
    }

    &.success {
      background: #63b67f;
    }
  }

  button.secondary {
    background: #dce8f4;
    color: #17568b;

    &.danger {
      background: #df374b;
      color: #ffffff;
    }

    &.success {
      background: #63b67f26;
      color: #63b67f;
    }
  }

  button.outline {
    background: #ffffff;
    border: 1px solid #17568b;
    color: #17568b;
  }
  button.outline_danger {
    background: #ffffff;
    border: 1px solid #df374b;
    color: #df374b;
  }

  button.round {
    border-radius: 16px;
  }

  button.squared {
    border-radius: 0px;
  }
  
  @media (max-width: '520px') {
    button {
      width: auto;
    }
  }
`;

const Button = ({
  type = 'button',
  group = 'primary',
  icon,
  iconRight,
  value,
  children,
  size,
  width = 200,
  margin = [],
  padding = [],
  submit = false,
  center,
  loading,
  disabled,
  variant,
  style,
  ...rest
}) => {
  return (
    <StyledButton
      {...{
        size,
        width,
        center,
        margin: margin.length > 0 && `${margin.join('px ')}px;`,
        padding: padding.length > 0 && `${padding.join('px ')}px;`,
      }}
      style={style}
    >
      <BButton
        type={type}
        className={group}
        disabled={loading || disabled}
        variant={variant}
        {...rest}
      >
        <>
          {!loading && (
            <>
              {icon && (
                <Icon
                  name={icon}
                  style={{ fontSize: 16, marginRight: value || children ? 5 : 0 }}
                  width={14}
                  onClick={() => null}
                />
              )}
              {value || children}
              {iconRight && (
                <Icon
                  name={iconRight}
                  style={{ fontSize: 16, marginRight: value || children ? 5 : 0 }}
                  width={14}
                  onClick={() => null}
                />
              )}
            </>
          )}
        </>
        {loading && <Loader color="white" />}
      </BButton>
    </StyledButton>
  );
};

export default Button;
