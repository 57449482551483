import { useCallback, useEffect, useState } from 'react';

// Components
import Icon from '../Icon';
import Card from '../Card';
import Chart from '../Chart';

// Utils
import { APIDailyActivities } from '../../api/backendRoutes';

const DailyActivityChart = () => {
  const [walletUsage, setWalletUsage] = useState();
  const [dailyIncrease, setDailyIncrease] = useState(null);
  const [chartData, setChartData] = useState();

  const getDailyActivity = useCallback(async () => {
    try {
      const response = await APIDailyActivities();
      if (response.data.message === 'ok') {
        setChartData(response.data.data.wallet);
        setWalletUsage(response.data.data.walletMonthlyUsagePercentage);
        setDailyIncrease(response.data.data.walletDailyPercentageIncrement);
      } else {
        throw response?.data?.error;
      }
    } catch (error) {
      /* eslint-disable-no-empty */
    }
  }, []);

  useEffect(() => {
    getDailyActivity();
    return () => {
      setChartData();
    };
  }, []);

  return (
    <Card>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div
          style={{
            width: 66,
            height: 66,
            borderRadius: 33,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#CD45A2',
          }}
        >
          <div
            style={{
              width: 60,
              height: 60,
              borderRadius: 30,
              background: '#FAEDF6',
              display: 'flex',
              alignItems: 'center',
              fontSize: 18,
              fontWeight: 600,
              color: '#CD45A2',
              justifyContent: 'center',
            }}
          >
            {Number(walletUsage || 0)?.toFixed(1)}%
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '10px 0',
            fontWeight: 300,
            fontSize: 14,
          }}
        >
          {dailyIncrease !== null && (
            <>
              {dailyIncrease < 0 ? (
                <Icon name="trending-down" margin={[0, 10, 0, 0]} width={20} color="red" />
              ) : (
                <Icon name="trending-up" margin={[0, 10, 0, 0]} width={20} color="#63B67F" />
              )}
              {Number(dailyIncrease || 0)?.toFixed(1)}% Today
            </>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '10px 0',
            fontWeight: 300,
            fontSize: 14,
          }}
        >
          <div>Total Sales per Day</div>
          <div
            style={{
              padding: '8px 20px',
              background: '#F2F2F273',
              borderRadius: 5,
            }}
          >
            <h4 style={{ fontWeight: 700, fontSize: 28, color: '#505050' }}>
              N{chartData?.map((data) => data?.amount).reduce((a, b) => a + b, 0) || 0}
            </h4>
          </div>
        </div>

        <div
          style={{
            width: '100%',
            marginTop: 20,
            borderRadius: 5,
            background: 'linear-gradient(180deg, rgba(42, 137, 217, 0.89) 0%, #0D497B 94.27%)',
          }}
        >
          {chartData && (
            <Chart
              option={{
                xAxis: {
                  axisLine: { show: false },
                  axisTick: { show: false },
                  axisLabel: { color: '#dddddd' },
                  type: 'category',
                  data: chartData.map((data) => data?.day),
                },
                yAxis: {
                  show: false,
                  type: 'value',
                },
                series: [
                  {
                    color: '#ffffff',
                    data: chartData.map((data) => data?.amount),
                    type: 'line',
                    smooth: true,
                  },
                ],
              }}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default DailyActivityChart;
