import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Components
import Services from '../Services';
import Merchants from '../Merchants';
import Commission from '../Commission';
import Tabs from '../../../../components/Tabs';
import WalletTransactions from '../WalletTransactions';
import PricingModel from '../PricingModel/PricingModel';
import PartnerProfileContent from './PartnerProfileContent';
import ContentWrapper from '../../../../components/layout/ContentWrapper';
// import ServiceTypesContent from './ServiceTypesContent/ServiceTypesContent';

// Utils
import baseAPI from '../../../../api';
import { getPartner } from '../../../../api/partners';
import { AppContext } from '../../../../utilities/context/AppContext';
import CreateServiceBtn from '../Buttons/CreateServiceBtn/CreateServiceBtn';
import CreatePriceModelBtn from '../PricingModel/buttons/CreatePriceModelBtn';

const StyledPartners = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }

  .MuiCheckbox-root {
    margin-right: 10px;
    .MuiSvgIcon-root {
      color: #bbbbbb !important;
    }
  }
`;
const Partner = () => {
  const { id } = useParams();
  const [key, setKey] = useState('profile');
  const { push } = useHistory();

  const getPartnerQuery = useQuery(
    ['partners', id],
    async () => {
      const response = await baseAPI.get(getPartner(id));
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (Object.keys(data?.data).length < 1) {
          push({
            pathname: `/admin/partners/`,
          });
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { setGoBackMethod } = useContext(AppContext);

  const renderExtra = (value) => {
    switch (value) {
      case 'services':
        return <CreateServiceBtn partner={getPartnerQuery?.data?.data} />;

      case 'pricingModel':
        return <CreatePriceModelBtn partner={getPartnerQuery?.data?.data} />;

      default:
        return '';
    }
  };

  useEffect(() => {
    setGoBackMethod(() =>
      push({
        pathname: `/admin/partners/`,
      })
    );
  }, []);
  return (
    <StyledPartners>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h5>Partner Information</h5>
        {renderExtra(key)}
      </div>
      <Tabs
        header={[
          {
            key: 'profile',
            label: 'Profile',
          },
          {
            key: 'walletTransactions',
            label: 'Wallet Transactions',
          },
          {
            key: 'commision',
            label: 'Commision',
          },
          {
            key: 'merchants',
            label: 'Merchants',
          },
          {
            key: 'services',
            label: 'Services',
          },
          {
            key: 'pricingModel',
            label: 'Pricing Model',
          },
        ]}
        data={{
          profile: (
            <ContentWrapper
              errorTitle="Error fetching partner profile"
              currentQuery={getPartnerQuery}
            >
              <PartnerProfileContent partner={getPartnerQuery?.data?.data} />
            </ContentWrapper>
          ),
          walletTransactions: (
            <ContentWrapper
              errorTitle="Error fetching partner profile"
              currentQuery={getPartnerQuery}
            >
              <WalletTransactions partner={getPartnerQuery?.data?.data} />
            </ContentWrapper>
          ),
          commision: <Commission />,
          merchants: (
            <ContentWrapper
              errorTitle="Error fetching partner profile"
              currentQuery={getPartnerQuery}
            >
              <Merchants partner={getPartnerQuery?.data?.data} />
            </ContentWrapper>
          ),
          services: (
            <ContentWrapper
              errorTitle="Error fetching partner profile"
              currentQuery={getPartnerQuery}
            >
              <Services partner={getPartnerQuery?.data?.data} />
            </ContentWrapper>
          ),
          pricingModel: (
            <ContentWrapper
              errorTitle="Error fetching partner profile"
              currentQuery={getPartnerQuery}
            >
              <PricingModel partner={getPartnerQuery?.data?.data} />
            </ContentWrapper>
          ),
        }}
        onSetKey={(value) => setKey(value)}
      />
    </StyledPartners>
  );
};
export default Partner;
