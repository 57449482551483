import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import Table from '../../../components/Table';
import { APIlistTeam } from '../../../api/backendRoutes';
import CreateUser from './CreateUser';
import { fetchUserData } from '../../../utilities/storage';
import ConfirmDeleteModal from './ConfirmDeleteModal';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
`;

const Teams = ({ showUser, setCurrentMember, roles }) => {
  const [deleteId, setDeleteId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddTeamMateModal, setShowAddTeamMateModal] = useState(false);
  const [team, setTeam] = useState([]);
  const [meta, setMeta] = useState({});
  const { merchantOrganizationId } = fetchUserData();
  // const history = useHistory();
  const getTeam = useCallback(async (params) => {
    try {
      const resp = await APIlistTeam({ ...params, limit: 25, merchantOrganizationId });
      if (resp.data.message === 'successful') {
        const newData = resp.data?.data;
        const teamMember = newData.map((d) => ({
          id: d.id,
          name: `${d.user?.firstName} ${d.user?.lastName}`,
          email: d.user?.email,
          country: d.user?.country,
          phone_number: d.user?.phoneNumber,
        }));
        setMeta({ limit: resp?.data?.limit, page: resp?.data?.page, total: resp?.data?.total });
        setTeam(teamMember);
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      // console.log(err);
    }
  });

  useEffect(() => {
    getTeam();
  }, []);

  const closeModal = () => {
    setShowAddTeamMateModal(!showAddTeamMateModal);
    // setShowPermissionModal(false);
  };

  return (
    <>
      <ButtonWrapper>
        <div>All Teammates</div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            value="Add Teammate"
            onClick={() => setShowAddTeamMateModal(!showAddTeamMateModal)}
          />
        </div>
      </ButtonWrapper>
      <Table
        paginate={Boolean(Object.keys(meta).length)}
        meta={meta}
        header={[
          {
            key: 'name',
            label: 'Name',
            sort: true,
            formatter: (name, { email }) => (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                  <div>
                    <div>{name}</div>
                    <div className="text-muted">{email}</div>
                  </div>
                </div>
              </>
            ),
          },
          { key: 'email', label: 'Email', sort: true },
          { key: 'country', label: 'Country', sort: true },
          { key: 'phone_number', label: 'Phone Number', sort: true },
          {
            key: 'id',
            label: '',
            formatter: (status, record) => (
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Button
                  value="Details"
                  group="secondary"
                  onClick={() => {
                    setCurrentMember(record);
                    showUser();
                  }}
                />
                <Icon
                  name="trashcircle"
                  onClick={() => {
                    setDeleteId(record.id);
                    setShowDeleteModal(true);
                  }}
                  width={26}
                  margin={[0, 0, 0, 10]}
                />
              </div>
            ),
          },
        ]}
        data={team}
      />
      <CreateUser
        show={showAddTeamMateModal}
        getTeam={getTeam}
        roles={roles}
        onClose={closeModal}
        title="Add TeamMates"
      />
      <ConfirmDeleteModal
        action={getTeam}
        show={showDeleteModal}
        id={deleteId}
        closeModal={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default Teams;
