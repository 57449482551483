import styled from 'styled-components';
import Icon from './Icon';

const StyledBack = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  color: #17568b;
`;

const Back = ({ icon = 'back', value = 'Back', onClick }) => {
  const goBack = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <StyledBack onClick={goBack}>
      {icon && <Icon name={icon} width={14} margin={[0, 10, 0, 0]} />}{' '}
      {value && <span>{value}</span>}
    </StyledBack>
  );
};

export default Back;
