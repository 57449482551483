import { useState } from 'react';
import toast from 'react-hot-toast';
import { APIDeleteUser } from '../../../api/backendRoutes';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import { errorParser } from '../../../utilities/helper';

function ConfirmDeleteModal({ show, closeModal, action, id }) {
  const [loading, setLoading] = useState(false);
  const deleteRole = async () => {
    try {
      setLoading(true);
      const resp = await APIDeleteUser({
        memberId: id,
      });
      if (resp.data.success) {
        action();
        toast.success('User removed successfully');
        closeModal();
        setLoading(false);
      } else {
        setLoading(false);
        throw resp?.data?.error;
      }
    } catch (err) {
      setLoading(false);
      toast.error(errorParser(err) || 'Server error');
    }
  };
  return (
    <Modal show={show} onClose={closeModal} title="Confirm Delete">
      <h3 className="text-center">This action is irreversible, proceed with caution!</h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '20px 0px',
          borderTop: '1px solid #F2F2F2',
          margin: '10px 0px',
        }}
      >
        <Button value="Cancel" group="outline" onClick={closeModal} />
        <Button value="Delete" group="outline_danger" loading={loading} onClick={deleteRole} />
      </div>
    </Modal>
  );
}

export default ConfirmDeleteModal;
