import styled, { css } from 'styled-components';
import { EyeOutline, EyeOff2Outline } from 'styled-icons/evaicons-outline';
import { AngleDown, AngleUp, Bars } from 'styled-icons/fa-solid';
import { Close } from 'styled-icons/evaicons-solid';

import { ReactComponent as arrowcircle } from './arrow-circle.svg';
import { ReactComponent as badgecheck } from './badge-check.svg';
import { ReactComponent as bar } from './bar.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as caretdownsm } from './caret-down-sm.svg';
import { ReactComponent as cash } from './cash.svg';
import { ReactComponent as doc } from './doc.svg';
import { ReactComponent as megaphone } from './megaphone.svg';
import { ReactComponent as morealt } from './more-alt.svg';
import { ReactComponent as negative } from './negative.svg';
import { ReactComponent as phone } from './phone.svg';
import { ReactComponent as cellphone } from './cellphone.svg';
import { ReactComponent as search } from './search.svg';
import { ReactComponent as trash } from './trash.svg';
import { ReactComponent as pencil } from './pencil.svg';
import { ReactComponent as cog } from './cog.svg';
import { ReactComponent as cogwhite } from './cog-white.svg';
import { ReactComponent as bell } from './bell.svg';
import { ReactComponent as openlink } from './window.svg';
import { ReactComponent as whatsapp } from './whatsapp.svg';
import { ReactComponent as upload } from './upload.svg';
import { ReactComponent as album } from './album.svg';
import { ReactComponent as users } from './users.svg';
import { ReactComponent as wallet } from './wallet.svg';
import { ReactComponent as bolt } from './bolt.svg';
import { ReactComponent as shop } from './shop.svg';
import { ReactComponent as trashopen } from './trash-open.svg';
import { ReactComponent as tv } from './tv.svg';
import { ReactComponent as tvlight } from './tv-light.svg';
import { ReactComponent as close } from './close.svg';
import { ReactComponent as fileupload } from './file-upload.svg';
import { ReactComponent as burger } from './burger.svg';
import { ReactComponent as goback } from './go-back.svg';
import { ReactComponent as caretleft } from './caret-left.svg';
import { ReactComponent as power } from './power.svg';
import { ReactComponent as cogblue } from './cog-blue.svg';
import { ReactComponent as card } from './card.svg';
import { ReactComponent as group } from './group.svg';
import { ReactComponent as briefcase } from './briefcase.svg';
import { ReactComponent as store } from './store.svg';
import { ReactComponent as chat } from './chat.svg';
import { ReactComponent as report } from './report.svg';
import { ReactComponent as moneybag } from './money-bag.svg';
import { ReactComponent as cashblue } from './cash-blue.svg';
import { ReactComponent as avatar } from './avatar.svg';
import { ReactComponent as caretdown } from './caret-down.svg';
import { ReactComponent as increase } from './increase.svg';
import { ReactComponent as decrease } from './decrease.svg';
import { ReactComponent as trashcircle } from './trash-circle.svg';
import { ReactComponent as pluscircle } from './plus-circle.svg';
import { ReactComponent as notification } from './notification.svg';
import { ReactComponent as bank } from './bank.svg';
import { ReactComponent as useradd } from './user-add.svg';
import { ReactComponent as userlock } from './user-lock.svg';
import { ReactComponent as building } from './building.svg';
import { ReactComponent as back } from './back.svg';
import { ReactComponent as closesolid } from './close-solid.svg';
import { ReactComponent as trendingup } from './trending-up.svg';
import { ReactComponent as trendingdown } from './trending-down.svg';
import { ReactComponent as warn } from './warn.svg';
import { ReactComponent as error } from './error.svg';
import { ReactComponent as success } from './success.svg';
import { ReactComponent as email } from './email.svg';
import { ReactComponent as location } from './location.svg';

const sharedStyle = css`
  color: var(--primary-color);
  width: 3rem;
  height: 3rem;
  position: absolute;
  right: 2rem;
`;

const sharedAngleStyle = css`
  color: #fff;
  width: 2rem;
  height: 2rem;
`;
const ClosedEye = styled(EyeOff2Outline)`
  ${sharedStyle};
  top: ${(props) => props.top || '4rem'};
`;
const Eye = styled(EyeOutline)`
  ${sharedStyle};
  top: ${(props) => props.top || '4rem'};
`;

const StyledAngeleDown = styled(AngleDown)`
  ${sharedAngleStyle};
`;
const StyledAngeleUp = styled(AngleUp)`
  ${sharedAngleStyle};
`;

const StyledBar = styled(Bars)`
  color: #000;
  width: 3rem;
  height: 3rem;
`;

const StyledClose = styled(Close)`
  color: ${({ color }) => color || '#000'};
  width: 3rem;
  height: 3rem;
`;

export {
  arrowcircle,
  badgecheck,
  bar,
  calendar,
  cash,
  doc,
  megaphone,
  morealt,
  negative,
  phone,
  cellphone,
  search,
  trash,
  pencil,
  cog,
  cogwhite,
  bell,
  openlink,
  whatsapp,
  upload,
  album,
  users,
  wallet,
  bolt,
  shop,
  trashopen,
  tv,
  tvlight,
  close,
  fileupload,
  burger,
  goback,
  caretleft,
  power,
  cogblue,
  card,
  group,
  briefcase,
  store,
  chat,
  report,
  moneybag,
  cashblue,
  avatar,
  caretdown,
  caretdownsm,
  increase,
  decrease,
  trashcircle,
  pluscircle,
  bank,
  useradd,
  userlock,
  notification,
  building,
  back,
  closesolid,
  trendingup,
  trendingdown,
  warn,
  error,
  success,
  ClosedEye,
  Eye,
  StyledAngeleUp,
  StyledAngeleDown,
  StyledBar,
  StyledClose,
  email,
  location,
};
