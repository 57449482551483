import styled from 'styled-components';
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useQuery } from 'react-query';

// Components
import { Input, Select } from '../../../../components/inputs';

// Utils
import { APIfetchBankList } from '../../../../api/backendRoutes';

const StyledAccordionBody = styled.div`
  padding-bottom: 20px;
`;

const BankProfileInfo = ({ provider }) => {
  const { bankInformation } = provider;
  const [bankInfo] = useState({
    ...bankInformation?.[0],
  });
  const [bankList, setBankList] = useState([]);

  useQuery(
    ['banks'],
    async () => {
      const response = await APIfetchBankList();
      return response.data;
    },
    {
      onSuccess: ({ data }) => {
        const fetchedbankList = data?.result?.map((bank) => {
          return {
            label: bank.name,
            value: bank.name,
          };
        });
        setBankList(fetchedbankList);
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <StyledAccordionBody>
      <form style={{ width: '100%', margin: 'auto' }} onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col>
            <Select
              label="Bank Name"
              placeholder="Enter Bank Name"
              name="bankName"
              type="text"
              validate="required"
              defaultValue={bankInfo.bankName}
              // onChange={handleInputChange}
              options={bankList}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              label="Bank Account Name"
              placeholder="Bank Account Name"
              name="bankAccountName"
              type="text"
              validate="required"
              // onChange={handleInputChange}
              defaultValue={bankInfo.bankAccountName}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              label="Bank Account Number"
              placeholder="Bank Account Number"
              name="bankAccountNumber"
              type="number"
              validate="required"
              // onChange={handleInputChange}
              defaultValue={bankInfo.bankAccountNumber}
              disabled
            />
          </Col>
        </Row>
      </form>
    </StyledAccordionBody>
  );
};

export default BankProfileInfo;
