import { combineReducers } from 'redux';

import auditsReducer from './audits';
import reportsReducer from './reports';
import serviceDetailReducer from './serviceReportDetail';
import senderIdReducer from './senderId';
import transactionsReducer from './transactions';
import walletReducer from './wallet';
import walletDetailsReducer from './walletDetails';
import usersReducer from './users';
import userProfile from './userProfile';
import merchant from './merchant';
import provider from './provider';
import prices from './prices';

const rootReducer = combineReducers({
  audits: auditsReducer,
  senderId: senderIdReducer,
  reports: reportsReducer,
  serviceReportDetail: serviceDetailReducer,
  transactions: transactionsReducer,
  walletDetails: walletDetailsReducer,
  wallet: walletReducer,
  users: usersReducer,
  profile: userProfile,
  merchant,
  provider,
  prices,
});

export default rootReducer;
