import { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import Button from '../../components/Button';
import CreateMerchant from './components/CreateMerchant';
import { Checkbox } from '../../components/inputs';
import { AppContext } from '../../utilities/context/AppContext';
import { useUserTypes } from '../../utilities/hooks/useUserTypes';
import { fetchUsersByTypeAction } from '../../redux/actions/users';
import Merchant from './components/Merchant';
import { APIMerchantWalleDetails } from '../../api/backendRoutes';

const StyledMerchants = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }

  .MuiCheckbox-root {
    margin-right: 10px;
    .MuiSvgIcon-root {
      color: #bbbbbb !important;
    }
  }
`;

const mapStateToProps = ({ users }) => {
  return {
    fetchingMerchants: users.fetchingUsersByType,
    merchants: users.usersByType,
    meta: users.usersByTypeMeta,
  };
};

const Merchants = () => {
  const dispatch = useDispatch();
  const merchant = useUserTypes('Merchant');

  const [showCreateMerchantModal, setShowCreateMerchantModal] = useState(false);
  const [showMerchant, setShowMerchant] = useState(false);
  const [detailMerchant, setDetailMerchant] = useState();
  const [payload] = useState({ type: '' });
  const [reload, setReload] = useState(false);
  const { setGoBackMethod } = useContext(AppContext);
  const { fetchingMerchants, merchants, meta } = useSelector(mapStateToProps);
  const [walletDetails, setWalletDetails] = useState();
  const fetchUsersByType = useCallback(
    (params) => {
      dispatch(fetchUsersByTypeAction({ ...params, type: merchant?.id }));
    },
    [dispatch]
  );
  useEffect(() => {
    if (Object.keys(merchant || {})?.length) {
      fetchUsersByType({ ...payload, type: merchant.id, limit: meta.limit ?? 25 });
    }
  }, [merchant, reload]);

  const handleDetail = async (id) => {
    setGoBackMethod(() => setShowMerchant(false));
    try {
      const newdetailMerchant = merchants.filter((merchnt) => merchnt.id === id);
      setDetailMerchant(newdetailMerchant);
      const resp = await APIMerchantWalleDetails({
        accountId: newdetailMerchant[0].merchantOrganizationId,
      });
      if (resp.data.message === 'successful') {
        setWalletDetails(resp.data.data.data[0]);
        setTimeout(() => {
          setShowMerchant(true);
        }, 100);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  return (
    <StyledMerchants>
      {showMerchant && <Merchant merchant={detailMerchant} walletDetails={walletDetails} />}
      {!showMerchant && merchant?.id && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 30,
            }}
          >
            <h5>All merchants</h5>
            {/* <Button
              value="Create Merchant"
              group="secondary round"
              icon="plus-circle"
              width={150}
              onClick={() => setShowCreateMerchantModal(true)}
              // margin={[0, 0, 0, 10]}
            /> */}
          </div>

          <Row>
            <Col>
              <Table
                filterAction={fetchUsersByType}
                filter={{
                  Date: [
                    { value: 'Today', label: 'Today' },
                    { value: 'This Week', label: 'This Week' },
                    { value: 'This Month', label: 'This Month' },
                    { value: 'Last 30 Days', label: 'Last 30 Days' },
                    { value: 'This Year', label: 'This Year' },
                    { value: 'Period', label: 'Specific Period' },
                  ],
                  keyword: 'keyword',
                }}
                showFilter
                header={[
                  {
                    key: 'businessName',
                    label: (
                      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                        Name
                      </div>
                    ),
                    sort: true,
                    formatter: (businessName) => {
                      return (
                        <>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                            <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                            <div>
                              <div>{`${businessName}`}</div>
                              {/* <div className="text-muted">{businessName}</div> */}
                            </div>
                          </div>
                        </>
                      );
                    },
                  },
                  { key: 'email', label: 'Email', sort: true },
                  { key: 'country', label: 'Country', sort: true },
                  { key: 'phoneNumber', label: 'Phone Number', sort: true },

                  {
                    key: 'createdAt',
                    label: 'Date',
                    sort: true,
                    formatter: (date) => new Date(date).toDateString(),
                  },
                  {
                    key: 'id',
                    label: '',
                    formatter: (id) => (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          value="Details"
                          group="secondary"
                          size="sm"
                          width={70}
                          margin={[0, 10, 0, 0]}
                          onClick={() => handleDetail(id)}
                        />
                        {/* <Icon name="trashcircle" width={26} margin={[0, 0, 0, 10]} /> */}
                      </div>
                    ),
                  },
                ]}
                data={merchants}
                loading={fetchingMerchants}
                paginate={Boolean(Object.keys(meta).length)}
                meta={{ ...meta, type: merchant?.id, limit: meta.limit ?? 25 }}
                // handlePagination={handlePagination}
              />
            </Col>
          </Row>
          {showCreateMerchantModal && (
            <CreateMerchant
              show={showCreateMerchantModal}
              onClose={() => setShowCreateMerchantModal(false)}
              reload={() => setReload(!reload)}
            />
          )}
        </>
      )}
    </StyledMerchants>
  );
};

export default Merchants;
