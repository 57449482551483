/* eslint-disable */
import Toast from 'react-hot-toast';
import api from '../../api';
import { APIWalletActivities } from '../../api/backendRoutes';
import { errorParser } from '../../utilities/helper';
import {
  fetchAdminWalletSuccessReducer,
  fetchingAdminWalletReducer,
  fetchingAdminWalletErrorReducer,
} from '../slices/wallet';

export const fetchAdminWalletAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingAdminWalletReducer());
    try {
      const resp = await APIWalletActivities(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchAdminWalletSuccessReducer(resp.data));
      } else {
        dispatch(fetchingAdminWalletErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'error');
    }
  };
