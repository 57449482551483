import { useState } from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'react-bootstrap';

// Components
import Icon from '../../../../components/Icon';
import Table from '../../../../components/Table';
import ContentWrapper from '../../../../components/layout/ContentWrapper';

// Utils
import { APIPriceModel } from '../../../../api/backendRoutes';
import UpdatePriceModelModal from './modals/UpdatePriceModelModal';

const PricingModel = ({ partner }) => {
  const [currentRowValues, setCurrentRowValues] = useState({});

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen((prev) => !prev);

  const getPricingModelQuery = useQuery(
    ['pricing_model', partner.id],
    async () => {
      const response = await APIPriceModel(partner?.companyInformation?.[0]?.id);
      return response.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  return (
    <ContentWrapper errorTitle="Error fetching pricing models" currentQuery={getPricingModelQuery}>
      <Row>
        <Col>
          <Table
            header={[
              { key: '_id', label: 'ID', hidden: true },
              {
                key: 'priceModelName',
                label: 'Pricing Model',
                sort: false,
                formatter: (_, model) => model?.priceModelName || '-----------',
              },
              { key: 'type', label: 'Model Type', sort: false },
              { key: 'country', label: 'Country', sort: false },
              {
                key: 'price',
                label: 'Price/Value',
                sort: false,
                formatter: (_, model) =>
                  model?.type === 'Discount'
                    ? `${model?.value || 0}%`
                    : `${model?.currency} ${model?.price || 0}`,
              },
              {
                key: 'menu',
                label: '',
                formatter: (_, model) => (
                  <Icon
                    name="pencil"
                    width={16}
                    margin={[0, 0, 0, 10]}
                    onClick={() => {
                      setCurrentRowValues(model);
                      toggleModal();
                    }}
                  />
                ),
              },
            ]}
            data={getPricingModelQuery?.data?.data || []}
            // paginate={providers?.length > 0}
            // meta={meta}
            handlePagination={() => {}}
          />
        </Col>
        {isOpen && (
          <UpdatePriceModelModal
            model={currentRowValues}
            show={isOpen}
            onClose={toggleModal}
            partner={partner}
          />
        )}
      </Row>
    </ContentWrapper>
  );
};
export default PricingModel;
