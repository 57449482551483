import baseAPI from '.';
import { BASE_URL } from '../utilities/constants';

const qs = (params) => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
};

export const APIfetchAdminAuditReports = (params = {}) =>
  baseAPI.get(`${BASE_URL}/reports/audit-trails/admin?${qs(params)}`);
export const APIlogin = (params = {}) => baseAPI.post(`/auth/login-admin`, params);
export const APIfetchUserProfile = () => baseAPI.get(`${BASE_URL}/auth/users/profile`);
export const APIDeativateUserAccount = (params = {}) =>
  baseAPI.put(`/auth/users/profile/deactivate`, params);
export const APIfetchRoles = () => baseAPI.get(`/auth/roles/admin`);
export const APIUpdateRole = (params = {}) => baseAPI.put(`/auth/team/update/admin`, params);
export const APIDeleteUser = (params = {}) =>
  baseAPI.delete(`/auth/remove-team-member/admin?${qs(params)}`);
export const APIaddTeamMate = (params = {}) => baseAPI.post(`/auth/team-member/add/admin`, params);
export const APIchangePassword = (params = {}) =>
  baseAPI.patch(`/auth/change/password/reset`, params);
export const APIfetchUsersByType = (params = {}) =>
  baseAPI.get(`${BASE_URL}/auth/users/admin?${qs(params)}`);
export const APIgetCompanyDetails = (params = {}) =>
  baseAPI.get(`auth/companies-name/fetch`, { params });
export const APIgetCompanyDetailsById = (params = {}) =>
  baseAPI.get(`auth/companies/fetch?${qs(params)}`);
export const APIlistTeam = (params = {}) => baseAPI.get(`/auth/team-member/list?${qs(params)}`);
export const APIfetchAccountTypes = (params = {}) =>
  baseAPI.get(`${BASE_URL}/auth/list-account-types`, params);
export const APIcreateNewMerchant = (params = {}) =>
  baseAPI.post(`${BASE_URL}/auth/create-merchant`, params);
export const APIMerchantTeam = (params = {}) =>
  baseAPI.get(`${BASE_URL}/auth/merchant-team/admin?${qs(params)}`);
export const APIMerchantWalleDetails = (params = {}) =>
  baseAPI.get(`${BASE_URL}/auth/users/admin/wallet/details?${qs(params)}`);
export const APIEditUserData = (params = {}) =>
  baseAPI.put(`/auth/users/profile/edit/admin`, params);
export const APIfetchMerchantServiceReportDetail = (params = {}) =>
  baseAPI.get(`/reports/service-reports-details?${qs(params)}`);
export const APIMerchantWalletListing = (params = {}) =>
  baseAPI.get(`${BASE_URL}/core/wallet/merchant-activities/admin?${qs(params)}`);
export const APIfetchcostModel = () => baseAPI.get(`${BASE_URL}/core/list/cost/model`);
export const APIfetchpriceModel = () => baseAPI.get(`${BASE_URL}/core/list/price/model`);
export const APIcreateProvider = (params = {}) =>
  baseAPI.post(`${BASE_URL}/auth/create-provider`, params);
export const APIverifyBankDetails = (params = {}) =>
  baseAPI.post(`${BASE_URL}/auth/verify/bank`, params);
export const APIfetchBankList = () => baseAPI.get(`${BASE_URL}/auth/list/bank`);
export const APIforgotPassword = (params = {}) =>
  baseAPI.post(`${BASE_URL}/auth/forget/password/reset`, params);
export const APIlistCountry = () => baseAPI.get(`${BASE_URL}/core/list/country`);
export const APIlistState = (params = {}) =>
  baseAPI.get(`${BASE_URL}/core/list/country/state`, params);
export const APIWalletStatistics = (params = {}) =>
  baseAPI.get(`${BASE_URL}/core/wallet/get-merchant-activity/admin?${qs(params)}`);
export const APIlistService = (params = {}) => baseAPI.get(`${BASE_URL}/core/list/service/type`);
export const APIlistAllService = (params = {}) =>
  baseAPI.get(`${BASE_URL}/core/list/admin/service`);
export const APIcreateService = (params = {}) =>
  baseAPI.post(`${BASE_URL}/core/create/service`, params);
export const APIUpdateService = (id, params = {}) =>
  baseAPI.put(`${BASE_URL}/core/service/update/${id}`, params);
export const APIdeleteService = (params = {}) =>
  baseAPI.delete(`${BASE_URL}/core/service/delete`, params);
export const APIupdateServiceStatus = (body = {}, params = {}) =>
  baseAPI.post(`${BASE_URL}/core/service/status`, body, params);
export const APISetupServices = (body = {}, params = {}) =>
  baseAPI.post(`${BASE_URL}/core/service/provider-services/setup`, body, params);
export const APIlistProviderService = (params = {}) =>
  baseAPI.get(`${BASE_URL}/core/list/service/admin/partner`, params);
export const APIlistSenderIdRequest = (params) =>
  baseAPI.get(`${BASE_URL}/core/senderid/list?${qs(params)}`);
export const APIapproveSendeIdRequest = (params = {}) =>
  baseAPI.put(`${BASE_URL}/core/senderid/request/approve`, params);
export const APIrejectSendeIdRequest = (params = {}) =>
  baseAPI.put(`${BASE_URL}/core/senderid/request/reject`, params);
export const APITransactionDetails = (params = {}) =>
  baseAPI.get(`${BASE_URL}/core/wallet/merchant-wallet-transaction/admin?${qs(params)}`);
export const APITransactionReports = (params = {}) =>
  baseAPI.get(`${BASE_URL}/reports/transactions/admin?${qs(params)}`);
export const APIServiceReports = (params = {}) =>
  baseAPI.get(`${BASE_URL}/reports/service-reports/admin?${qs(params)}`);
export const APIWalletActivities = (params = {}) =>
  baseAPI.get(`${BASE_URL}/core/wallet/activities/admin?${qs(params)}`);
export const APIWalletSummary = (params = {}) =>
  baseAPI.get(`${BASE_URL}/core/wallet/summary/admin?${qs(params)}`);
export const APIPartnerWallet = (params = {}) =>
  baseAPI.get(`${BASE_URL}/auth/wallet/balance/partner`, params);
export const APIPartnerWalletTransaction = (id, params = {}) =>
  baseAPI.get(`${BASE_URL}/core/wallet/activities/partner/${id}`, params);
export const APIPriceTable = (params = {}) =>
  baseAPI.get(`core/admin/list/price/model?${qs(params)}`);
export const APICreatePrice = (params) => baseAPI.post(`/core/create/service-price`, params);
export const APIEditPrice = (params) =>
  baseAPI.put(`/core/service/price/default-price/update`, params);

export const APIPriceModel = (id) => baseAPI.get(`/core/list/price/model/${id}`);
export const APICreatePriceModel = (params = {}) => baseAPI.post(`/core/price/model`, params);

export const APIUpdatePriceModel = (id, params = {}) =>
  baseAPI.put(`/core/update/price/model/${id}`, params);

export const APIUserReport = () => baseAPI.get(`${BASE_URL}/auth/users/count/admin`);

export const APITransactionChart = (params) =>
  baseAPI.get(`${BASE_URL}/reports/transactions/admin/analysis`, { params });

export const APITransactionTable = () =>
  baseAPI.get(`${BASE_URL}/reports/transactions/admin/latest`);

export const APIDailyActivities = () => baseAPI.get(`${BASE_URL}/core/wallet/daily-analytics`);
