import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Country as Countries, State } from 'country-state-city';

// Components
import Button from '../../../../components/Button';
import { ColorPicker, Input, PhoneInput, Select } from '../../../../components/inputs';

// Utils
import { providerCreateState } from '../../../../utilities/constants';
import { validator } from '../../../../utilities/helper';

const CompanyInfo = ({ moveToNext }) => {
  const [providerInfo, setProviderInfo] = useRecoilState(providerCreateState);
  const [formData, setFormData] = useState({ ...providerInfo, fetching: false });
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const getCountry = () => {
    try {
      const countries = Countries.getAllCountries();

      const parsedCountries = countries.map((data) => ({
        label: data?.name,
        value: data?.name,
        ...data,
      }));
      setCountry(parsedCountries);
    } catch (error) {
      setCountry([]);
    }
  };

  const getState = (code) => {
    try {
      const parsedStates = (countryCode) =>
        State.getStatesOfCountry(countryCode).map((data) => ({
          label: data?.name,
          value: data?.name,
          ...data,
        }));
      setState(parsedStates(code));
    } catch (error) {
      setState([]);
    }
  };

  useEffect(() => {
    getCountry();

    if (formData?.country) {
      const iso = Countries.getAllCountries().find((c) => c.name === formData.country).isoCode;
      getState(iso);
    }
  }, []);

  useEffect(() => {
    if (formData?.country === providerInfo?.country) {
      return;
    }
    const newFormData = { ...formData };
    delete newFormData?.state;
    setFormData({ ...newFormData });

    if (providerInfo.country) {
      const newProiderInfo = { ...providerInfo };
      delete newProiderInfo?.state;

      setProviderInfo({ ...newProiderInfo, ...newFormData });
    }
  }, [formData.country]);

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
    if (name === 'country') {
      const selectedCountry = country.filter((cont) => cont.value === value);
      const { isoCode } = selectedCountry[0];
      getState(isoCode);
    }
  };

  return (
    <form
      style={{ width: '100%', margin: 'auto' }}
      onSubmit={(e) => {
        e.preventDefault();
        if (
          validator(formData?.companyPhoneNumber.replace(/\s/g, ''), 'phone_number')?.length > 0
        ) {
          return;
        }
        setProviderInfo((p) => ({ ...p, ...formData }));
        moveToNext();
      }}
    >
      <Row>
        <Col style={{ fontSize: 16, fontWeight: 200, marginBottom: 15, opacity: 0.6 }}>
          Company Information
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Company Email"
            placeholder="Enter Company Email"
            name="companyEmail"
            type="email"
            validate="email"
            defaultValue={formData.companyEmail}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Company Name"
            placeholder="Enter Comapny Name"
            name="companyName"
            type="text"
            validate="required"
            defaultValue={formData.companyName}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Business Registration"
            placeholder="Enter Business Registration Number"
            name="businessRegNumber"
            type="text"
            validate="required"
            defaultValue={formData.businessRegNumber}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Company Domain URL"
            placeholder="Enter Company Domain"
            name="domain"
            type="text"
            validate="required"
            defaultValue={formData.domain}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PhoneInput
            label="Phone Number"
            placeholder="Enter Phone Number"
            name="companyPhoneNumber"
            validate="phone_number"
            defaultValue={formData.companyPhoneNumber}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            label="Enter Address"
            placeholder="Enter Address"
            name="companyAddress"
            type="text"
            validate="required"
            defaultValue={formData.companyAddress}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Select
            label="Select Country *"
            placeholder="Select Country"
            name="country"
            validate="required"
            defaultValue={formData.country}
            onChange={handleInputChange}
            options={country}
          />
        </Col>
        <Col md={6}>
          <Select
            label="Select State *"
            placeholder="Select State"
            name="state"
            validate="required"
            defaultValue={formData?.state}
            onChange={handleInputChange}
            options={state}
          />
        </Col>
      </Row>

      {formData.is_partner === true && (
        <>
          <Row>
            <Col>
              <Select
                label="Business Sector *"
                placeholder="Select Business Sector"
                name="state"
                validate="required"
                defaultValue={formData.business_sector}
                onChange={handleInputChange}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                label="Company Website URL"
                placeholder="Enter URL"
                name="company_website_url"
                type="url"
                validate="required"
                defaultValue={formData.company_website_url}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              <Input
                label="Copyright Info"
                placeholder="Copyright Info"
                name="copy_right_info"
                type="text"
                validate="required"
                defaultValue={formData.copy_right_info}
                onChange={handleInputChange}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                label="Background Image URL"
                placeholder="Enter URL"
                name="address"
                type="url"
                validate="required"
                defaultValue={formData.address}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              <Input
                label="Logo URL"
                placeholder="Copyright Info"
                name="logo_url"
                type="url"
                validate="required"
                defaultValue={formData.logo_url}
                onChange={handleInputChange}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                label="Domain"
                placeholder="Domain URL"
                name="domain"
                type="url"
                validate="required"
                defaultValue={formData.domain}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              <Input
                label="Domain Email"
                placeholder="Domain Email"
                name="app_domain_mail"
                type="email"
                validate="required"
                defaultValue={formData.app_domain_mail}
                onChange={handleInputChange}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Input
                label="Forgot Password Text"
                placeholder="Forgot Password Text"
                name="forgot_password_text"
                type="text"
                validate="required"
                defaultValue={formData.forgot_password_text}
                onChange={handleInputChange}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <ColorPicker
                label="Sidebar Color"
                placeholder="Select Sidebar Color"
                name="sidebar_color"
                validate="required"
                defaultValue={formData.sidebar_color}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              <ColorPicker
                label="Footer Color"
                placeholder="Select Footer Color"
                name="footer_color"
                validate="required"
                defaultValue={formData.footer_color}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              <ColorPicker
                label="Header Color"
                placeholder="Select Header Color"
                name="header_color"
                validate="required"
                defaultValue={formData.header_color}
                onChange={handleInputChange}
              />
            </Col>

            <Col>
              <ColorPicker
                label="Background Color"
                placeholder="Select Background Color"
                name="background_color"
                validate="required"
                defaultValue={formData.background_color}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button type="submit" value="Continue" size="md" width={100} />
        </Col>
      </Row>
    </form>
  );
};

export default CompanyInfo;
