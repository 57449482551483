import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';

// Components
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import Button from '../../components/Button';
import { Checkbox } from '../../components/inputs';
import CreateProvider from './components/CreateProvider';
import ContentWrapper from '../../components/layout/ContentWrapper';
import ActivateProviderModal from './components/Modals/ActivateProviderModal';
import DeactivateProviderModal from './components/Modals/DeactivateProviderModal';

// Utils
import baseAPI from '../../api';
import { getProviders } from '../../api/provider';
import { ACTIVATE, DEACTIVATE } from '../../utilities/constants';
import { useUserTypes } from '../../utilities/hooks/useUserTypes';
import { fetchUsersByTypeAction } from '../../redux/actions/users';

const StyledProviders = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }
`;

const ProvidersTable = ({ providers, meta, handleFilters }) => {
  const { push } = useHistory();
  const [modal, setModal] = useState({
    [ACTIVATE]: false,
    [DEACTIVATE]: false,
  });

  const toggleModal = (state) => {
    setModal({
      ...modal,
      [state]: !modal[state],
    });
  };

  const [currentRowValues, setCurrentRowValues] = useState({});

  return (
    <Row>
      <Col>
        <Table
          filterAction={handleFilters}
          showFilter
          header={[
            { key: 'id', label: 'ID', hidden: true },
            {
              key: 'firstName',
              label: (
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                  Name
                </div>
              ),
              sort: true,
              formatter: (_, { companyInformation }) => (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                    <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                    <div>
                      <div style={{ fontWeight: 500 }}>{companyInformation[0]?.companyName}</div>
                      <div className="text-muted">{companyInformation[0]?.companyEmail}</div>
                    </div>
                  </div>
                </>
              ),
            },
            { key: 'email', label: 'Email', sort: true },
            { key: 'country', label: 'Country', sort: true },
            { key: 'phoneNumber', label: 'Phone Number', sort: true },
            {
              key: 'menu',
              label: '',
              formatter: (x, provider) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    value="Details"
                    group="secondary"
                    size="sm"
                    width={70}
                    margin={[0, 10, 0, 0]}
                    onClick={() => {
                      push({
                        pathname: `/admin/providers/${provider.id}`,
                      });
                    }}
                  />
                  {provider.is_active ? (
                    <Icon
                      name="closesolid"
                      width={26}
                      margin={[0, 0, 0, 10]}
                      onClick={() => {
                        setCurrentRowValues(provider);
                        toggleModal(DEACTIVATE);
                      }}
                    />
                  ) : (
                    <Icon
                      name="power"
                      width={20}
                      margin={[0, 0, 0, 12]}
                      onClick={() => {
                        setCurrentRowValues(provider);
                        toggleModal(ACTIVATE);
                      }}
                    />
                  )}
                </div>
              ),
            },
          ]}
          data={providers}
          paginate
          meta={meta}
        />
      </Col>
      {modal[DEACTIVATE] && (
        <DeactivateProviderModal
          show={modal[DEACTIVATE]}
          data={currentRowValues}
          closeModal={() => toggleModal(DEACTIVATE)}
        />
      )}
      {modal[ACTIVATE] && (
        <ActivateProviderModal
          show={modal[ACTIVATE]}
          data={currentRowValues}
          closeModal={() => toggleModal(ACTIVATE)}
        />
      )}
    </Row>
  );
};

const Providers = () => {
  const [showCreateProviderModal, setShowCreateProviderModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [payload] = useState({ type: '' });

  const getProvidersQuery = useQuery(
    ['providers', filters],
    async () => {
      const response = await baseAPI.get(getProviders, {
        params: {
          ...(filters || {}),
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {},
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const dispatch = useDispatch();
  const provider = useUserTypes('Provider');

  const APIfetchUsersByType = useCallback(
    (params = {}) => dispatch(fetchUsersByTypeAction(params)),
    [dispatch]
  );

  useEffect(() => {
    if (Object.keys(provider || {})?.length) {
      APIfetchUsersByType({ ...payload, type: provider.id });
    }
  }, [provider]);

  const handleFilters = (filters) => {
    const newFilters = { ...filters };
    delete newFilters?.total;

    setFilters((prev) => ({ ...prev, ...newFilters }));
  };
  return (
    <StyledProviders>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 30,
        }}
      >
        <h5>All providers</h5>
        <Button
          value="Create Provider"
          group="secondary round"
          icon="plus-circle"
          width={140}
          onClick={() => setShowCreateProviderModal(true)}
          margin={[0, 0, 0, 10]}
        />
      </div>
      <ContentWrapper errorTitle="Error fetching providers" currentQuery={getProvidersQuery}>
        <ProvidersTable
          providers={getProvidersQuery?.data?.data}
          meta={{
            total: getProvidersQuery?.data?.total,
            page: getProvidersQuery?.data?.page,
            limit: getProvidersQuery?.data?.limit ?? 25,
          }}
          handleFilters={handleFilters}
        />
      </ContentWrapper>

      {showCreateProviderModal && (
        <CreateProvider
          show={showCreateProviderModal}
          onClose={() => setShowCreateProviderModal(false)}
        />
      )}
    </StyledProviders>
  );
};

export default Providers;
