import { Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';

// Components
import Card from '../Card';
import Chart from '../Chart';
import { Select } from '../inputs';

// Utils
import { APITransactionChart } from '../../api/backendRoutes';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const options = ({ merchants = [], partners = [], agents = [] }) => {
  const a = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      itemWidth: 10,
      itemHeight: 10,
      data: ['Clients', 'Agents', 'Partners'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        axisLine: { color: '#999999' },
        axisTick: { show: false },
        type: 'category',
        data: merchants.map((merchant) => merchant?.label),
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Agents',
        type: 'bar',
        barWidth: 7,
        stack: '搜索引擎',
        itemStyle: {
          emphasis: {
            borderRadius: [50, 50],
          },
          normal: {
            borderRadius: [50, 50, 50, 50],
          },
        },
        color: '#509FEF',
        emphasis: {
          focus: 'series',
        },
        data: agents.map((agent) => agent.amount),
      },
      {
        name: 'Clients',
        type: 'bar',
        stack: '搜索引擎',
        itemStyle: {
          emphasis: {
            borderRadius: [50, 50],
          },
          normal: {
            borderRadius: [50, 50, 50, 50],
          },
        },
        color: '#17568B',
        emphasis: {
          focus: 'series',
        },
        data: merchants.map((merchant) => merchant.amount),
      },
      {
        name: 'Partners',
        type: 'bar',
        stack: '搜索引擎',
        itemStyle: {
          emphasis: {
            borderRadius: [50, 50],
          },
          normal: {
            borderRadius: [50, 50, 50, 50],
          },
        },
        color: 'rgba(101, 177, 255, 0.26)',
        emphasis: {
          focus: 'series',
        },
        data: partners.map((partner) => partner.amount),
      },
    ],
  };

  return a;
};

const TransactionChart = () => {
  const [dateFilter, setDateFilter] = useState();
  const [transactionData, setTransactionData] = useState({
    merchants: [],
    partners: [],
    agents: [],
  });

  const unique = (arr) => {
    if (dateFilter?.value === 'week') {
      const merged = days.map((day) => {
        const found = arr?.items?.find((data) => data.label === day);
        if (found) {
          return {
            amount: found.amount,
            label: found.label,
          };
        }
        return {
          amount: 0,
          label: day,
        };
      });

      return merged.sort(function (a, b) {
        return days.indexOf(a?.day) - days.indexOf(b?.day);
      });
    }
    const merged = months.map((month) => {
      const found = arr?.items?.find((data) => data.label === month);
      if (found) {
        return {
          amount: found.amount,
          label: found.label,
        };
      }
      return {
        amount: 0,
        label: month,
      };
    });

    return merged.sort(function (a, b) {
      return months.indexOf(a?.label) - months.indexOf(b?.label);
    });
  };

  const getTransactions = async () => {
    try {
      const response = await APITransactionChart({ type: dateFilter?.value || 'month' });
      if (response.data.message === 'ok') {
        const merchants = response.data.data.find(
          ({ userType }) => userType?.toLowerCase() === 'merchant'
        );
        const partners = response.data.data.find(
          ({ userType }) => userType?.toLowerCase() === 'partner'
        );
        const agents = response.data.data.find(
          ({ userType }) => userType?.toLowerCase() === 'agents'
        );

        setTransactionData({
          merchants: unique(merchants),
          partners: unique(partners),
          agents: unique(agents),
        });
      } else {
        throw response?.data?.error;
      }
    } catch (error) {
      /* eslint-disable-no-empty */
    }
  };

  useEffect(() => {
    getTransactions();
    return () => {
      setTransactionData({
        merchants: [],
        partners: [],
        agents: [],
      });
    };
  }, [dateFilter]);

  return (
    <>
      <Col md={12}>
        <Card>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h4>Average Transactions</h4>
            <Select
              onChange={(value) => setDateFilter(value)}
              size="tiny"
              options={[
                { label: 'Weekly', value: 'week' },
                { label: 'Monthly', value: 'month' },
              ]}
              defaultValue="month"
              style={{ minWidth: 80 }}
              padding={0}
            />
          </div>
          {transactionData && (
            <div style={{ marginTop: 10 }}>
              <Chart option={options(transactionData)} />
            </div>
          )}
        </Card>
      </Col>
    </>
  );
};

export default TransactionChart;
