import { useContext } from 'react';
import { UserTypesContext } from '../context/UserTypesContext';

export function useUserTypes(type = '') {
  const state = useContext(UserTypesContext);
  const accountType = state?.userTypes?.find(
    (s) => s.accountTypeName.toLowerCase() === type?.toLowerCase()
  );
  return accountType;
}

export function useUser() {
  const { user } = useContext(UserTypesContext);
  return user;
}
