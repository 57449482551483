import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Table from '../../components/Table';
import { Select } from '../../components/inputs';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import { AppContext } from '../../utilities/context/AppContext';
import { fetchAdminWalletAction } from '../../redux/actions/wallet';
import { APIWalletSummary } from '../../api/backendRoutes';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;

  > div {
    display: flex;
    align-items: center;
  }

  h6 {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    padding: 7px 20px;
    font-weight: 600;
    font-size: 24px;
    margin: 10px 0;
  }

  &.green-bg {
    background: linear-gradient(283.69deg, #126b24 13.67%, #1c8932 49.49%, #239d3c 91.56%);
  }

  &.red-bg {
    background: linear-gradient(283.69deg, #6b1212 13.67%, #891c1c 49.49%, #9d2323 91.56%);
  }

  &.blue-bg {
    background: linear-gradient(283.69deg, #12266b 13.67%, #1c5589 49.49%, #23549d 91.56%);
  }
`;
const intervalFormatter = (int) => {
  if (int === 'daily') {
    return 'Day';
  }
  return int.slice(0, int.length - 2);
};
const Wallets = () => {
  const [walletSummary, setWalletSummary] = useState({});
  const [interval, setInterval] = useState('monthly');
  const { setGoBackMethod } = useContext(AppContext);
  const mapStateToProps = ({ wallet }) => {
    return {
      fetchingWallet: wallet.fetchingWallet,
      wallet: wallet.wallet,
      meta: wallet.walletMeta,
    };
  };
  const history = useHistory();
  const dispatch = useDispatch();

  const { fetchingWallet, wallet, meta } = useSelector(mapStateToProps);

  const fetchWallet = useCallback((params) =>
    dispatch(fetchAdminWalletAction({ ...params, limit: 25 }), [dispatch])
  );

  async function fetchWalletSummary(params) {
    const response = await APIWalletSummary(params);
    setWalletSummary(response.data.data);
  }
  useEffect(() => {
    fetchWallet();
    fetchWalletSummary({ period: 'monthly' });
  }, []);

  const handlePagination = ({ selected }) => {
    fetchWallet({ page: selected });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 30,
        }}
      >
        <h5>Wallets Information</h5>

        <div style={{ display: 'flex' }}>
          {/* <Select
                size="tiny"
                options={[
                  { label: 'All', value: 'all' },
                  { label: 'Merchants', value: 'merchants' },
                  { label: 'Agents', value: 'agents' },
                  { label: 'Partners', value: 'partners' },
                ]}
                defaultValue="agents"
                style={{ minWidth: 80, marginRight: 10 }}
                padding={0}
              /> */}

          <Select
            size="tiny"
            options={[
              { label: 'Daily', value: 'daily' },
              { label: 'Weekly', value: 'weekly' },
              { label: 'Monthly', value: 'monthly' },
              { label: 'Yearly', value: 'yearly' },
            ]}
            onChange={(e) => {
              fetchWalletSummary({ period: e.value });
              setInterval(e.value);
            }}
            defaultValue="monthly"
            style={{ minWidth: 80 }}
            padding={0}
          />
        </div>
      </div>

      <Row style={{ marginTop: 50, marginBottom: 50 }}>
        <Col md={4}>
          <StyledCard className="green-bg">
            <div>Total Wallet Inflow:</div>
            <h6>
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="₦ "
                value={walletSummary?.currentPeriodSummary?.totalWalletInflow.toFixed(2) || 0}
                className=""
              />
            </h6>
            <div>
              <Icon name="trending-up" width={22} margin={[0, 10, 0, 0]} />
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="₦ "
                value={(
                  walletSummary?.currentPeriodSummary?.totalWalletInflow -
                    walletSummary?.previousPeriodSummary?.totalWalletInflow || 0
                ).toFixed(2)}
                className=""
              />{' '}
              <span className="ml-1">This {intervalFormatter(interval)}</span>
            </div>
          </StyledCard>
        </Col>
        <Col md={4}>
          <StyledCard className="red-bg">
            <div>Total Wallet Outflow:</div>
            <h6>
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="₦ "
                value={walletSummary?.currentPeriodSummary?.totalWalletOutflow.toFixed(2) || 0}
                className=""
              />
            </h6>
            <div>
              <Icon name="trending-down" width={22} margin={[0, 10, 0, 0]} />
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="₦ "
                value={(
                  walletSummary?.currentPeriodSummary?.totalWalletOutflow -
                    walletSummary?.previousPeriodSummary?.totalWalletOutflow || 0
                ).toFixed(2)}
                className=""
              />{' '}
              <span className="ml-1">This {intervalFormatter(interval)}</span>{' '}
            </div>
          </StyledCard>
        </Col>
        <Col md={4}>
          <StyledCard className="blue-bg h-100">
            <div>Total Wallet Balance:</div>
            <h6>
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="₦ "
                value={walletSummary?.wallets?.totalBalance.toFixed(2) || 0}
                className=""
              />
            </h6>
          </StyledCard>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table
            noBorder={false}
            filterAction={fetchWallet}
            filter={{
              Date: [
                { value: 'Today', label: 'Today' },
                { value: 'This Week', label: 'This Week' },
                { value: 'This Month', label: 'This Month' },
                { value: 'Last 30 Days', label: 'Last 30 Days' },
                { value: 'This Year', label: 'This Year' },
                { value: 'Period', label: 'Specific Period' },
              ],
              name: 'name',
            }}
            showFilter
            header={[
              {
                key: 'name',
                label: (
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>Wallet Name</div>
                ),
                sort: true,
                formatter: (name, { email, accountName }) => (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                      <div>
                        <div>{accountName}</div>
                        <div className="text-muted">{email}</div>
                      </div>
                    </div>
                  </>
                ),
              },
              {
                key: 'totalDebit',
                label: 'Total Debit',
                sort: true,
                formatter: (total_debit) => (
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    prefix="₦"
                    value={total_debit}
                    className="red"
                  />
                ),
              },
              {
                key: 'totalCredit',
                label: 'Total Credit',
                sort: true,
                formatter: (total_credit) => (
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    prefix="₦"
                    value={total_credit}
                    className="green"
                  />
                ),
              },
              {
                key: 'walletBalance',
                label: 'Wallet Balance',
                sort: true,
                formatter: (balance) => (
                  <NumberFormat displayType="text" thousandSeparator prefix="₦" value={balance} />
                ),
              },
              {
                key: 'accountNumber',
                label: 'Account Number',
              },
              {
                key: 'menu',
                label: '',
                formatter: (menu, record) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      value="Details"
                      group="secondary"
                      size="sm"
                      width={70}
                      margin={[0, 10, 0, 0]}
                      onClick={() => {
                        setGoBackMethod(() => history.goBack());
                        history.push(`/admin/wallet/${record.merchantOrganizationId}`);
                      }}
                    />
                  </div>
                ),
              },
            ]}
            data={wallet}
            loading={fetchingWallet}
            paginate={Boolean(Object.keys(meta).length)}
            meta={meta}
            handlePagination={handlePagination}
          />
        </Col>
      </Row>
    </>
  );
};

export default Wallets;
