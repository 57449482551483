/* eslint-disable */
import Toast from 'react-hot-toast';
import { APIMerchantWalletListing } from '../../api/backendRoutes';
import {
  fetchAdminWalletDetailsSuccessReducer,
  fetchingAdminWalletDetailsReducer,
  fetchingAdminWalletDetailsErrorReducer,
} from '../slices/walletDetails';

export const fetchAdminWalletDetailsAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingAdminWalletDetailsReducer());
    try {
      const resp = await APIMerchantWalletListing(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchAdminWalletDetailsSuccessReducer(resp.data));
      } else {
        dispatch(fetchingAdminWalletDetailsErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'error');
    }
  };
