import { QueryClientProvider, QueryClient } from 'react-query';
import { RecoilRoot } from 'recoil';
import { Toaster } from 'react-hot-toast';
// import { Normalize } from 'styled-normalize';
import { ThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { CssBaseline } from '@material-ui/core';
import { LastLocationProvider } from 'react-router-last-location';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Switch, Router, Redirect } from './components/router/Router';
import GlobalStyle from './GlobalStyle';
import theme from './utilities/theme';
import { AppProvider } from './utilities/context/AppContext';
import { AuthProvider } from './utilities/context/AuthContext';
import PublicRoutes, { PublicRoutesGroup } from './components/router/publicRoutes';
import PrivateRoutes, { ProtectedRoutesGroup } from './components/router/privateRoutes';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <>
      {/* The rest of your application */}
      <GlobalStyle />
      {/* <Normalize /> */}
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <RecoilRoot>
              <Toaster position="top-center" />
              <Router>
                <LastLocationProvider>
                  <AppProvider>
                    <AuthProvider>
                      <Switch>
                        <PrivateRoutes path="/admin" component={ProtectedRoutesGroup} />
                        <PublicRoutes path="/" component={PublicRoutesGroup} />
                        <Redirect path="*" to="/" />
                      </Switch>
                    </AuthProvider>
                  </AppProvider>
                </LastLocationProvider>
              </Router>
            </RecoilRoot>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
