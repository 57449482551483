/* eslint-disable */
import Toast from 'react-hot-toast';
import api from '../../api';
import {
  fetchingAdminUsersByTypeReducer,
  fetchAdminUsersByTypesSuccessReducer,
  fetchingAdminUsersByTypeErrorReducer,
  fetchAccountTypesSuccessReducer,
  fetchingAccountTypesReducer,
  fetchingAccountTypesErrorReducer,
} from '../slices/users';
import {
  fetchingUserProfileReducer,
  fetchLoggedInUserProfile,
  errorFetchingProfileReducer,
} from '../slices/userProfile';
import {
  APIfetchAccountTypes,
  APIfetchUserProfile,
  APIfetchUsersByType,
} from '../../api/backendRoutes';

export const fetchUsersByTypeAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingAdminUsersByTypeReducer());
    try {
      const resp = await APIfetchUsersByType(params);
      if (resp.data.message === 'successful') {
        dispatch(fetchAdminUsersByTypesSuccessReducer(resp.data));
      } else {
        dispatch(fetchingAdminUsersByTypeErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'error');
    }
  };

export const fetchAccountsTypeAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingAccountTypesReducer());
    try {
      const resp = await APIfetchAccountTypes(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchingAccountTypesSuccessReducer(resp.data));
      } else {
        dispatch(fetchingAccountTypesErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'error');
    }
  };

export const fetchUserProfileAction = () => async (dispatch) => {
  dispatch(fetchingUserProfileReducer());
  try {
    const resp = await APIfetchUserProfile();
    if (resp.data.message === 'successful') {
      dispatch(fetchLoggedInUserProfile(resp.data.data));
    } else {
      dispatch(errorFetchingProfileReducer());
    }
  } catch (error) {
    Toast.error(errorParser(error) || 'error');
  }
};
