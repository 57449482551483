import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { KeyboardDatePicker } from '@material-ui/pickers';

const StyledDate = styled.div`
  border: 1px solid #bdbdbd;
  margin-bottom: 0;
  border-radius: 10px;
  padding: 0px 6px;
  background: #ffffff;
  > div {
    width: 100%;
  }
  .MuiFormControl-marginNormal {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

const DateInput = ({
  label,
  placeholder = '',
  disabled = false,
  type = 'text',
  variant = 'outlined',
  hint,
  value,
  name,
  defaultValue,
  multiline = false,
  rows = 3,
  onChange,
  validate,
  icon,
  iconPosition,
  format = 'dddd, Do MMM yyyy',
  ...rest
}) => {
  const [errors] = useState([]);
  const [data, setData] = useState(defaultValue);

  useEffect(() => {
    if (onChange) {
      onChange({ name, value: data });
    }
  }, [data]);

  const handleOnChange = (date) => {
    setData(date);

    onChange({ name, value: date });

    // const { name: n, value: v } = e.target;
    // setData(v);
    // if (errors) {
    //   handleValidation(e);
    // } else if (onChange) {
    //   onChange({ name: n, value: v });
    // }
  };

  return (
    <StyledDate>
      <KeyboardDatePicker
        name={name}
        defaultValue={defaultValue}
        label={label}
        variant={variant}
        placeholder={placeholder || label}
        disabled={disabled}
        aria-label={label}
        aria-describedby={label}
        format={format}
        margin="normal"
        id="date-picker-inline"
        value={data}
        onChange={handleOnChange}
        // onBlur={handleValidation}
        error={errors.length > 0}
        helperText={errors.length > 0 ? errors[0] : ''}
        required={!!validate}
        {...rest}
      />
    </StyledDate>
  );
};

export default DateInput;
