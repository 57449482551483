import { useState } from 'react';
import { useQuery } from 'react-query';

// Components
import { Select } from '../../inputs';

// Utils
import baseAPI from '../../../api';
import { getProvider } from '../../../api/provider';
import { APIlistProviderService } from '../../../api/backendRoutes';

const ProviderServiceSelect = ({
  label,
  placeholder,
  name,
  validate,
  defaultValue,
  onChange,
  provider,
}) => {
  const [options, setOptions] = useState([]);
  const [providerInfo, setProviderInfo] = useState();
  const [isEnabled, setIsEnabled] = useState(false);

  useQuery(
    ['providers', provider?.id],
    async () => {
      const response = await baseAPI.get(getProvider(provider?.id));
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data?.success) {
          setProviderInfo(data?.data);
          setIsEnabled(true);
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    ['services', provider.id],
    async () => {
      const response = await APIlistProviderService({
        params: {
          companyId: providerInfo?.companyInformation?.[0]?.id,
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data?.data) {
          setOptions(
            data?.data?.map((service) => ({
              label: service?.serviceName,
              value: service?._id,
              ...service,
            }))
            /* eslint no-underscore-dangle: 0 */
          );
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEnabled,
    }
  );

  return (
    <Select
      label={label}
      placeholder={placeholder}
      name={name}
      validate={validate}
      defaultValue={defaultValue}
      onChange={(e) =>
        onChange(
          e,
          options.find((option) => option?.value === e?.value)
        )
      }
      options={options}
    />
  );
};

export default ProviderServiceSelect;
