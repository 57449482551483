import { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Box, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import Button from '../../../components/Button';
import { Input, PasswordInput } from '../../../components/inputs';
import { AuthContext } from '../../../utilities/context/AuthContext';
import { ROUTES } from '../../../components/router/routes/routes';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  typography: {
    lineHeight: '23.92px',
    fontWeight: 400,
    fontSize: '16px',
    color: '#505050',
    '& span': {
      color: '#509FEF',
      marginLeft: '7px',
      cursor: 'pointer',
    },
  },
});

const SignIn = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({ email: '', password: '', fetching: false });

  const { signIn } = useContext(AuthContext);

  const handleSignIn = (e) => {
    e.preventDefault();

    const { email, password } = formData;

    setFormData((d) => ({ ...d, fetching: true }));
    signIn({ email, password }).finally(() => {
      setFormData((d) => ({ ...d, fetching: false }));
    });
  };

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}
    >
      <h1 style={{ fontWeight: 600 }}>Welcome back, Login</h1>
      <form className={classes.root} style={{ marginTop: 40 }} onSubmit={handleSignIn}>
        <Row>
          <Col>
            <Input
              label="Company Email"
              name="email"
              hint="*This email should be an admin or a primary contact for your brand"
              onChange={handleInputChange}
              value={formData.email}
              validate="required|email"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <PasswordInput
              label="Password"
              name="password"
              onChange={handleInputChange}
              value={formData.password}
              validate="required"
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Button
              value="Login"
              size="lg"
              width={isMobile ? '100%' : 200}
              variant="contained"
              loading={formData.fetching}
              type="submit"
            />
          </Col>
        </Row>

        <Row>
          <Col
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'space-between',
              marginTop: 30,
            }}
          >
            <Typography className={classes.typography}>
              Forgot Password?{' '}
              <Box component="span">
                <Link component={RouterLink} to={ROUTES.FORGOT_PASSWORD.path}>
                  Reset
                </Link>
              </Box>
            </Typography>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default SignIn;
