import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Icon from '../../../components/Icon';
import Accordion from '../../../components/Accordion';
import { Input, PhoneInput } from '../../../components/inputs';

const StyledAccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  color: #505050;
  font-size: 14px;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledAccordionBody = styled.div`
  padding-bottom: 20px;
`;
const MerchantProfile = ({ merchant, walletDetails }) => {
  const singleMerchant = merchant[0];
  const profile = useMemo(
    () => [
      {
        header: (
          <StyledAccordionHeader>
            <div>
              <Icon name="user-lock" width={18} margin={[0, 20, 0, 0]} />
              Representative Information
            </div>
            <Accordion.Toggle value={<Icon name="caret-down" width={24} />} eventKey="2" />
          </StyledAccordionHeader>
        ),
        body: (
          <StyledAccordionBody>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="First Name"
                  placeholder="First Name"
                  name="first_name"
                  value={singleMerchant?.firstName}
                  disabled
                  type="text"
                  validate="required"
                />
              </Col>
              <Col md={6} sm={6}>
                <Input
                  label="Last Name"
                  placeholder="Last Name"
                  value={singleMerchant?.lastName}
                  disabled
                  name="last_name"
                  type="text"
                  validate="required"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="Email"
                  placeholder="Enter Email"
                  name="email"
                  type="email"
                  value={singleMerchant?.email}
                  disabled
                  validate="required"
                />
              </Col>
              <Col md={6} sm={6}>
                <PhoneInput
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  name="phone_number"
                  value={singleMerchant?.phoneNumber}
                  disabled
                  validate="required"
                />
              </Col>
            </Row>
          </StyledAccordionBody>
        ),
        eventKey: '2',
      },
      {
        header: (
          <StyledAccordionHeader>
            <div>
              <Icon name="building" width={18} margin={[0, 20, 0, 0]} />
              Company Information
            </div>
            <Accordion.Toggle value={<Icon name="caret-down" width={24} />} eventKey="1" />
          </StyledAccordionHeader>
        ),
        body: (
          <StyledAccordionBody>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="Company Name"
                  placeholder="Company Name"
                  name="company_name"
                  type="text"
                  validate="required"
                  value={singleMerchant?.businessName}
                  disabled
                />
              </Col>
              <Col md={6} sm={6}>
                <Input
                  label="Email"
                  placeholder="Enter Email"
                  name="email"
                  type="email"
                  validate="required"
                  value={singleMerchant?.email}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <PhoneInput
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  name="phone_number"
                  validate="required"
                  value={singleMerchant?.phoneNumber}
                  disabled
                />
              </Col>
              <Col md={6} sm={6}>
                <Input
                  label="Address"
                  placeholder="Address"
                  name="address"
                  type="text"
                  validate="required"
                  value={singleMerchant?.address}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="City"
                  placeholder="City"
                  name="city"
                  type="text"
                  validate="required"
                  value={singleMerchant?.city}
                  disabled
                />
              </Col>
              <Col md={6} sm={6}>
                <Input
                  label="State"
                  placeholder="State"
                  name="state"
                  type="text"
                  validate="required"
                  value={singleMerchant?.state}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="Country"
                  placeholder="Country"
                  name="country"
                  type="text"
                  validate="required"
                  value={singleMerchant?.country}
                  disabled
                />
              </Col>
            </Row>
          </StyledAccordionBody>
        ),
        eventKey: '1',
      },
      {
        header: (
          <StyledAccordionHeader>
            <div>
              <Icon name="bank" width={16} margin={[0, 20, 0, 0]} />
              Bank Information
            </div>
            <Accordion.Toggle value={<Icon name="caret-down" width={24} />} eventKey="3" />
          </StyledAccordionHeader>
        ),
        body: (
          <StyledAccordionBody>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="Bank Name"
                  placeholder="Bank Name"
                  name="bank_name"
                  type="text"
                  validate="required"
                  value={walletDetails?.bank_name}
                  disabled
                />
              </Col>
              <Col md={6} sm={6}>
                <Input
                  label="Bank Account Name"
                  placeholder="Bank Account Name"
                  name="bank_account_name"
                  type="text"
                  validate="required"
                  value={walletDetails?.account_name}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="Bank Account Number"
                  placeholder="Bank Account Number"
                  name="bank_account_number"
                  type="number"
                  validate="required"
                  value={walletDetails?.account_number}
                  disabled
                />
              </Col>
              <Col md={6} sm={6}>
                <p>Balance</p>
                <NumberFormat
                  displayType="text"
                  thousandSeparator
                  prefix="₦"
                  value={walletDetails?.balance || 0}
                />
              </Col>
            </Row>
          </StyledAccordionBody>
        ),
        eventKey: '3',
      },
    ],
    []
  );
  return (
    <Row>
      <Col md={8} sm={8} style={{ paddingRight: 0, borderRight: '0.3px solid #ececec' }}>
        <Accordion data={profile} />
      </Col>
      <Col md={4} sm={4}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 30,
            paddingBottom: 60,
            alignItems: 'center',
          }}
        >
          <Icon name="avatar" width={160} margin={[0, 0, 20, 0]} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px',
                borderRight: '1px solid #e7e7e7',
                color: '#df374b',
                fontWeight: 600,
                fontSize: 12,
              }}
            >
              <Icon name="trash-circle" width={20} margin={[0, 5, 0, 0]} /> Delete
            </span>{' '}
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px',
                color: '#17568B',
                fontWeight: 600,
                fontSize: 12,
              }}
            >
              {' '}
              <Icon name="upload" width={20} margin={[0, 5, 0, 0]} /> Upload
            </span>{' '}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default MerchantProfile;
