/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reports: [],
  fetchingReports: false,
  reportsMeta: {},
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    fetchAdminReportsSuccessReducer: (state, { payload }) => {
      state.reports = payload.data;
      state.fetchingReports = false;
      state.reportsMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingAdminReportsReducer: (state) => {
      state.fetchingReports = true;
      state.reports = [];
      state.reportsMeta = {};
    },
    fetchingAdminReportsErrorReducer: (state) => {
      state.fetchingReports = false;
      state.reports = [];
      state.reportsMeta = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchAdminReportsSuccessReducer,
  fetchingAdminReportsReducer,
  fetchingAdminReportsErrorReducer,
} = reportsSlice.actions;

export default reportsSlice.reducer;
