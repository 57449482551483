import { useState } from 'react';
import Moment from 'react-moment';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

// Components
import Icon from '../../../components/Icon';
import Table from '../../../components/Table';
import ContentWrapper from '../../../components/layout/ContentWrapper';

// Utils
import { APIPartnerWallet, APIPartnerWalletTransaction } from '../../../api/backendRoutes';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;

  > div {
    display: flex;
    align-items: center;
  }

  h6 {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    padding: 7px 20px;
    font-weight: 600;
    font-size: 24px;
    margin: 10px 0;
  }

  &.green-bg {
    background: linear-gradient(283.69deg, #126b24 13.67%, #1c8932 49.49%, #239d3c 91.56%);
  }

  &.red-bg {
    background: linear-gradient(283.69deg, #6b1212 13.67%, #891c1c 49.49%, #9d2323 91.56%);
  }

  &.blue-bg {
    background: linear-gradient(283.69deg, #12266b 13.67%, #1c5589 49.49%, #23549d 91.56%);
  }
`;

const WalletTransactions = ({ partner }) => {
  const [walletInfo, setWalletInfo] = useState(0);

  const getPartnerWalletQuery = useQuery(
    ['partner_wallet', partner.id],
    async () => {
      const response = await APIPartnerWallet({
        params: {
          wallet_reference: partner?.companyInformation?.[0]?.id,
        },
      });
      return response.data;
    },
    {
      onSuccess: ({ data }) => {
        setWalletInfo(data?.data[0]);
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const getPartnerWalletTransactionQuery = useQuery(
    ['partner_wallet_transaction', partner.id],
    async () => {
      const response = await APIPartnerWalletTransaction(partner?.companyInformation?.[0]?.id, {});
      return response.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <ContentWrapper
        errorTitle="Error fetching wallet information"
        currentQuery={getPartnerWalletQuery}
      >
        <div className="my-3">
          <Row style={{ justifyContent: 'end' }}>
            <Col md={4}>
              <StyledCard className="blue-bg">
                <div>Total Wallet Balance:</div>
                <h6>
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    prefix={`${walletInfo?.currency} `}
                    value={walletInfo?.balance || 0}
                    className=""
                  />
                </h6>
              </StyledCard>
            </Col>
          </Row>
        </div>
      </ContentWrapper>
      <ContentWrapper
        errorTitle="Error fetching wallet transaction"
        currentQuery={getPartnerWalletTransactionQuery}
      >
        <Table
          header={[
            {
              key: 'transaction',
              label: 'Transaction Name',
              formatter: (_, { narration, action }) => (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon
                      name={action === 'credit' ? 'increase' : 'decrease'}
                      margin={[0, 10, 0, 0]}
                      width={36}
                    />
                    <div>
                      <div style={{ fontWeight: 500 }}>{narration}</div>
                    </div>
                  </div>
                </>
              ),
            },
            {
              key: 'reference',
              label: 'Transaction ID',
            },
            {
              key: 'amount',
              label: 'Debit/Credit',
              formatter: (amount, { currency }) => (
                <NumberFormat
                  displayType="text"
                  thousandSeparator
                  prefix={`${currency} `}
                  value={amount}
                  className={amount > 0 ? 'green' : 'red'}
                />
              ),
            },
            {
              key: 'createdAt',
              label: 'Date',
              formatter: (createdAt) => (
                <div style={{ display: 'flex' }}>
                  <Moment format="D MMM, YYYY - hh:mma" date={createdAt} />
                </div>
              ),
            },
          ]}
          data={getPartnerWalletTransactionQuery?.data?.data}
          meta={{
            limit: getPartnerWalletTransactionQuery?.data?.limit,
            total: getPartnerWalletTransactionQuery?.data?.total?.[0]?.total,
            page: getPartnerWalletTransactionQuery?.data?.page,
          }}
          theadBg="#ffffff"
          noBorder
        />
      </ContentWrapper>
    </>
  );
};
export default WalletTransactions;
