/* eslint-disable import/namespace */
import { useMemo } from 'react';
import styled from 'styled-components';
import * as icons from '../assets/icons';

const StyledIcon = styled.div.attrs((props) => ({ ...props }))`
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${({ width }) => (width ? `${width}px` : '')};
  height: ${({ height }) => (height ? `${height}px` : '')};

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    path {
      fill: ${(props) => props.color} !important;
    }
  }
  .profile_pic {
    position: absolute;
    right: 0;
    bottom: 40px;
    padding: 10px;
    border-radius: 100%;
    background-color: #aaaaaa;
    cursor: pointer;
  }
  .logo svg {
    background: pink !important;
  }
`;

const Icon = ({
  name = '',
  margin = [],
  padding = [],
  color = '',
  size = 'sm',
  title = '',
  width,
  height,
  onClick,
  className,
  ...rest
}) => {
  const SVGIcon = useMemo(() => icons[name.replace(/-/g, '')], [name]);

  return name && icons[name.replace(/-/g, '')] ? (
    <StyledIcon
      margin={margin.length > 0 && `${margin.join('px ')}px;`}
      padding={padding.length > 0 && `${padding.join('px ')}px;`}
      color={color}
      size={size}
      width={width}
      height={height}
      {...rest}
    >
      <div
        className={className}
        onClick={(e) => onClick && onClick(e)}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
        title={title}
      >
        {SVGIcon && (
          <SVGIcon
            {...(color ? { fill: { color } } : {})}
            className="logo"
            alt={name}
            width={width || 'auto'}
            height={height || 'auto'}
          />
        )}
        {/* {(icons as any)[name.replace(/-/g, '')] && (
          <img
            alt={name}
            src={(icons as any)[name.replace(/-/g, '')]}
            className="logo"
            width={width || 'auto'}
            height={height || 'auto'}
          />
        )} */}
      </div>
    </StyledIcon>
  ) : null;
};

export default Icon;
