import { useContext } from 'react';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom';
import { ROUTES } from '../routes/routes';
import Loader from '../../Loader';
import Auth from '../../../pages/auth/Auth';
import { fetchUserData } from '../../../utilities/storage';
import { UserTypesContext } from '../../../utilities/context/UserTypesContext';

export const publicRoutes = [
  {
    component: Auth,
    routes: ROUTES.LANDING,
  },
  {
    component: Auth,
    routes: ROUTES.SIGN_IN,
  },
  {
    component: Auth,
    routes: ROUTES.RESET_PASSWORD,
  },
  {
    component: Auth,
    routes: ROUTES.FORGOT_PASSWORD,
  },
];

const PublicRoutes = ({ component: Component, ...rest }) => {
  const { token } = fetchUserData();

  return (
    <Route
      {...rest}
      render={(props) => (token ? <Redirect to="/admin/dashboard" /> : <Component {...props} />)}
    />
  );
};

export default withRouter(PublicRoutes);

export const PublicRoutesGroup = () => {
  const { dataLoading } = useContext(UserTypesContext);
  return (
    <>
      {dataLoading ? (
        <Loader />
      ) : (
        <Switch>
          {publicRoutes.map((route, index) => (
            <Route key={index} component={route.component} {...route.routes} />
          ))}
        </Switch>
      )}
    </>
  );
};
