import { Link, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Logo from '../../components/Logo';
import SignIn from './components/SignIn';
import Button from '../../components/Button';
// import ForgotPassword from './components/ForgotPassword';
// import ResetPassword from './components/ResetPassword';
import ShapesImage from '../../assets/images/shapes.png';
import ThoughtfulAfricanImage from '../../assets/images/thoughtful-african.png';
import { ROUTES } from '../../components/router/routes/routes';

const Landing = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}
    >
      <h1 style={{ margin: '20px 0', fontWeight: 700 }}>
        Administrative privilege to monitor all user activity
      </h1>
      <p style={{ margin: '0 0 30px 0' }} className="muted">
        Airtime, Data bundles, Messaging, Bill payments and more across borders
      </p>

      <Link to={ROUTES.SIGN_IN.path}>
        <Button
          value="Begin"
          size="lg"
          width={isMobile ? '100%' : 200}
          variant="contained"
          type="button"
        />
      </Link>
    </div>
  );
};

const Auth = () => {
  return (
    <Auth.Wrapper>
      <div className="left">
        <div style={{ position: 'absolute', bottom: 0, left: 'calc(50% - 40px)', zIndex: 1 }}>
          <img src={ShapesImage} alt="Bottom Shape" width={80} />
        </div>
        <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}>
          <img src={ShapesImage} alt="Bottom Shape" width={80} />
        </div>
        <div className="left-wrapper">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to="/">
              <Logo src="/logo-dark.png" name="Blusalt" />
            </Link>
          </div>
          <div className="left-wrapper-inner">
            <Switch>
              <Route path="/" exact render={(props) => <Landing />} />
              <Route path={ROUTES.SIGN_IN.path} exact render={(props) => <SignIn />} />
              {/* <Route
                path={ROUTES.FORGOT_PASSWORD.path}
                exact
                render={(props) => <ForgotPassword />}
              />
              <Route
                path={ROUTES.RESET_PASSWORD.path}
                exact
                render={(props) => <ResetPassword />}
              /> */}
            </Switch>
          </div>
        </div>
      </div>
      <div className="right" />
    </Auth.Wrapper>
  );
};

export default Auth;

Auth.Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  .left {
    height: 100vh;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .left-wrapper {
      width: 90%;
      .left-wrapper-inner {
        background: #509fef0d;
        border-radius: 10px;
        min-height: 85vh;
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .right {
    min-height: 100vh;
    width: 50%;
    background: url(${ThoughtfulAfricanImage}) no-repeat center;
    background-size: cover;
  }

  @media screen and (max-width: 700px) {
    .right {
      display: none;
    }
    .left {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
`;
