import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import Button from '../../components/Button';
import { Checkbox } from '../../components/inputs';
import { fetchSenderIdAction } from '../../redux/actions/senderId';
import SenderIdApproveModal from './components/SenderIdApproveModal';

const StyledRequests = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }

  .MuiCheckbox-root {
    margin-right: 10px;
    .MuiSvgIcon-root {
      color: #bbbbbb !important;
    }
  }
`;

const mapStateToProps = ({ senderId }) => {
  return {
    fetchingSenderId: senderId.fetchingSenderId,
    senderId: senderId.senderId,
    meta: senderId.senderIdMeta,
  };
};
const Requests = () => {
  const dispatch = useDispatch();
  const [approveRow, setApproveRow] = useState(null);
  const [showApproveModal, setShowApproveModal] = useState(false);

  const { fetchingSenderId, senderId, meta } = useSelector(mapStateToProps);
  const fetchAdminSenderId = useCallback((params = { limit: 25 }) =>
    dispatch(fetchSenderIdAction(params), [dispatch])
  );
  useEffect(() => {
    fetchAdminSenderId();
  }, []);
  return (
    <StyledRequests>
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 30,
          }}
        >
          <h5>All Requests</h5>
        </div>

        <Row>
          <Col>
            <Table
              filterAction={fetchAdminSenderId}
              filter={{
                status: [
                  { value: 'approved', label: 'Approved' },
                  { value: 'rejected', label: 'Rejected' },
                  { value: 'pending', label: 'Pending' },
                ],
                keyword: 'keyword',
              }}
              showFilter
              header={[
                {
                  key: 'name',
                  label: (
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                      Name
                    </div>
                  ),
                  sort: true,
                  formatter: (businessName) => {
                    return (
                      <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                          <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                          <div>
                            <div>{`${businessName}`}</div>
                            {/* <div className="text-muted">{businessName}</div> */}
                          </div>
                        </div>
                      </>
                    );
                  },
                },
                {
                  key: 'merchant',
                  label: 'Merchant',
                  sort: true,
                  formatter: (_, { firstName, lastName }) => `${firstName}, ${lastName}`,
                },
                { key: 'email', label: 'Email', sort: true },
                { key: 'requestCategory', label: 'Category', sort: true },
                {
                  key: 'approval_status',
                  label: 'Status',
                  sort: true,
                  formatter: (status) => (
                    <div className={status.toLowerCase()}>
                      <Button value={status.toUpperCase()} width={120} disabled />
                    </div>
                  ),
                },

                {
                  key: 'createdAt',
                  label: 'Date',
                  sort: true,
                  formatter: (date) => new Date(date).toDateString(),
                },
                {
                  key: 'id',
                  label: '',
                  formatter: (id, row) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Button
                        value="Details"
                        group="secondary"
                        size="sm"
                        width={70}
                        margin={[0, 10, 0, 0]}
                        onClick={() => {
                          setApproveRow(row);
                          setShowApproveModal(true);
                        }}
                      />
                      {/* <Icon name="trashcircle" width={26} margin={[0, 0, 0, 10]} /> */}
                    </div>
                  ),
                },
              ]}
              data={senderId}
              loading={fetchingSenderId}
              paginate={Boolean(Object.keys(meta).length)}
              meta={meta}
              // handlePagination={handlePagination}
            />
          </Col>
        </Row>
      </>
      <SenderIdApproveModal
        action={fetchAdminSenderId}
        show={showApproveModal}
        row={approveRow}
        closeModal={() => setShowApproveModal(false)}
      />
    </StyledRequests>
  );
};

export default Requests;
