import { errorParser } from '../../utilities/helper';
import Button from '../Button';
import { Spinner } from '../Spinner';
// import FailedState from '@utils/Modal/Response/FailedState';

const ContentWrapper = ({
  errorTitle,
  currentQuery,
  cls,
  children,
  isResolve,
  CustomLoader,
  showLoading = true,
  showError = true,
}) => {
  const renderByStatus = () => {
    const { isLoading, isError, error, refetch } = currentQuery || {};

    if (isLoading && showLoading) {
      if (CustomLoader) {
        return CustomLoader;
      }
      return (
        <>
          <div className={`w-100 py-5 d-flex align-items-center justify-content-center ${cls}`}>
            <Spinner />
          </div>
        </>
      );
    }

    if (isError && showError)
      return (
        <div className="text-center py-5 d-flex flex-column align-items-center mt-3">
          <h5>{errorTitle}</h5>
          <h4 className="my-4">{errorParser(error)}</h4>
          <Button type="button" value="Retry" size="md" width={200} onClick={refetch} />
        </div>
      );

    return <>{children}</>;
  };
  return <>{renderByStatus()}</>;
};

export default ContentWrapper;
