import { Row, Col } from 'react-bootstrap';
// import { useEffect } from 'react';
import UserReport from '../../components/Dashboard/UserReport';
import TransactionChart from '../../components/Dashboard/TransactionChart';
import TransactionsTable from '../../components/Dashboard/TransactionsTable';
import DailyActivityChart from '../../components/Dashboard/DailyActivityChart';

const Dashboard = () => {
  return (
    <div>
      <Row>
        <Col md={8}>
          <Row>
            <UserReport />
            <TransactionChart />
            <TransactionsTable />
          </Row>
        </Col>
        <Col md={4}>
          <DailyActivityChart />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
