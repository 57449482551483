import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Navigation } from 'react-minimal-side-navigation';
import Icon from '../Icon';
import Logo from '../Logo';
import { SIDE_MENU } from '../../utilities/constants';
import { AppContext } from '../../utilities/context/AppContext';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { AuthContext } from '../../utilities/context/AuthContext';
import CreateAgent from '../../pages/agents/components/CreateAgent';
import CreateClient from '../../pages/clients/components/CreateMerchant';
import CreateProvider from '../../pages/providers/components/CreateProvider';
// import CreatePartner from '../../pages/partners/components/CreatePartner';

const StyledLogo = styled.div`
  padding: 0 1.5rem;
  // height: 80px !important;

  div {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledSideMenu = styled.div`
  width: 300px !important;
  background: #17568b !important;
  color: #ffffff !important;
  height: 100vh !important;

  .side-navigation-panel {
    margin-right: 20px;
    height: calc(100vh - 130px);
    overflow-y: auto;
  }

  li > * {
    color: #ffffff !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }

  .side-navigation-panel-select-option {
    display: flex;
    justify-content: flex-start !important;
    margin-top: 15px !important;
    border-radius: 0 7px 7px 0;
  }

  .side-navigation-panel-select-option-text {
    margin-left: 24px !important;
  }

  .side-navigation-panel-select-inner-option {
    margin-top: 0 !important;
    padding-left: 60px !important;
    padding-right: 10px !important;
    border-left: none !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    transition: all 250ms ease !important;
  }

  .side-navigation-panel-select-option {
    border-color: #17568b;
    border-left: none !important;
    height: 24px !important;
    padding-top: 22px !important;
    padding-bottom: 22px !important;
    transition: all 100ms ease !important;
  }

  .side-navigation-panel-select-option-selected,
  .side-navigation-panel-select-inner-option-selected {
    color: #ffffff !important;
    background: #509fef80 !important;
    font-weight: 600 !important;
  }

  // .side-navigation-panel-select-option-selected,
  // .side-navigation-panel-select-inner-option-selected,
  // .side-navigation-panel-select-option:hover,
  // .side-navigation-panel-select-inner-option:hover {
  //   border-left: none !important;
  //   background: inherit;
  // }
  .side-navigation-panel-select-option: hover {
    background: #509fef80 !important;
  }
  .side-navigation-panel-select-inner-option: hover {
    background: inherit !important;
  }
  .close-icon {
    opacity: 0;
    display: flex;
    justify-content: flex-end;
    > div {
      margin-right: 10px;
      margin-top: 10px;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 700px) {
    position: absolute;
    z-index: 99999;
    transition: left 300ms ease;
    ${({ show }) => (show ? 'left: 0;' : `left: -400px;`)}
    .close-icon {
      opacity: 1;
    }
  }
`;

const SideMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const { signOut } = useContext(AuthContext);
  const { showSideMenu, toggleSideMenu } = useContext(AppContext);
  const [modal, setModal] = useState('');

  return (
    <>
      <StyledSideMenu className="scrollbar" show={showSideMenu}>
        <div className="close-icon">
          <Icon name="close" onClick={() => toggleSideMenu()} width={24} />
        </div>
        <StyledLogo>
          <Logo src="/logo.png" name="Blusalt" />
        </StyledLogo>

        <Navigation
          // you can use your own router's api to get pathname
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            // maybe push to the route
            if (itemId) {
              if (!itemId.startsWith('#')) {
                history.push(itemId);
                toggleSideMenu();
              } else if (itemId === '#createmerchant') {
                setModal('client');
              } else if (itemId === '#createagent') {
                setModal('agent');
              }
              // } else if (itemId === '#createpartner') {
              //   setModal('partner');
              // }
            }
          }}
          items={SIDE_MENU}
        />
        <div
          style={{ display: 'flex', alignItems: 'center', paddingTop: 10, cursor: 'pointer' }}
          onClick={signOut}
        >
          <Icon width={14} margin={[0, 23, 0, 21]} name="power" color="#ffffff" />
          <span>Logout</span>
        </div>
      </StyledSideMenu>

      {modal === 'agent' && <CreateAgent show={modal === 'agent'} onClose={() => setModal('')} />}
      {modal === 'client' && (
        <CreateClient show={modal === 'client'} onClose={() => setModal('')} />
      )}
      {/* {modal === 'partner' && (
        <CreatePartner show={modal === 'partner'} onClose={() => setModal('')} />
      )} */}
      {modal === 'provider' && (
        <CreateProvider show={modal === 'provider'} onClose={() => setModal('')} />
      )}
    </>
  );
};

export default SideMenu;
