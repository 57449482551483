export const ROUTES = {
  LANDING: { path: '/', meta: { title: 'Welcome to BluIntouch' } },
  SIGN_IN: { path: '/auth/signin', meta: { title: 'Sign In' } },
  SIGN_UP: { path: '/auth/signup', meta: { title: 'Sign Up' } },
  FORGOT_PASSWORD: { path: '/auth/forgot-password', meta: { title: 'Forgot Password' } },
  RESET_PASSWORD: { path: '/auth/reset-password/:token', meta: { title: 'Reset Password' } },
  VERIFY_OTP: { path: '/auth/verify', meta: { title: 'Verify OTP' } },
  VERIFY_ACCOUNT: { path: '/auth/confirm-account/:token', meta: { title: 'Verify Account' } },
  DASHBOARD: { path: '/admin/dashboard', meta: { title: 'Dashboard' } },
  REPORT: { path: '#report', meta: { title: 'Report' } },
  CONTACTS: { path: '/admin/contacts', meta: { title: 'Manage Contacts' } },
  CAMPAIGN: { path: '#campaign', meta: { title: 'Campaign' } },
  ALL_CAMPAIGN: { path: '/admin/campaign/all', meta: { title: 'Campaign' } },
  CREATE_CAMPAIGN: { path: '/admin/campaign/create', meta: { title: 'Campaign' } },
  SENDER_ID: { path: '/admin/campaign/sender', meta: { title: 'Sender ID' } },
  SERVICE: { path: '#service', meta: { title: 'Service' } },
  AUDIT_TRAIL: { path: '/admin/report/audit', meta: { title: 'Report' } },
  SERVICE_REPORT_DETAIL: { path: '/admin/report/service/:id', meta: { title: 'Report Details' } },
  SERVICE_REPORT: { path: '/admin/report/service', meta: { title: 'Report' } },
  TRANSACTION_REPORT: { path: '/admin/report/transaction', meta: { title: 'Report' } },
  WALLET: { path: '/admin/wallets', meta: { title: 'Wallets' } },
  SINGLE_WALLET: { path: '/admin/wallet/:id', meta: { title: 'Wallet Information' } },
  SETTINGS: { path: '/admin/settings', meta: { title: 'Settings' } },
  CREATE_ROLE: { path: '/admin/settings/role', meta: { title: 'Settings' } },
  SMS_SERVICE: { path: '/admin/service/sms', meta: { title: 'Service' } },
  DATA_SERVICE: { path: '/admin/service/data', meta: { title: 'Service' } },
  AIRTIME_SERVICE: { path: '/admin/service/airtime', meta: { title: 'Service' } },
  WHATSAPP_SERVICE: { path: '/admin/service/whatsapp', meta: { title: 'Service' } },
  PAY_BILLS: { path: '/admin/service/bills', meta: { title: 'Service' } },
  GIFT_CARDS: { path: '/admin/service/gift', meta: { title: 'Service' } },
  NOT_FOUND: '/404',
  REQUESTS: { path: '/admin/requests', meta: { title: 'Requests' } },
  AGENTS: { path: '/admin/agents', meta: { title: 'Agents' } },
  CREATE_AGENT: { path: '/admin/agents/create', meta: { title: 'Create Agents' } },
  MERCHANTS: { path: '/admin/merchants', meta: { title: 'Merchants' } },
  PARTNERS: { path: '/admin/partners', meta: { title: 'Partners' } },
  PROVIDERS: { path: '/admin/providers', meta: { title: 'Providers' } },
  PROVIDER: { path: '/admin/providers/:id', meta: { title: 'Provider Information' } },
  UPGRADE_PROVIDER: { path: '/admin/providers/:id/upgrade', meta: { title: 'Become a Partner' } },
  PARTNER: { path: '/admin/partners/:id', meta: { title: 'Partners Information' } },
  PRICES: { path: '/admin/prices', meta: { title: 'Prices' } },
  COMMISSIONS: { path: '/admin/commissions', meta: { title: 'Commissions' } },
  REPORTS: { path: '/admin/reports', meta: { title: 'Reports' } },
};
