import { Route, Redirect, withRouter, Router, Switch } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { ROUTES } from '../routes/routes';
import { fetchUserData } from '../../../utilities/storage';
import Dashboard from '../../../pages/dashboard/Dashboard';
import Prices from '../../../pages/prices/Prices';
import Wallet from '../../../pages/wallet/Wallets';
import WalletDetail from '../../../pages/wallet/WalletDetail';
import SingleWallet from '../../../pages/wallet/components/SingleWallet';
import Agents from '../../../pages/agents/Agents';
import Clients from '../../../pages/clients/Merchant';
import Commission from '../../../pages/commission/Commission';
import Partners from '../../../pages/partners/Partners';
import Requests from '../../../pages/requests/Requestss';
import Providers from '../../../pages/providers/Providers';
import AuditsReport from '../../../pages/reports/Audits';
import ServicesReport from '../../../pages/reports/Services';
import TransactionsReport from '../../../pages/reports/Transactions';
import Settings from '../../../pages/settings/Settings';
import ServiceReportDetail from '../../../pages/reports/components/ServiceReportDetail';
import Provider from '../../../pages/providers/components/Provider';
import Partner from '../../../pages/partners/components/PartnerProfileContent/Partner';
import UpgradeProvider from '../../../pages/providers/components/UpgradeProvider/UpgradeProvider';
// import ServiceReportDetail from '../../../pages/reports/ServiceReportDetail';

export const protectedRoutes = [
  {
    component: Dashboard,
    routes: ROUTES.DASHBOARD,
  },
  {
    component: Prices,
    routes: ROUTES.PRICES,
  },
  {
    component: Wallet,
    routes: ROUTES.WALLET,
  },
  {
    component: WalletDetail,
    routes: ROUTES.SINGLE_WALLET,
  },
  {
    component: SingleWallet,
    routes: ROUTES.DATA_SERVICE,
  },
  {
    component: Agents,
    routes: ROUTES.AGENTS,
  },
  {
    component: Clients,
    routes: ROUTES.MERCHANTS,
  },
  {
    component: Commission,
    routes: ROUTES.COMMISSIONS,
  },
  {
    component: Partners,
    routes: ROUTES.PARTNERS,
  },
  {
    component: Partner,
    routes: ROUTES.PARTNER,
  },
  {
    component: Requests,
    routes: ROUTES.REQUESTS,
  },
  {
    component: Providers,
    routes: ROUTES.PROVIDERS,
  },
  {
    component: UpgradeProvider,
    routes: ROUTES.UPGRADE_PROVIDER,
  },
  {
    component: Provider,
    routes: ROUTES.PROVIDER,
  },
  {
    component: AuditsReport,
    routes: ROUTES.AUDIT_TRAIL,
  },
  {
    component: ServicesReport,
    routes: ROUTES.SERVICE_REPORT,
  },
  {
    component: ServiceReportDetail,
    routes: ROUTES.SERVICE_REPORT_DETAIL,
  },
  {
    component: TransactionsReport,
    routes: ROUTES.TRANSACTION_REPORT,
  },
  { component: Settings, routes: ROUTES.SETTINGS },
];

const PrivateRoutes = ({ component: Component, ...rest }) => {
  const { token } = fetchUserData();
  return (
    <Route
      {...rest}
      render={(props) => (token ? <Component {...props} /> : <Redirect to="/auth/signin" />)}
    />
  );
};

export default withRouter(PrivateRoutes);

export const ProtectedRoutesGroup = () => {
  const { token } = fetchUserData();
  if (token && window.location.pathname === '/') {
    window.location.pathname = '/admin/dashboard';
  }
  return (
    <Switch>
      {protectedRoutes.map((route, index) => (
        <PrivateRoute
          key={index}
          exact
          component={route.component}
          showHeader
          showSideMenu
          {...route.routes}
        />
      ))}
      <Router exact path="*" component={() => <Redirect to="/" />} />
    </Switch>
  );
};
