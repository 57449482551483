import Icon from './Icon';
import Modal from './Modal';

const NotifyMain = ({ show, type, onClose, title, children, ...rest }) => {
  const handleOnClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal show={show} onClose={handleOnClose} showHeader={false} size="md" {...rest}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Icon
          name="close"
          width={24}
          style={{ cursor: 'pointer', marginLeft: 'auto' }}
          onClick={handleOnClose}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0 20px',
        }}
      >
        <Icon name={type} width={60} margin={[10, 10, 20, 10]} />
        {title && (
          <div
            style={{
              marginBottom: 20,
              fontSize: 16,
              fontWeight: 600,
              width: '100%',
              textAlign: 'center',
            }}
          >
            {title}
          </div>
        )}
        {children && (
          <div style={{ marginBottom: 20, width: '100%', textAlign: 'center' }}>{children}</div>
        )}
      </div>
    </Modal>
  );
};

const Notify = ({ type = 'success', ...rest }) => {
  switch (type) {
    case 'error':
      return <Notify.Error {...rest} />;
    case 'success':
      return <Notify.Success {...rest} />;
    case 'warn':
      return <Notify.Warn {...rest} />;
    case 'info':
      return <Notify.Info {...rest} />;
    default:
      return null;
  }
};

Notify.Error = (props) => <NotifyMain {...props} type="error" />;

Notify.Success = (props) => <NotifyMain {...props} type="success" />;

Notify.Info = (props) => <NotifyMain {...props} type="info" />;

Notify.Warn = (props) => <NotifyMain {...props} type="warn" />;

export default Notify;
