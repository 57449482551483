import axios from 'axios';
import moment from 'moment';
import Toast from 'react-hot-toast';
import { isEmail, isMobilePhone, isStrongPassword } from 'validator';
import { ROUTES } from '../components/router/routes/routes';
import { STORAGE } from './constants';
import { localGet, localRemove } from './forage';

export const errorParser = (error) => {
  return error?.response?.data?.data?.error;
};

export const throttle = (fn, wait) => {
  let time = Date.now();

  return function () {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
};

export const injectLink = (link, href = '') => {
  // eslint-disable-next-line no-undef
  const typoElt = document.getElementById('typography.js');

  if (typoElt) {
    typoElt.insertAdjacentHTML('afterend', link);
  }
};

export const getFontsStr = (typography) => {
  let fontsStr = '';
  if (typography.options.googleFonts) {
    const fonts = typography.options.googleFonts.map((font) => {
      let str = '';
      str += font.name.split(' ').join('+');
      str += ':';
      str += font.styles.join(',');

      return str;
    });

    fontsStr = fonts.join('|');
  }

  return fontsStr;
};

export const getFontsLink = (fontsStr) => {
  const href = `//fonts.googleapis.com/css?family=${fontsStr}`;
  const link = `<link href="${href}" rel="stylesheet" type="text/css" />`;
  return { link, href };
};

export const injectFonts = (typography) => {
  const fontsStr = getFontsStr(typography);
  if (fontsStr) {
    const { link, href } = getFontsLink(fontsStr);
    injectLink(link, href);
  }
};

export const isEmpty = (obj) => {
  return (
    obj === undefined ||
    obj === null ||
    (typeof obj === 'object' && Object.keys(obj).length === 0) ||
    (Array.isArray(obj) && obj.length === 0) ||
    (typeof obj === 'string' && obj.trim().length === 0)
  );
};

export const isEmptyObject = (obj) => {
  return !(typeof obj === 'object' && Object.keys(obj).length > 0);
};

export const onlyDefined = (obj) => {
  const newObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== '' && value !== 'undefined') {
      newObj[key] = value;
    }
  });

  return newObj;
};

/**
 * encodeB64
 *
 * encode string to base 64 using **`btoa`** library
 * @param {string} str
 * @returns {string}
 */
export const encodeB64 = (str) => {
  return btoa(str);
};

const responseHandler = ({ data = {} }, show) => {
  const { response, message, status_code: status, error, ...others } = data;
  if (show) {
    if (error) {
      Toast.error(typeof response === 'string' ? response : message || 'Could be your network!');
    } else {
      Toast.success(typeof show === 'string' ? show : message || 'Success');
    }
  }
  return { response, message, status, error, ...others };
};

const errorHandler = ({ response: { data } = { data: {} } }, show) => {
  const { response, data: d, error, status_code: status } = data;
  let { message = 'Network Error' } = data;

  if (show) {
    if (d) {
      message = Array.isArray(d) ? d.join('\n') : d;
    }
    Toast.error(typeof response === 'string' ? response : message || 'Could be your network!');
  }

  return { response, message, status, error };
};

const preRequestCheck = () => {
  return Promise.all([localGet(STORAGE.TOKEN_EXPIRE), localGet(STORAGE.TOKEN)]).then(
    ([token_exp, token]) => {
      if (token_exp < Date.now()) {
        localRemove(Object.keys(STORAGE));
        window.location.href = `/#${ROUTES.SIGN_IN.path}`;
        Toast.error('Session expired. Please login =-=-=');
        return {};
      }
      return { headers: { authorization: `Bearer ${token}` } };
    }
  );
};

export const get = async (url, { authorize = false, showMessage = false } = {}, options = {}) => {
  const config = { ...options, ...(authorize === true ? await preRequestCheck() : {}) };

  return axios
    .get(url, { role: 'admin', ...config })
    .then((x) => responseHandler(x, showMessage))
    .catch((x) => errorHandler(x, showMessage));
};

export const post = async (
  url,
  body,
  { authorize = false, showMessage = false } = {},
  options = {}
) => {
  const config = { ...options, ...(authorize === true ? await preRequestCheck() : {}) };

  return axios
    .post(url, body, { role: 'admin', ...config })
    .then((x) => responseHandler(x, showMessage))
    .catch((x) => errorHandler(x, showMessage));
};

export const patch = async (
  url,
  body,
  { authorize = false, showMessage = false } = {},
  options = {}
) => {
  const config = { ...options, ...(authorize === true ? await preRequestCheck() : {}) };

  return axios
    .patch(url, body, { role: 'admin', ...config })
    .then((x) => responseHandler(x, showMessage))
    .catch((x) => errorHandler(x, showMessage));
};

export const upload = async (
  url,
  body,
  { authorize = false, showMessage = false } = {},
  options = {}
) => {
  const config = { ...options, ...(authorize === true ? await preRequestCheck() : {}) };

  return axios
    .post(url, body, { role: 'admin', ...config })
    .then((x) => responseHandler(x, showMessage))
    .catch((x) => errorHandler(x, showMessage));
};

export const formatDate = (date, format = 'ddd MMM D YYYY h:ma') => {
  if (date) {
    return moment(date).format(format);
  }

  return date;
};

export const validator = (value, validate = '') => {
  const opts = validate.split('|');

  const errors = opts
    .map((opt) => {
      if (opt === 'required') {
        return (value || '').trim() ? null : 'Field required';
      }

      if (opt === 'email') {
        return isEmail(value) ? null : 'Enter correct email address';
      }

      if (opt === 'phone_number') {
        return isMobilePhone(value) ? null : 'Phone number invalid';
      }

      if (opt === 'password') {
        return isStrongPassword(value)
          ? null
          : 'Password must contain 1 lowercase, 1 uppercase, 1 number, 1 special character and minimum of 8 characters';
      }
      return null;
    })
    .filter((e) => !!e);

  return errors;
};

export const maskPhoneNumber = (value, stars = 9) => {
  return value.replace(
    value.substr(0, value.length - stars),
    value.substr(0, value.length - stars).replace(/./g, '*')
  );
};

export const getDate = (date) => {
  let computedDate = null;
  switch (date) {
    case 'Today':
      computedDate = moment().startOf('day');
      break;
    case 'This Week':
      computedDate = moment().startOf('week');
      break;
    case 'This Month':
      computedDate = moment().startOf('month');
      break;
    case 'Last 30 Days':
      computedDate = moment().subtract(30, 'day');
      break;
    case 'This Year':
      computedDate = moment().startOf('year');
      break;
    default:
      break;
  }
  return computedDate;
};

export function toTitleCase(str) {
  return str.replace(/\b\w+/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const toCurrency = (n) => {
  if (n === null || n === undefined || n === 'NaN') return 0;
  return Number(n)
    .toFixed(2)
    .replace(/./g, function (c, i, a) {
      return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c; // eslint-disable-line prefer-template
    });
};

export const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
