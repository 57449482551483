import { Modal as BModal } from 'react-bootstrap';
import Back from './Back';
import Icon from './Icon';

const Modal = ({
  show,
  onClose,
  title,
  goBack,
  children,
  footer,
  showHeader = true,
  centered = true,
  size,
}) => {
  return (
    <>
      <BModal show={show} centered={centered} backdrop onHide={onClose} size={size}>
        {showHeader && (
          <BModal.Header>
            {title || goBack ? (
              <BModal.Title style={{ fontWeight: 300 }}>
                {title || <Back onClick={goBack} />}
              </BModal.Title>
            ) : (
              ''
            )}

            <Icon
              name="close"
              width={24}
              style={{ cursor: 'pointer', marginLeft: 'auto' }}
              onClick={() => onClose()}
            />
          </BModal.Header>
        )}
        <BModal.Body>{children}</BModal.Body>
        {footer && <BModal.Footer />}
      </BModal>
    </>
  );
};

export default Modal;
