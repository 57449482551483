import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import Table from '../../../components/Table';
import Button from '../../../components/Button';
import { fetchServiceReportDetailAction } from '../../../redux/actions/serviceReportDetail';

const StyledServiceReportDetail = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }

  .MuiCheckbox-root {
    margin-right: 10px;
    .MuiSvgIcon-root {
      color: #bbbbbb !important;
    }
  }
`;

const ServiceReportDetail = () => {
  const { id } = useParams();
  const { serviceReport, meta, fetching } = useSelector((state) => {
    return {
      serviceReport: state.serviceReportDetail.serviceReportDetail,
      meta: {
        ...state.serviceReportDetail.serviceReportDetailMeta,
        limit: 25,
        serviceReportId: id,
      },
      fetching: state.serviceReportDetail.fetchingServiceReportDetail,
    };
  });
  const dispatch = useDispatch();

  const FetchService = useCallback(
    (params = { limit: 25 }) => {
      dispatch(fetchServiceReportDetailAction({ ...params, serviceReportId: id }));
    },
    [dispatch]
  );

  useEffect(() => {
    FetchService();
  }, []);

  return (
    <StyledServiceReportDetail>
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 30,
          }}
        >
          <h5>Service Trail</h5>

          <div style={{ display: 'flex' }}>
            {/* <div style={{ marginRight: 10 }}>
              <Select
                size="tiny"
                options={[{ label: 'Merchants', value: 'merchants' }]}
                defaultValue="merchants"
                style={{ minWidth: 90 }}
                padding={0}
              />
            </div>
            <div style={{ marginRight: 10 }}>
              <Select
                size="tiny"
                options={[{ label: 'Monthly', value: 'monthly' }]}
                defaultValue="monthly"
                style={{ minWidth: 80 }}
                padding={0}
              />
            </div> */}
            <Button value="Download CSV" size="sm" />
          </div>
        </div>

        <Row>
          <Col>
            <Table
              header={[
                {
                  key: 'createdAt',
                  label: 'Date',
                  formatter: (createdAt) => (
                    <div className="td-width-100">
                      <div>
                        <Moment format="D MMM, YYYY" date={createdAt} />
                      </div>
                      <div>
                        <Moment format="h:mm:SSa" date={createdAt} />
                      </div>
                    </div>
                  ),
                },
                { key: 'phoneNumber', label: 'Phone Number' },
                {
                  key: 'status',
                  label: 'Status',
                  formatter: (status) => (
                    <div>
                      <span className={status === 'Failed' ? 'text-danger' : 'text-success'}>
                        {status}
                      </span>
                    </div>
                  ),
                },
              ]}
              data={serviceReport}
              filter={{
                status: [
                  { value: 'Successful', label: 'Successful' },
                  { value: 'Failed', label: 'Failed' },
                ],
              }}
              showFilter
              meta={meta}
              paginate
              handlePagination={FetchService}
              filterAction={FetchService}
              loading={fetching}
            />
          </Col>
        </Row>
      </>
    </StyledServiceReportDetail>
  );
};

export default ServiceReportDetail;
