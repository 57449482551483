/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  wallet: [],
  fetchingWallet: false,
  walletMeta: {},
};

export const reportSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    fetchAdminWalletSuccessReducer: (state, { payload }) => {
      state.wallet = payload.data;
      state.fetchingWallet = false;
      state.walletMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingAdminWalletReducer: (state) => {
      state.fetchingWallet = true;
      state.wallet = [];
      state.walletMeta = {};
    },
    fetchingAdminWalletErrorReducer: (state) => {
      state.fetchingWallet = false;
      state.wallet = [];
      state.walletMeta = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchAdminWalletSuccessReducer,
  fetchingAdminWalletReducer,
  fetchingAdminWalletErrorReducer,
} = reportSlice.actions;

export default reportSlice.reducer;
