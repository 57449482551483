/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  senderId: [],
  fetchingSenderId: false,
  senderIdMeta: {},
};

export const senderIdSlice = createSlice({
  name: 'senderId',
  initialState,
  reducers: {
    fetchSenderIdSuccessReducer: (state, { payload }) => {
      state.senderId = payload.data;
      state.fetchingSenderId = false;
      state.senderIdMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingSenderIdReducer: (state) => {
      state.fetchingSenderId = true;
      state.senderId = [];
      state.senderIdMeta = {};
    },
    fetchingSenderIdErrorReducer: (state) => {
      state.fetchingSenderId = false;
      state.senderId = [];
      state.senderIdMeta = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchSenderIdSuccessReducer,
  fetchingSenderIdReducer,
  fetchingSenderIdErrorReducer,
} = senderIdSlice.actions;

export default senderIdSlice.reducer;
