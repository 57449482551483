import { Flex } from 'rebass';
import LoadingIcon from '../../assets/loaders/loading';

const AppWrapper = () => {
  return (
    <Flex alignItems="center" justifyContent="center" height="100vh">
      <LoadingIcon />
    </Flex>
  );
};

export default AppWrapper;
