import { useCallback, useEffect, useState } from 'react';
import Toast from 'react-hot-toast';
import { Row, Col } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { Country as Countries, State } from 'country-state-city';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { Input, PhoneInput, Select } from '../../../components/inputs';
import { agentCreateState } from '../../../utilities/constants';
import { APIfetchBankList, APIverifyBankDetails } from '../../../api/backendRoutes';
import { blockInvalidChar, toTitleCase } from '../../../utilities/helper';

const BankInfo = ({ moveToNext }) => {
  const [agentInfo, setAgentInfo] = useRecoilState(agentCreateState);
  const [formData, setFormData] = useState({ ...agentInfo, fetching: false });

  const [bankList, setBankList] = useState([]);
  const [verifyAcct, setVerifyAcct] = useState({
    verified: false,
    verifying: false,
    error: false,
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      ...agentInfo,
      fetching: false,
    }));
  }, []);

  const getallBankList = useCallback(async () => {
    try {
      const resp = await APIfetchBankList();
      if (resp.data.message === 'ok') {
        const { result } = resp.data.data;
        const fetchedbankList = result.map((bank) => {
          return {
            label: bank.name,
            value: bank.code,
          };
        });
        setBankList(fetchedbankList);
      } else {
        throw resp?.data?.error;
      }
    } catch (error) {
      setBankList({});
    }
  }, []);

  useEffect(() => {
    getallBankList();
  }, []);

  const verifyBank = async () => {
    const bankDetails = {
      phoneNumber: formData.phone_number,
      accountNumber: formData.bankAccountNumber,
      bankName: bankList?.find((bank) => bank?.value === formData.bankName)?.label,
    };
    setVerifyAcct({
      ...verifyAcct,
      verifying: true,
    });
    try {
      const resp = await APIverifyBankDetails(bankDetails);
      if (resp.data.message === 'ok') {
        const { results } = resp.data.data.data;
        setVerifyAcct({
          ...verifyAcct,
          verified: true,
          verifying: false,
        });
        Toast.success('Bank successfully verified');
        moveToNext({
          ...agentInfo,
          ...formData,
          bankName: bankList?.find((bank) => bank?.value === formData.bankName)?.label,
          bankAccountName: toTitleCase(results?.account_name),
          bankCode: formData?.bankName,
        });
      } else {
        setVerifyAcct({
          ...verifyAcct,
          verified: false,
          error: true,
        });
        throw resp?.data?.error;
      }
    } catch (error) {
      setVerifyAcct({
        ...verifyAcct,
        verified: false,
        error: true,
      });
      Toast.error("Error Can't verify Bank Details");
    }
  };

  const handleInputChange = ({ name, value }) => {
    if (name === 'nin' || name === 'bankAccountNumber') {
      return setFormData((v) => ({ ...v, [name]: value.replace(/[^0-9]/g, '') }));
    }
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <form
      style={{ width: '100%', margin: 'auto' }}
      onSubmit={(e) => {
        e.preventDefault();
        setAgentInfo((p) => ({ ...p, ...formData }));
        // moveToNext();
        verifyBank();
      }}
    >
      <Row>
        <Col style={{ fontSize: 16, fontWeight: 200, marginBottom: 15, opacity: 0.6 }}>
          Bank Information
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            label="Bank Name"
            placeholder="Enter Bank Name"
            name="bankName"
            type="text"
            validate="required"
            defaultValue={formData.bankName}
            onChange={handleInputChange}
            options={bankList}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Bank Account Number"
            placeholder="Enter Bank Account Number"
            name="bankAccountNumber"
            type="number"
            validate="required"
            defaultValue={formData.bankAccountNumber}
            onChange={handleInputChange}
            onKeyDown={blockInvalidChar}

          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="NIN"
            placeholder="Enter NIN"
            name="nin"
            validate="required"
            defaultValue={formData.nin}
            onChange={handleInputChange}
            inputProps={{ autoComplete: 'none', minLength: 11, maxLength: 11 }}
          />
        </Col>
      </Row>

      <Row>
        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button
            loading={verifyAcct.verifying}
            type="submit"
            value="Add Agent"
            size="md"
            width={100}
          />
        </Col>
      </Row>
    </form>
  );
};

const RepresentativeInfo = ({ moveToNext }) => {
  const [agentInfo, setAgentInfo] = useRecoilState(agentCreateState);
  const [formData, setFormData] = useState({ ...agentInfo, fetching: false });

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <form
      style={{ width: '100%', margin: 'auto' }}
      onSubmit={(e) => {
        e.preventDefault();
        setAgentInfo((p) => ({ ...p, ...formData }));
        moveToNext();
      }}
    >
      <Row>
        <Col style={{ fontSize: 16, fontWeight: 200, marginBottom: 15, opacity: 0.6 }}>
          Representative Information
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="First Name"
            placeholder="Enter First Name"
            name="r_first_name"
            type="text"
            validate="required"
            defaultValue={formData.r_first_name}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Last Name"
            placeholder="Enter Last Name"
            name="r_last_name"
            type="text"
            validate="required"
            defaultValue={formData.r_last_name}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Email"
            placeholder="Enter Email"
            name="r_email"
            type="email"
            validate="required"
            defaultValue={formData.r_email}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PhoneInput
            label="Phone Number"
            placeholder="Enter Phone Number"
            name="r_phone_number"
            validate="required"
            defaultValue={formData.r_phone_number}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button type="submit" value="Submit" size="md" width={100} />
        </Col>
      </Row>
    </form>
  );
};

const CompanyInfo = ({ moveToNext }) => {
  const [agentInfo, setAgentInfo] = useRecoilState(agentCreateState);
  const [formData, setFormData] = useState({ ...agentInfo, fetching: false });
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);

  const getCountry = () => {
    try {
      const countries = Countries.getAllCountries();

      const parsedCountries = countries.map((data) => ({
        label: data?.name,
        value: data?.name,
        ...data,
      }));
      setCountry(parsedCountries);
    } catch (error) {
      setCountry([]);
    }
  };

  const getState = (code) => {
    try {
      const parsedStates = (countryCode) =>
        State.getStatesOfCountry(countryCode).map((data) => ({
          label: data?.name,
          value: data?.name,
          ...data,
        }));
      setState(parsedStates(code));
    } catch (error) {
      setState([]);
    }
  };

  useEffect(() => {
    getCountry();

    if (formData?.country) {
      const iso = Countries.getAllCountries().find((c) => c.name === formData.country).isoCode;
      getState(iso);
    }
  }, []);

  const handleInputChange = ({ name, value }) => {
    if (name === 'country') {
      const selectedCountry = country.filter((cont) => cont.value === value);
      const { isoCode } = selectedCountry[0];
      getState(isoCode);
    }
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <form
      style={{ width: '100%', margin: 'auto' }}
      onSubmit={(e) => {
        e.preventDefault();
        setAgentInfo((p) => ({ ...p, ...formData }));
        moveToNext();
      }}
    >
      <Row>
        <Col style={{ fontSize: 16, fontWeight: 200, marginBottom: 15, opacity: 0.6 }}>
          Company Information
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Email"
            placeholder="Enter Email"
            name="mail"
            type="email"
            validate="required"
            defaultValue={formData.mail}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Full Name"
            placeholder="Enter Full Name"
            name="company"
            type="text"
            validate="required"
            defaultValue={formData.company}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PhoneInput
            label="Phone Number"
            placeholder="Enter Phone Number"
            name="phone_number"
            validate="required"
            defaultValue={formData.phone_number}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Enter Address"
            placeholder="Enter Address"
            name="address"
            type="text"
            validate="required"
            defaultValue={formData.address}
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Select
            label="Select Country *"
            placeholder="Select Country"
            name="country"
            validate="required"
            defaultValue={formData.country}
            onChange={handleInputChange}
            options={country}
          />
        </Col>
        <Col md={6}>
          <Select
            label="Select State *"
            placeholder="Select State"
            name="state"
            validate="required"
            defaultValue={formData?.state}
            onChange={handleInputChange}
            options={state}
          />
        </Col>
      </Row>

      <Row>
        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button type="submit" value="Continue" size="md" width={100} />
        </Col>
      </Row>
    </form>
  );
};

const CreateAgent = ({ show, onClose }) => {
  const [index, setIndex] = useState(0);

  const goBack = () => {
    setIndex((i) => (i - 1 > -1 ? i - 1 : 0));
  };

  const moveToNext = (data) => {
    setIndex((i) => i + 1);
  };

  const createAgent = async (data) => {};

  return (
    <>
      <Modal
        show={show}
        onClose={onClose}
        goBack={goBack}
        title={index === 0 ? 'Create Agent' : ''}
      >
        {index === 0 && <CompanyInfo moveToNext={moveToNext} />}
        {index === 1 && <RepresentativeInfo moveToNext={moveToNext} />}
        {index === 2 && <BankInfo moveToNext={createAgent} />}
      </Modal>
    </>
  );
};

export default CreateAgent;
