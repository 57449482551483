import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { APIaddTeamMate } from '../../../api/backendRoutes';
import Button from '../../../components/Button';
import { Input, Select } from '../../../components/inputs';
import Modal from '../../../components/Modal';
import { errorParser } from '../../../utilities/helper';

function CreateUser({ show, onClose, getTeam, roles }) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    memberRole: '',
    fetching: false,
  });

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setFormData((v) => ({ ...v, fetching: true }));
      const newPayload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        memberRole: formData.memberRole,
      };
      const resp = await APIaddTeamMate(newPayload);
      if (resp.data.success) {
        setFormData((v) => ({ ...v, fetching: false }));
        getTeam();
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          memberRole: '',
          fetching: false,
        });
        onClose();
      } else {
        setFormData((v) => ({ ...v, fetching: false }));
        throw resp?.data.error;
      }
    } catch (err) {
      const errMessage = errorParser(err);
      setFormData((v) => ({ ...v, fetching: false }));
      toast.error(errMessage);
    }
  };

  return (
    <Modal show={show} onClose={onClose} title="Add TeamMates">
      {/* {showAddTeam()} */}
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Input
              placeholder="First Name"
              label="First Name"
              type="text"
              onChange={handleInputChange}
              defaultValue={formData.firstName}
              name="firstName"
            />
          </Col>
          <Col>
            <Input
              placeholder="Last Name"
              label="Last Name"
              type="text"
              onChange={handleInputChange}
              defaultValue={formData.lastName}
              name="lastName"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              placeholder="Email"
              label="Email"
              type="email"
              onChange={handleInputChange}
              defaultValue={formData.email}
              name="email"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              placeholder="Select Role"
              label="Role"
              onChange={handleInputChange}
              defaultValue={formData.role}
              options={roles?.map((r) => ({ value: r.id, label: r.roleName }))}
              name="memberRole"
            />
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 0px',
            borderTop: '1px solid #F2F2F2',
            margin: '10px 0px',
          }}
        >
          <Button value="Cancel" group="outline" onClick={onClose} />
          <Button value="Add Team Mate" type="submit" group="primary" loading={formData.fetching} />
        </div>
      </form>
      {/* <StyledPermission display={showPermissionModal}>{showPermissions()}</StyledPermission> */}
    </Modal>
  );
}

export default CreateUser;
