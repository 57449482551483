import { useState } from 'react';
import Button from '../../../../../components/Button';
import CreateServiceModal from '../../Modals/CreateServiceModal';

const CreateServiceBtn = ({ provider }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        group="secondary round"
        width={150}
        value="Create Service"
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <CreateServiceModal provider={provider} show={isOpen} closeModal={() => setIsOpen(false)} />
      )}
    </>
  );
};

export default CreateServiceBtn;
