import { useState } from 'react';
import Toast from 'react-hot-toast';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { Country as Countries } from 'country-state-city';
import { useMutation, useQueryClient } from 'react-query';

// Components
import Icon from '../../../../../components/Icon';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';
import { Input, Select } from '../../../../../components/inputs';

// Utils
import { errorParser } from '../../../../../utilities/helper';
import { APIUpdatePriceModel } from '../../../../../api/backendRoutes';
import ProviderServiceSelect from '../../../../../components/Select/ProviderServiceSelect/ProviderServiceSelect';

const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const ShowSuccessModal = ({ onClose }) => {
  return (
    <StyledSuccess>
      <Icon name="success" height="80px" />
      <div>Pricing Model Updated Successfully</div>
    </StyledSuccess>
  );
};

const UpdatePriceModelModal = ({ model, show, onClose, provider, reload = () => {} }) => {
  const queryClient = useQueryClient();

  const countries = Countries.getAllCountries().map((data) => ({
    label: data?.name,
    value: data?.name,
    ...data,
  }));

  const [formData, setFormData] = useState({ ...model });
  const [showSuccess, setSuccess] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find((cont) => cont.value === model.country)
  );

  const handleCloseSuccessmodal = () => {
    setSuccess(false);
    onClose();
  };

  const handleInputChange = ({ name, value }) => {
    if (name === 'country') {
      setSelectedCountry(countries.find((cont) => cont.value === value));
    }
    setFormData((v) => ({ ...v, [name]: value }));
  };

  // eslint-disable-next-line
  const { mutate, isLoading } = useMutation(
    async (payload) => {
      setSuccess(false);
      const response = await APIUpdatePriceModel(model?._id, {
        ...payload,
      });

      return response;
    },
    {
      onSuccess: ({ data }) => {
        if (data.success) {
          queryClient.invalidateQueries(['pricing_model']);
          setSuccess(true);
          setFormData({});
          reload();
          setSelectedCountry({});
          Toast.success(data?.message);
        } else Toast.error("Error Can't Create Provider");
      },
      onError: (error) => Toast.error(errorParser(error)),
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      country: selectedCountry.name,
      currency: selectedCountry.currency,
      countryCode: selectedCountry.phonecode,
    };

    delete payload?._id;
    delete payload?.__v;
    delete payload?.deleted;
    delete payload?.updatedAt;
    delete payload?.createdAt;
    delete payload?.deletedAt;
    delete payload?.companyId;
    delete payload?.serviceId;
    delete payload?.serviceType;
    delete payload?.priceModelName;

    if (payload.type === 'Flat') {
      delete payload?.value;
    } else if (payload.type === 'Discount') {
      delete payload?.price;
      delete payload?.cost;
    }

    mutate(payload);
  };

  return (
    <>
      {!showSuccess && (
        <Modal show={show} onClose={onClose} title="Update Pricing Model">
          <form style={{ width: '100%', margin: 'auto' }} onSubmit={handleSubmit}>
            <Row>
              <Col style={{ fontSize: 16, fontWeight: 200, marginBottom: 15, opacity: 0.6 }}>
                Pricing Information
              </Col>
            </Row>
            {provider && (
              <Row>
                <Col>
                  <ProviderServiceSelect
                    provider={provider}
                    label="Services"
                    placeholder="Enter Service"
                    name="serviceId"
                    type="text"
                    validate="required"
                    defaultValue={formData.serviceId}
                    disabled
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Select
                  label="Country"
                  placeholder="Select Country"
                  name="country"
                  type="text"
                  validate="required"
                  disabled
                  defaultValue={formData.country}
                  options={countries}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Input
                  label="Pricing Model Name"
                  placeholder="Enter Pricing Model Name"
                  name="priceModelName"
                  validate="required"
                  defaultValue={formData.priceModelName}
                  onChange={handleInputChange}
                />
              </Col>
            </Row> */}
            <Row>
              <Col>
                <Select
                  label="Select Pricing Type *"
                  placeholder="Select Pricing Type"
                  name="type"
                  validate="required"
                  defaultValue={formData.type}
                  onChange={handleInputChange}
                  options={[
                    { label: 'Discount', value: 'Discount' },
                    { label: 'Flat', value: 'Flat' },
                  ]}
                />
              </Col>
            </Row>

            {formData.type === 'Discount' && (
              <Row>
                <Col>
                  <Input
                    label="Value"
                    placeholder="Enter Value"
                    name="value"
                    type="number"
                    validate="required"
                    defaultValue={formData.value}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
            )}

            {formData.type === 'Flat' && (
              <>
                <Row>
                  <Col>
                    <Input
                      label="Price"
                      placeholder="Enter Price"
                      name="price"
                      type="number"
                      validate="required"
                      defaultValue={formData.price}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <Input
                      label="Cost"
                      placeholder="Enter Cost"
                      name="cost"
                      type="number"
                      validate="required"
                      defaultValue={formData.cost}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row> */}
              </>
            )}

            <Row>
              <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <Button loading={isLoading} type="submit" value="Submit" size="md" width={100} />
              </Col>
            </Row>
          </form>
        </Modal>
      )}
      {showSuccess && (
        <Modal show={showSuccess} onClose={handleCloseSuccessmodal}>
          <ShowSuccessModal onClose={handleCloseSuccessmodal} />
        </Modal>
      )}
    </>
  );
};

export default UpdatePriceModelModal;
