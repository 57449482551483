/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  walletDetails: [],
  fetchingWalletDetails: false,
  walletDetailsMeta: {},
};

export const reportSlice = createSlice({
  name: 'walletDetails',
  initialState,
  reducers: {
    fetchAdminWalletDetailsSuccessReducer: (state, { payload }) => {
      state.walletDetails = payload.data;
      state.fetchingWalletDetails = false;
      state.walletDetailsMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingAdminWalletDetailsReducer: (state) => {
      state.fetchingWalletDetails = true;
      state.walletDetails = [];
      state.walletDetailsMeta = {};
    },
    fetchingAdminWalletDetailsErrorReducer: (state) => {
      state.fetchingWalletDetails = false;
      state.walletDetails = [];
      state.walletDetailsMeta = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchAdminWalletDetailsSuccessReducer,
  fetchingAdminWalletDetailsReducer,
  fetchingAdminWalletDetailsErrorReducer,
} = reportSlice.actions;

export default reportSlice.reducer;
