import { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Toast, { toast } from 'react-hot-toast';
import { STORAGE } from '../constants';
import { localGet, localRemove } from '../forage';
// import { useUser } from '../hooks/useUserTypes';
import { ROUTES } from '../../components/router/routes/routes';
import { storeUserData } from '../storage';
import { APIlogin } from '../../api/backendRoutes';
import { errorParser } from '../helper';

const AuthContext = createContext({});

const initialState = { user: null };

const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(initialState);
  const history = useHistory();
  // const { setUser } = useUser();

  const signOut = () => {
    localRemove(Object.values(STORAGE));
    setAuthData({ ...initialState });

    history.push(ROUTES.SIGN_IN.path);
    localStorage.removeItem('token');
    localStorage.clear();
    // setUser({});
  };

  const checkExpiration = () => {
    localGet(STORAGE.TOKEN_EXPIRE).then((expiration) => {
      if (expiration && expiration < Date.now()) {
        Toast.error('Session expired. Please login');
        signOut();
      }
    });
  };

  const signIn = async ({ email, password }) => {
    try {
      const resp = await APIlogin({ email, password });
      if (resp.data.message === 'ok') {
        const { data } = resp.data;
        // setPersonalInfo({ phone_number: data.phoneNumber, email: data.email });
        const newData = {
          email,
          ...data,
          user_id: data.id,
          token_exp: Date.now() + 10800000, // 3hrs = 10800000
          token: data.token,
        };
        localStorage.setItem('token', data.token);
        localStorage.setItem('expiration', newData.token_exp);
        storeUserData(data);
        history.push(ROUTES.DASHBOARD.path);
        setAuthData((d) => ({
          user: newData,
        }));
      } else {
        throw resp?.data?.error || 'Server Error';
      }
    } catch (error) {
      toast.error(errorParser(error) || 'error');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        signOut,
        signIn,
        get isSignedIn() {
          checkExpiration();
          return !!(authData.user && authData.user.token);
        },
        ...authData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
