import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import Table from '../../../components/Table';
import Icon from '../../../components/Icon';

const data = [
  {
    name: 'John Doe',
    businessName: 'MTNng',
    email: 'johndoe@gmail.com',
    type: 'New Agent',
    status: 'Pending',
    country: 'Zimbabwe',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 20,
    date: 1610302993000,
    amount: 6250,
    commission: 10,
  },
  {
    name: 'John Doe',
    businessName: 'MTNng',
    email: 'johndoe@gmail.com',
    type: 'New Partner',
    status: 'Approved',
    country: 'Nigeria',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 1300,
    date: 1610302993000,
    amount: 4250,
    commission: 10,
  },
  {
    name: 'John Doe',
    businessName: 'MTNng',
    email: 'johndoe@gmail.com',
    type: 'Client Request',
    status: 'Pending',
    country: 'Zimbabwe',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 4.5,
    date: 1610302993000,
    amount: 7950,
    commission: 10,
  },
  {
    name: 'John Doe',
    businessName: 'MTNng',
    email: 'johndoe@gmail.com',
    type: 'Sender ID',
    status: 'Approved',
    country: 'Kenya',
    phone_number: '+234 9056831073',
    service: 'Data',
    cost: 210100,
    date: 1610302993000,
    amount: 7230,
    commission: 10,
  },
  {
    name: 'John Doe',
    businessName: 'MTNng',
    email: 'johndoe@gmail.com',
    type: 'Sender ID',
    status: 'Approved',
    country: 'Nigeria',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 300000,
    date: 1610302993000,
    amount: 1250,
    commission: 10,
  },
  {
    name: 'John Doe',
    businessName: 'MTNng',
    email: 'johndoe@gmail.com',
    type: 'Agent Request',
    status: 'Denied',
    country: 'Nigeria',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 20,
    date: 1610302993000,
    amount: 5670,
    commission: 10,
  },
  {
    name: 'John Doe',
    businessName: 'MTNng',
    email: 'johndoe@gmail.com',
    type: 'Client Request',
    status: 'Denied',
    country: 'Ghana',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 20,
    date: 1610302993000,
    amount: 9320,
    commission: 10,
  },
];

const Commission = () => {
  return (
    <Table
      header={[
        {
          key: 'name',
          label: 'Name',
          sort: true,
          formatter: (name, { businessName }) => (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                <div>
                  <div>{name}</div>
                  <div className="text-muted">{businessName}</div>
                </div>
              </div>
            </>
          ),
        },
        { key: 'email', label: 'Email', sort: true },
        {
          key: 'date',
          label: 'Date',
          sort: true,
          formatter: (date) => (
            <div style={{ display: 'flex' }}>
              <Moment format="D MMM, YYYY - hh:mma" date={date} />
            </div>
          ),
        },
        { key: 'service', label: 'Service', sort: true },
        {
          Key: 'amount',
          label: 'Amount',
          formatter: (amount) => (
            <NumberFormat displayType="text" thousandSeparator prefix="₦" value={amount} />
          ),
        },
        { key: 'commission', label: 'Commissions' },
      ]}
      data={data}
      noBorder
    />
  );
};
export default Commission;
