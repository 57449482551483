import { useRecoilState } from 'recoil';
import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';

// Components
import Button from '../../../../components/Button';
import { Input, PhoneInput } from '../../../../components/inputs';

// Utils
import { providerCreateState } from '../../../../utilities/constants';
import { validator } from '../../../../utilities/helper';

const RepresentativeInfo = ({ moveToNext }) => {
  const [providerInfo, setProviderInfo] = useRecoilState(providerCreateState);
  const [formData, setFormData] = useState({ ...providerInfo, fetching: false });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      ...providerInfo,
      fetching: false,
    }));
  }, []);

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <form
      style={{ width: '100%', margin: 'auto' }}
      onSubmit={(e) => {
        e.preventDefault();
        if (validator(formData?.phoneNumber.replace(/\s/g, ''), 'phone_number')?.length > 0) {
          return;
        }
        setProviderInfo((p) => ({ ...p, ...formData }));
        moveToNext();
      }}
    >
      <Row>
        <Col style={{ fontSize: 16, fontWeight: 200, marginBottom: 15, opacity: 0.6 }}>
          Representative Information
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="First Name"
            placeholder="Enter First Name"
            name="firstName"
            type="text"
            validate="required"
            value={formData.firstName}
            onChange={handleInputChange}
          />
        </Col>
        <Col>
          <Input
            label="Last Name"
            placeholder="Enter Last Name"
            name="lastName"
            type="text"
            validate="required"
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Email"
            placeholder="Enter Email"
            name="email"
            type="email"
            validate="required"
            value={formData.email}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PhoneInput
            label="Phone Number"
            placeholder="Enter Phone Number"
            name="phoneNumber"
            validate="phone_number"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Enter Address"
            placeholder="Enter Address"
            name="address"
            type="text"
            validate="required"
            value={formData.address}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button type="submit" value="Continue" size="md" width={100} />
        </Col>
      </Row>
    </form>
  );
};

export default RepresentativeInfo;
