import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

// Components
import Icon from '../../../../components/Icon';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

// Utils
import baseAPI from '../../../../api';
import { activateProvider } from '../../../../api/provider';
import { errorParser } from '../../../../utilities/helper';

function ActivateProviderModal({ show, closeModal, data: Provider }) {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    async () => {
      const response = await baseAPI.put(activateProvider(Provider?.id));

      return response;
    },
    {
      onSuccess: ({ data }) => {
        if (data.success) {
          queryClient.invalidateQueries(['providers']);
          toast.success(data.message);
          closeModal();
        } else toast.error(data?.message);
      },
      onError: (error) => toast.error(errorParser(error)),
    }
  );
  return (
    <Modal show={show} onClose={closeModal} title="Confirm Activation">
      <div className="d-flex justify-content-center w-100 mb-4">
        <Icon name="success" width={100} margin={[0, 0, 0, 10]} />
      </div>
      <div className="text-center">Are you sure you want to activate this provide</div>
      <div
        className="d-flex justify-content-between"
        style={{
          padding: '20px 25px',
          borderTop: '1px solid #F2F2F2',
          margin: '10px 0px',
        }}
      >
        <Button
          padding={[20, 20]}
          width={150}
          value="No"
          group="outline"
          disabled={isLoading}
          onClick={closeModal}
        />
        <Button
          padding={[20, 20]}
          width={150}
          value="Yes"
          loading={isLoading}
          onClick={() => mutate()}
        />
      </div>
    </Modal>
  );
}

export default ActivateProviderModal;
