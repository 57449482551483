/* eslint-disable */
import Toast from 'react-hot-toast';
import api from '../../api';
import { APITransactionReports } from '../../api/backendRoutes';
import {
  fetchAdminTransactionsSuccessReducer,
  fetchingAdminTransactionsReducer,
  fetchingAdminTransactionsErrorReducer,
} from '../slices/transactions';

export const fetchAdminTransactionsAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingAdminTransactionsReducer());
    try {
      const resp = await APITransactionReports(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchAdminTransactionsSuccessReducer(resp.data));
      } else {
        dispatch(fetchingAdminTransactionsErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'error');
    }
  };
