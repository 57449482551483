/* eslint-disable no-param-reassign */
import axios from 'axios';
import { fetchTempUserData, fetchUserData } from '../utilities/storage';

const baseURLs = [process.env.REACT_APP_BASE_URL];

const APIs = [];

baseURLs.forEach((url) => {
  const API = axios.create({ baseURL: url });

  API.interceptors.request.use((config) => {
    config.headers = { 'Content-Type': 'application/json' };

    const { token } = fetchUserData();
    const tempUser = fetchTempUserData();
    config.headers.Authorization = `Bearer: ${token ?? tempUser.token}`;
    return config;
  });

  API.interceptors.response.use(
    async (response) => response,
    async (error) => {
      return Promise.reject(error);
    }
  );

  APIs.push(API);
});
const baseAPI = APIs[0];
export default baseAPI;
