import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
// import { useHistory } from 'react-router-dom';
import Tabs from '../../components/Tabs';
import Password from './components/Password';
import Teams from './components/Teams';
import Profile from './components/Profile';
import { AppContext } from '../../utilities/context/AppContext';
import { APIfetchRoles } from '../../api/backendRoutes';
import UserDetail from './components/UserDetail';
// import { ROUTES } from '../../utilities/constants';
// import CreateRole from './components/CreateRole';

const StyledSettings = styled.div`
  min-width: fit-content;
  margin: 10px 200px 10px 20px;
`;

const Settings = () => {
  const [userDetail, setUserDetail] = useState(false);
  const { setGoBackMethod } = useContext(AppContext);
  const [currentMember, setCurrentMember] = useState({});
  const [roles, setRoles] = useState([]);
  const handleDetail = () => {
    setGoBackMethod(() => setUserDetail(false));
    setUserDetail(true);
  };
  const getRoles = async () => {
    try {
      const resp = await APIfetchRoles();
      if (resp.data.success === true) {
        const newData = resp.data.data;
        setRoles(newData);
      } else {
        throw resp?.data?.error;
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);
  return (
    <>
      {userDetail && <UserDetail currentMember={currentMember} roles={roles} />}
      {!userDetail && (
        <StyledSettings>
          <Row>
            <Col>
              <h2>Settings</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs
                header={[
                  {
                    key: 'profile',
                    label: 'Profile',
                  },
                  {
                    key: 'password',
                    label: 'Password',
                  },
                  {
                    key: 'team',
                    label: 'Team',
                  },
                ]}
                data={{
                  profile: <Profile />,
                  password: <Password />,
                  team: (
                    <Teams
                      showUser={handleDetail}
                      setCurrentMember={setCurrentMember}
                      roles={roles}
                    />
                  ),
                }}
              />
            </Col>
          </Row>
        </StyledSettings>
      )}
    </>
  );
};
export default Settings;
