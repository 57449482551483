import styled from 'styled-components';
import { Accordion as BAccordion } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { useState } from 'react';

const StyledAccordion = styled.div`
  padding: 0 20px;
  background: #f6f6f659;

  .accordion-toggle * {
    cursor: pointer;
  }

  .accordion-header {
    &:not(:last-child) {
      border-bottom: 0.3px solid #ececec;
    }
  }
`;

const Accordion = ({ data, defaultActiveKey }) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  return (
    <StyledAccordion>
      <BAccordion defaultActiveKey={activeKey} onSelect={(k) => setActiveKey(k)}>
        {data.map(({ header, body, eventKey }, i) => (
          <div className="accordion-header" key={i}>
            {header}
            <div className="accordion-body">
              <BAccordion.Collapse eventKey={eventKey}>{body}</BAccordion.Collapse>
            </div>
          </div>
        ))}
      </BAccordion>
    </StyledAccordion>
  );
};

Accordion.Toggle = ({ value, eventKey, callback }) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  return (
    <span className="accordion-toggle" onClick={(e) => decoratedOnClick(e)}>
      {value}
    </span>
  );
};

export default Accordion;
