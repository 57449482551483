import { useState } from 'react';
import { useQuery } from 'react-query';
import { Col, Row } from 'react-bootstrap';

// Components
import Icon from '../../../components/Icon';
import Table from '../../../components/Table';
import UpdateServiceModal from './Modals/UpdateServiceModal';
import ActivateServiceModal from './Modals/ActivateServiceModal';
import DeactivateServiceModal from './Modals/DeactivateServiceModal';
import ContentWrapper from '../../../components/layout/ContentWrapper';

// Utils
import { APIlistProviderService } from '../../../api/backendRoutes';
import { ACTIVATE, DEACTIVATE, EDIT } from '../../../utilities/constants';

const Services = ({ partner }) => {
  const [currentRowValues, setCurrentRowValues] = useState({});

  const [modal, setModal] = useState({
    [ACTIVATE]: false,
    [DEACTIVATE]: false,
    [EDIT]: false,
  });

  const toggleModal = (state) => {
    setModal({
      ...modal,
      [state]: !modal[state],
    });
  };

  const handlePagination = ({ selected }) => {
    return 'Test';
  };

  const getServicesQuery = useQuery(
    ['services', partner.id],
    async () => {
      const response = await APIlistProviderService({
        params: {
          companyId: partner?.companyInformation?.[0]?.id,
        },
      });
      return response.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
  return (
    <ContentWrapper errorTitle="Error fetching services" currentQuery={getServicesQuery}>
      <Row>
        <Col>
          <Table
            header={[
              { key: '_id', label: 'ID', hidden: true },
              { key: 'serviceName', label: 'Service Name', sort: false },
              {
                key: 'apiEndpoint',
                label: 'API Endpoint',
                sort: false,
                formatter: (x) => x || 'No API Endpoint',
              },
              {
                key: 'price',
                label: 'Price',
                sort: false,
                formatter: (x) => x || 'No Price Available',
              },
              {
                key: 'menu',
                label: '',
                formatter: (x, service) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {service?.companyId !== service?.serviceOwnerId && (
                      <Icon
                        name="pencil"
                        width={20}
                        margin={[0, 0, 0, 10]}
                        onClick={() => {
                          setCurrentRowValues(service);
                          toggleModal(EDIT);
                        }}
                      />
                    )}
                    {service.isActive ? (
                      <Icon
                        name="trashcircle"
                        width={26}
                        margin={[0, 0, 0, 10]}
                        onClick={() => {
                          setCurrentRowValues(service);
                          toggleModal(DEACTIVATE);
                        }}
                      />
                    ) : (
                      <Icon
                        name="power"
                        width={20}
                        margin={[0, 0, 0, 12]}
                        onClick={() => {
                          setCurrentRowValues(service);
                          toggleModal(ACTIVATE);
                        }}
                      />
                    )}
                  </div>
                ),
              },
            ]}
            data={getServicesQuery?.data?.data || []}
            // paginate={providers?.length > 0}
            // meta={meta}
            handlePagination={handlePagination}
          />
        </Col>
        {modal[DEACTIVATE] && (
          <DeactivateServiceModal
            show={modal[DEACTIVATE]}
            data={currentRowValues}
            closeModal={() => toggleModal(DEACTIVATE)}
          />
        )}
        {modal[ACTIVATE] && (
          <ActivateServiceModal
            show={modal[ACTIVATE]}
            data={currentRowValues}
            closeModal={() => toggleModal(ACTIVATE)}
          />
        )}
        {modal[EDIT] && (
          <UpdateServiceModal
            partner={partner}
            show={modal[EDIT]}
            data={currentRowValues}
            closeModal={() => toggleModal(EDIT)}
          />
        )}
      </Row>
      {/* <StyledService>
          <div className="header">
            <div>Services</div>
            <div>Cost of Service</div>
            <div>Commission</div>
          </div>
          {providerService && showServices(providerService)}
        </StyledService> */}
    </ContentWrapper>
  );
};
export default Services;
