import Moment from 'react-moment';
import { Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useCallback, useEffect, useState } from 'react';

// Components
import Card from '../Card';
import Icon from '../Icon';
import Table from '../Table';
import Button from '../Button';

// Utils
import { APITransactionTable } from '../../api/backendRoutes';

const menu = [
  {
    key: 'transaction',
    label: 'Sender Name',
    headerAttrs: { hidden: true },
    formatter: (transaction, { company, value }) => (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon name={value > 0 ? 'increase' : 'decrease'} margin={[0, 10, 0, 0]} width={36} />
          <div>
            <div style={{ fontWeight: 500 }}>{transaction}</div>
            <div className="text-muted">{company}</div>
          </div>
        </div>
      </>
    ),
  },
  {
    key: 'value',
    label: 'Value',
    headerAttrs: { hidden: true },
    formatter: (value) => (
      <span style={{ color: value > 0 ? '#63b67f' : '#df374b' }}>
        <NumberFormat displayType="text" thousandSeparator prefix="₦" value={value} />
      </span>
    ),
  },
  {
    key: 'date',
    label: 'Date',
    headerAttrs: { hidden: true },
    formatter: (date) => <Moment format="D MMM, YYYY" date={date} />,
  },
  {
    key: 'role',
    label: '',
    headerAttrs: { hidden: true },
    formatter: (role) => <Button group="secondary" value={role} />,
  },
];

const TransactionsTable = () => {
  const [tableData, setTableData] = useState();

  const getTableData = useCallback(async () => {
    try {
      const response = await APITransactionTable();
      if (response.data.message === 'ok') {
        setTableData(response.data.data);
      } else {
        throw response?.data?.error;
      }
    } catch (error) {
      /* eslint-disable-no-empty */
    }
  }, []);

  const formatData = () => {
    return tableData.map((row) => ({
      transaction: row?.category,
      company: row?.operatorName,
      value: row?.isDebit ? -row?.amount : row?.amount,
      role: row?.userType,
      createdAt: new Date(row?.createdAt),
    }));
  };

  useEffect(() => {
    getTableData();

    return () => {
      setTableData();
    };
  }, []);
  return (
    <Col md={12}>
      <Card>
        <h4>Todays Transactions</h4>
        {tableData && <Table hideHeader noBorder header={menu} data={formatData()} />}
      </Card>
    </Col>
  );
};

export default TransactionsTable;
