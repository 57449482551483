/* eslint-disable */
import Toast from 'react-hot-toast';
import { APIlistSenderIdRequest } from '../../api/backendRoutes';
import { errorParser } from '../../utilities/helper';
import {
  fetchSenderIdSuccessReducer,
  fetchingSenderIdReducer,
  fetchingSenderIdErrorReducer,
} from '../slices/senderId';

export const fetchSenderIdAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingSenderIdReducer());
    try {
      const resp = await APIlistSenderIdRequest(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchSenderIdSuccessReducer(resp.data));
      } else {
        dispatch(fetchingSenderIdErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'error');
    }
  };
