/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userProfile: {},
  fetchingUserProfile: false,
  errorFetchingProfile: {},
};

export const currentUser = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    fetchLoggedInUserProfile: (state, { payload }) => {
      (state.userProfile = payload),
        (state.fetchingUserProfile = false),
        (state.errorFetchingProfile = {});
    },
    fetchingUserProfileReducer: (state) => {
      (state.userProfile = {}),
        (state.fetchingUserProfile = true),
        (state.errorFetchingProfile = {});
    },
    errorFetchingProfileReducer: () => {
      (state.userProfile = {}),
        (state.fetchingUserProfile = false),
        (state.errorFetchingProfile = {});
    },
  },
});

// Action creators are generated for each case reducer function
export const { fetchLoggedInUserProfile, errorFetchingProfileReducer, fetchingUserProfileReducer } =
  currentUser.actions;

export default currentUser.reducer;
