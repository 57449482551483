// import { useState } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Components
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import Button from '../../components/Button';
import { Checkbox } from '../../components/inputs';
// import ActivatePartnerModal from './components/Modals/ActivatePartnerModal';
// import DeactivatePartnerModal from './components/Modals/DeactivateProviderModal';

// Utils
import baseAPI from '../../api';
import { getPartners } from '../../api/partners';
import ContentWrapper from '../../components/layout/ContentWrapper';
// import { ACTIVATE, DEACTIVATE } from '../../utilities/constants';

const StyledPartners = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }

  .MuiCheckbox-root {
    margin-right: 10px;
    .MuiSvgIcon-root {
      color: #bbbbbb !important;
    }
  }
`;
const PartnersTable = ({ partners, meta, handleFilters }) => {
  const { push } = useHistory();

  return (
    <Row>
      <Col>
        <Table
          showFilter
          filterAction={handleFilters}
          header={[
            { key: 'id', label: 'ID', hidden: true },
            {
              key: 'firstName',
              label: (
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                  Name
                </div>
              ),
              sort: true,
              formatter: (_, { companyInformation }) => (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                    <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                    <div>
                      <div>
                        <div style={{ fontWeight: 500 }}>{companyInformation[0]?.companyName}</div>
                        <div className="text-muted">{companyInformation[0]?.companyEmail}</div>
                      </div>
                    </div>
                  </div>
                </>
              ),
            },
            { key: 'email', label: 'Email', sort: true },
            { key: 'country', label: 'Country', sort: true },
            { key: 'phoneNumber', label: 'Phone Number', sort: true },
            {
              key: 'menu',
              label: '',
              formatter: (_, partner) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    value="Details"
                    group="secondary"
                    size="sm"
                    width={70}
                    margin={[0, 10, 0, 0]}
                    onClick={() => {
                      push({
                        pathname: `/admin/partners/${partner.id}`,
                      });
                    }}
                  />
                </div>
              ),
            },
          ]}
          data={partners}
          meta={meta}
          paginate
        />
      </Col>
    </Row>
  );
};

const Partners = () => {
  const [filters, setFilters] = useState({});

  const getPartnersQuery = useQuery(
    ['partners', filters],
    async () => {
      const response = await baseAPI.get(getPartners, {
        params: {
          ...(filters || {}),
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {},
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleFilters = (filters) => {
    const newFilters = { ...filters };
    delete newFilters?.total;

    setFilters((prev) => ({ ...prev, ...newFilters }));
  };

  return (
    <StyledPartners>
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 30,
          }}
        >
          <h5>All partners</h5>
        </div>

        <ContentWrapper errorTitle="Error fetching partners" currentQuery={getPartnersQuery}>
          <PartnersTable
            partners={getPartnersQuery?.data?.data}
            meta={{
              total: getPartnersQuery?.data?.total,
              page: getPartnersQuery?.data?.page,
              limit: getPartnersQuery?.data?.limit ?? 25,
            }}
            handleFilters={handleFilters}
          />
        </ContentWrapper>
      </>
    </StyledPartners>
  );
};

export default Partners;
