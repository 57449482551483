/* eslint-disable */
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Nav, Navbar } from 'react-bootstrap';
import Icon from '../Icon';
import Avatar from '../Avatar';
import AvatarImage from '../../assets/images/avatar.jpg';
import { AppContext } from '../../utilities/context/AppContext';
import { AuthContext } from '../../utilities/context/AuthContext';
import { ROUTES } from '../router/routes/routes';
import { fetchUserData } from '../../utilities/storage';

const StyledHeader = styled.header`
  background: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  position: sticky;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;

  nav {
    background: #ffffff;
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }

  .close-icon {
    display: none;
  }
  .search {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    background: #f5f5f5 45%;
    border-radius: 5px;
  }
  @media screen and (max-width: 700px) {
    padding-left: 20px;
    padding-right: 20px;
    nav {
      display: flex;
      justify-content: space-between !important;
    }
    nav > * {
      width: 33%;
      flex: 1;
      flex-basis: unset !important;
    }
    .dropdown-toggle {
      padding: 0 !important;
    }

    .navbar-brand h4 {
      display: flex;
      justify-content: center;
    }
    .close-icon {
      display: block;
    }
    .search {
      display: none;
    }
  }
`;

const Header = ({ title = '' }) => {
  const history = useHistory();
  const { signOut } = useContext(AuthContext);
  const { goBack, setGoBackMethod, goBackMethod, toggleSideMenu } = useContext(AppContext);

  const user = fetchUserData();

  return (
    <StyledHeader>
      <Navbar>
        <div className="close-icon">
          <Icon name="burger" width={24} onClick={() => toggleSideMenu()} />
        </div>
        <Navbar.Brand>
          {!goBackMethod && (
            <h4 style={{ fontWeight: 600, display: 'flex', flexDirection: 'column' }}>
              <div>{title}</div>
              <span
                style={{
                  fontWeight: 300,
                  fontSize: 10,
                }}
              >
                14:30pm - 28 January, 2021
              </span>
            </h4>
          )}
          {goBackMethod && (
            <span
              style={{
                cursor: 'pointer',
                display: 'flex',
                fontWeight: 600,
                fontSize: 14,
                alignItems: 'center',
                color: '#17568B',
              }}
              onClick={() => {
                goBack();
                setGoBackMethod(null);
              }}
            >
              <Icon name="back" width={14} margin={[0, 10, 0, 0]} onClick={() => null} /> Back
            </span>
          )}
        </Navbar.Brand>

        <Navbar.Collapse className="justify-content-end">
          <Nav className="align-items-center">
            <div className="search">
              <Icon name="notification" width={20} />
            </div>
            <Avatar
              name={user.firstName || user.lastName}
              email={user.email}
              src={AvatarImage}
              options={[{ label: 'Logout', action: signOut }]}
            />
          </Nav>
        </Navbar.Collapse>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
      </Navbar>
    </StyledHeader>
  );
};

export default Header;
