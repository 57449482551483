import { useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import Toast from 'react-hot-toast';
import Button from '../Button';
import baseAPI from '../../api';
import { errorParser } from '../../utilities/helper';

const StyledInput = styled.div`
  padding-bottom: ${({ padding }) => (padding !== undefined ? padding : '20px')};
  font-weight: 300;

  label {
    width: 100%;

    & > span {
      font-size: 12px;
      font-weight: 300;
      padding-left: 3px;
      margin-bottom: 2px !important;
    }
  }

  ul li {
    font-size: 12px;
    font-weight: 300;
  }

  .file-upload-container {
    overflow: hidden;
    display: flex;
    width: 100%;
    border-radius: ${({ size }) => (size === 'tiny' ? '5px' : '5px')};
    font-weight: 300;
    font-size: 13px;
    cursor: pointer;
    min-height: ${({ size }) => (size === 'tiny' ? '30px' : '40px')};
    border: 1px solid #cccccc;

    .content {
      flex-grow: 1;
      padding: ${({ size }) => (size === 'tiny' ? '7.5px 8px' : '9.5px 14px')};

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  input {
    display: none;
  }

  fieldset {
    border: 1px solid #cccccc;
    border-radius: ${({ size }) => (size === 'tiny' ? '5px' : '5px')};
  }

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    font-weight: 600;
    font-size: 12px;
    transition: all 200ms ease-in-out;
    opacity: 1;
    min-height: 100%;
    border-radius: 0;
    width: 25%;
    background-color: #f6f8fa;
    padding-inline: 20px;

    .black {
      color: rgb(0, 0, 0, 0.87);
    }
  }
`;

const FileUpload = ({
  label,
  placeholder = '',
  disabled = false,
  type = 'text',
  variant = 'outlined',
  hint,
  value,
  name,
  size = 'small',
  multiline = false,
  rows = 3,
  onChange,
  validate,
  padding,
  handleError,
  idx,
  accept = '*',
  ...rest
}) => {
  const [file, setFile] = useState();

  const { mutate, isLoading } = useMutation(
    async (payload) => {
      const response = await baseAPI.post('/auth/user/image/upload', payload);
      return response.data;
    },
    {
      onSuccess: (data) => {
        Toast.success('Image uploaded successfully');
        onChange({ name, value: data?.data?.url });
      },
      onError: (error) => Toast.error(errorParser(error)),
    }
  );

  const handleChange = (e) => {
    const file = e?.target?.files[0];

    if (!file) return; /* eslint-disable no-useless-return */

    setFile({
      url: URL.createObjectURL(file),
      data: file,
    });
  };

  const handleClick = () => {
    const formData = new FormData();
    formData.append('imageFile', file?.data);
    mutate(formData);
    return;
  };

  return (
    <StyledInput>
      <label htmlFor={name}>
        <span>{label}</span>
        <div className="file-upload-container">
          <div className="tag">
            <span className="black roboto-regular">Upload</span>
          </div>
          <div className="content">
            {file?.data ? `${file?.data?.name?.substring(0, 20)}...` : placeholder}
          </div>
        </div>
        <input id={name} name={name} type="file" accept={accept} onChange={handleChange} />
      </label>
      {file?.url && file?.data && (
        <Button
          loading={isLoading}
          type="button"
          value="Upload"
          size="sm"
          width={100}
          onClick={handleClick}
        />
      )}
    </StyledInput>
  );
};

export default FileUpload;
