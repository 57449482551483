import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { APITransactionDetails } from '../../../api/backendRoutes';
import Icon from '../../../components/Icon';
import Table from '../../../components/Table';

const WalletTransactions = ({ merchant }) => {
  const [transHist, setTransHist] = useState([]);
  const singleMerchant = merchant[0];
  const [meta, setMeta] = useState({});
  async function fetchMerchants(payload) {
    const resp = await APITransactionDetails(payload);
    setTransHist(resp.data.data);
    setMeta({ limit: resp.data.limit, page: resp.data.page, total: resp.data.total });
  }

  useEffect(async () => {
    fetchMerchants({
      ...meta,
      userId: singleMerchant.id,
    });
  }, []);

  const handlePagination = ({ selected }) => {
    fetchMerchants({
      ...meta,
      page: selected,
      userId: singleMerchant.id,
    });
  };
  const menu = [
    {
      key: 'transaction',
      label: 'Transaction Name',
      formatter: (transaction, { company, value }) => (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name={value > 0 ? 'increase' : 'decrease'} margin={[0, 10, 0, 0]} width={36} />
            <div>
              <div style={{ fontWeight: 500 }}>{transaction}</div>
              <div className="text-muted">{company}</div>
            </div>
          </div>
        </>
      ),
    },
    {
      key: 'trx_id',
      label: 'Transaction ID',
    },
    {
      key: 'value',
      label: 'Debit/Credit',
      formatter: (value) => (
        <span style={{ color: value > 0 ? '#63b67f' : '#df374b' }}>
          <NumberFormat displayType="text" thousandSeparator prefix="₦" value={value} />
        </span>
      ),
    },
    {
      key: 'date',
      label: 'Date',
      formatter: (date) => <Moment format="D MMM, YYYY - hh:mma" date={date} />,
    },
    {
      key: 'menu',
      label: '',
      formatter: (role) => <Icon name="trashcircle" width={26} margin={[0, 0, 0, 10]} />,
    },
  ];

  return (
    <Table
      noBorder
      header={menu}
      // data={d}
      data={transHist.map((dt) => ({
        transaction: dt.source,
        company: 'Anderson Paak Financials',
        trx_id: dt.reference,
        value: dt.action === 'debit' ? 0 - dt.amount : dt.amount,
        role: 'Agent',
        date: dt.updatedAt,
      }))}
      paginate={Boolean(Object.keys(meta).length)}
      meta={meta}
      handlePagination={handlePagination}
    />
  );
};

export default WalletTransactions;
