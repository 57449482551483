import { useCallback, useEffect, useState } from 'react';
import Toast from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import { Col, Row } from 'react-bootstrap';

// Components
import Button from '../../../../components/Button';
import { Input, Select } from '../../../../components/inputs';

// Utils
import { toTitleCase } from '../../../../utilities/helper';
import { providerCreateState } from '../../../../utilities/constants';
import { APIfetchBankList, APIverifyBankDetails } from '../../../../api/backendRoutes';

const BankInfo = ({ moveToNext, loading }) => {
  const [providerInfo, setProviderInfo] = useRecoilState(providerCreateState);
  const [formData, setFormData] = useState({ ...providerInfo, fetching: false });
  const [bankList, setBankList] = useState([]);
  const [verifyAcct, setVerifyAcct] = useState({
    verified: false,
    verifying: false,
    error: false,
  });

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      ...providerInfo,
      fetching: false,
    }));
  }, []);

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
    setProviderInfo((p) => ({ ...p, ...formData }));
  };
  const getallBankList = useCallback(async () => {
    try {
      const resp = await APIfetchBankList();
      if (resp.data.message === 'ok') {
        const { result } = resp.data.data;
        const fetchedbankList = result.map((bank) => {
          return {
            label: bank.name,
            value: bank.code,
          };
        });
        setBankList(fetchedbankList);
      } else {
        throw resp?.data?.error;
      }
    } catch (error) {
      setBankList({});
    }
  }, []);

  useEffect(() => {
    getallBankList();
  }, []);

  const verifyBank = async () => {
    const bankDetails = {
      phoneNumber: formData.companyPhoneNumber,
      accountNumber: formData.bankAccountNumber,
      bankName: bankList?.find((bank) => bank?.value === formData.bankName)?.label,
    };
    setVerifyAcct({
      ...verifyAcct,
      verifying: true,
    });
    try {
      const resp = await APIverifyBankDetails(bankDetails);
      if (resp.data.message === 'ok') {
        const { results } = resp.data.data.data;
        setVerifyAcct({
          ...verifyAcct,
          verified: true,
          verifying: false,
        });
        Toast.success('Bank successfully verified');
        moveToNext({
          ...providerInfo,
          ...formData,
          bankName: bankList?.find((bank) => bank?.value === formData.bankName)?.label,
          bankAccountName: toTitleCase(results?.account_name),
          bankCode: formData?.bankName,
        });
      } else {
        setVerifyAcct({
          ...verifyAcct,
          verified: false,
          error: true,
          verifying: false,
        });
        throw resp?.data?.error;
      }
    } catch (error) {
      setVerifyAcct({
        ...verifyAcct,
        verified: false,
        error: true,
        verifying: false,
      });
      Toast.error("Error Can't verify Bank Details");
    }
  };
  return (
    <form
      style={{ width: '100%', margin: 'auto' }}
      onSubmit={(e) => {
        e.preventDefault();
        setProviderInfo((p) => ({ ...p, ...formData }));
        // moveToNext();
        verifyBank();
        if (verifyAcct.verified) moveToNext();
      }}
    >
      <Row>
        <Col style={{ fontSize: 16, fontWeight: 200, marginBottom: 15, opacity: 0.6 }}>
          Bank Information
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            label="Bank Name"
            placeholder="Enter Bank Name"
            name="bankName"
            type="text"
            validate="required"
            defaultValue={formData.bankName}
            onChange={handleInputChange}
            options={bankList}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <Input
            label="Bank Account Name"
            placeholder="Enter Bank Account Name"
            name="bankAccountName"
            type="text"
            validate="required"
            defaultValue={formData.bankAccountName}
            onChange={handleInputChange}
          />
        </Col>
      </Row> */}
      <Row>
        <Col>
          <Input
            label="Bank Account Number"
            placeholder="Enter Bank Account Number"
            name="bankAccountNumber"
            type="number"
            validate="required"
            defaultValue={formData.bankAccountNumber}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20 }}
        >
          <Button
            type="submit"
            value="Continue"
            size="md"
            width={100}
            loading={verifyAcct.verifying || loading}
          />
          {verifyAcct.error ? (
            <div
              style={{
                color: 'red',
                fontSize: '12px',
              }}
            >
              Error verifying bank details
            </div>
          ) : null}
        </Col>
      </Row>
    </form>
  );
};

export default BankInfo;
