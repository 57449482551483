import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Table from '../../components/Table';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Card from '../../components/Card';
import { APIMerchantWalleDetails } from '../../api/backendRoutes';
import { fetchAdminWalletDetailsAction } from '../../redux/actions/walletDetails';
import WalletDetailChart from './components/WalletDetailChart';
import FundWallet from '../../components/FundWallet';
import DebitWallet from '../../components/DebitWallet';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  color: #ffffff;
  padding: 30px 20px 10px 20px;
  font-size: 14px;
  font-weight: 600;
  background: #17568b;

  > div {
    display: flex;
    align-items: center;
  }

  h6 {
    background: #ffffff;
    color: #000000;
    border-radius: 5px;
    padding: 7px 20px;
    font-weight: 600;
    font-size: 24px;
    margin: 10px 0;
  }
`;

const mapStateToProps = ({ walletDetails }) => {
  return {
    fetchingWallet: walletDetails.fetchingWallet,
    walletDetails: walletDetails.walletDetails,
    meta: walletDetails.walletDetailsMeta,
  };
};
const WalletDetail = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [debitShow, setDebitShow] = useState(false);
  const [walletSummary, setWalletSummary] = useState();
  const { id } = useParams();

  const dispatch = useDispatch();

  const { fetchingWalletDetails, walletDetails, meta } = useSelector(mapStateToProps);

  const fetchWalletDetails = useCallback((params) =>
    dispatch(fetchAdminWalletDetailsAction({ ...params, limit: 25 }), [dispatch])
  );
  useEffect(() => {
    fetchWalletDetails({ merchantOrganizationId: id });
  }, []);

  const fetchSingleWalletInfo = async () => {
    try {
      setLoading(true);
      const res = await APIMerchantWalleDetails({ accountId: id });
      setWalletSummary(res.data.data.data[0]);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSingleWalletInfo();
  }, []);
  return (
    <>
      {loading ? (
        '...'
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 30,
            }}
          >
            <h5>
              {walletSummary?.Customer?.first_name} {walletSummary?.Customer?.last_name}
            </h5>
          </div>

          <Row style={{ marginBottom: 30 }}>
            <Col md={8}>
              <Card>
                <WalletDetailChart />
              </Card>
            </Col>
            <Col md={4}>
              <div>
                <StyledCard className="blue-bg">
                  <div>Total Wallet Balance:</div>
                  <h6 style={{ color: '#555555' }}>
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="₦"
                      value={walletSummary?.balance || 0}
                      className=""
                    />
                  </h6>
                </StyledCard>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                <Button
                  width={150}
                  group="secondary"
                  size="sm"
                  value="Fund Wallet"
                  onClick={() => setShow(true)}
                />
                <Button
                  width={150}
                  group="secondary danger"
                  size="sm"
                  value="Debit Wallet"
                  onClick={() => setDebitShow(true)}
                />
              </div>
              <div style={{ marginTop: 25 }}>
                <div style={{ fontWeight: 500, marginBottom: 5 }}>Linked Bank Info</div>
                <div
                  style={{
                    fontWeight: 300,
                    border: '1px solid #E0E0E0',
                    padding: '8px 13px',
                    borderRadius: 10,
                  }}
                >
                  <div>
                    Account Name - {walletSummary?.Customer?.first_name}{' '}
                    {walletSummary?.Customer?.last_name}
                  </div>
                  <div style={{ marginTop: 10 }}>
                    Account Number - {walletSummary?.account_number}
                  </div>
                  <div style={{ marginTop: 10 }}>Bank Name - {walletSummary?.bank_name}</div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Table
                noBorder={false}
                filterAction={fetchWalletDetails}
                header={[
                  {
                    key: 'action',
                    label: 'Transaction Narration',
                    formatter: (transaction, { narration, action }) => (
                      <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Icon
                            name={action === 'credit' ? 'increase' : 'decrease'}
                            margin={[0, 10, 0, 0]}
                            width={36}
                          />
                          <div>
                            <div className="text-muted">{narration}</div>
                          </div>
                        </div>
                      </>
                    ),
                  },
                  {
                    key: '_id',
                    label: 'Transaction ID',
                    sort: true,
                  },
                  {
                    key: 'action',
                    label: 'Action',
                    sort: true,
                  },
                  {
                    key: 'amount',
                    label: 'Debit/Credit',
                    sort: true,
                    formatter: (cost) => (
                      <NumberFormat
                        displayType="text"
                        thousandSeparator
                        prefix="₦"
                        value={cost}
                        className={cost > 0 ? 'green' : 'red'}
                      />
                    ),
                  },
                  {
                    key: 'date',
                    label: 'Date',
                    sort: true,
                    formatter: (date) => (
                      <div style={{ display: 'flex' }}>
                        <Moment format="D MMM, YYYY - hh:mma" date={date} />
                      </div>
                    ),
                  },
                ]}
                meta={meta}
                loading={fetchingWalletDetails}
                paginate={Boolean(Object.keys(meta).length)}
                data={walletDetails}
              />
            </Col>
          </Row>
          <FundWallet
            show={show}
            wallet={walletSummary}
            refetch={fetchSingleWalletInfo}
            onClose={() => setShow(false)}
          />
          <DebitWallet
            show={debitShow}
            wallet={walletSummary}
            refetch={fetchSingleWalletInfo}
            onClose={() => setDebitShow(false)}
          />
        </div>
      )}
    </>
  );
};

export default WalletDetail;
