import { useQuery } from 'react-query';

import { useState } from 'react';
// Compoments
import Icon from '../../../components/Icon';
import Table from '../../../components/Table';
import Button from '../../../components/Button';
import ContentWrapper from '../../../components/layout/ContentWrapper';

// Utils
import baseAPI from '../../../api';
import { getPartnerMerchants } from '../../../api/partners';

const MerchantTable = ({ merchants, total, page, handleFilters, limit = 10 }) => {
  return (
    <Table
      filter={{}}
      header={[
        {
          key: 'name',
          label: 'Name',
          sort: true,
          formatter: (name, { email }) => (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                <div>
                  <div>{name}</div>
                  <div className="text-muted">{email}</div>
                </div>
              </div>
            </>
          ),
        },
        { key: 'email', label: 'Email', sort: true },
        { key: 'country', label: 'Country', sort: true },
        { key: 'phone_number', label: 'Phone Number', sort: true },
        {
          key: 'menu',
          label: '',
          formatter: () => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                value="Details"
                group="secondary"
                size="sm"
                width={70}
                margin={[0, 10, 0, 0]}
              />
            </div>
          ),
        },
      ]}
      data={merchants}
      paginate={total > 0}
      meta={{ page, total, limit }}
      filterAction={handleFilters}
      showFilter
      noBorder
    />
  );
};

const Merchants = ({ partner }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [limit, setLimit] = useState(10);

  const getMerchantsQuery = useQuery(
    ['partner_merchants', currentPage, filters, partner?.companyInformation[0]?.id],
    async () => {
      const response = await baseAPI.get(getPartnerMerchants, {
        params: {
          companyId: partner?.companyInformation[0]?.id,
          limit: 10,
          page: currentPage,
          ...filters,
        },
      });
      return response.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleFilters = (data) => {
    const payload = { ...data };
    delete payload?.total;
    setFilters({ ...payload });
    setLimit(payload?.limit);
    setCurrentPage(payload?.page);
  };

  return (
    <>
      <ContentWrapper errorTitle="Error fetching merchants" currentQuery={getMerchantsQuery}>
        <MerchantTable
          page={getMerchantsQuery?.data?.page}
          merchants={getMerchantsQuery?.data?.data}
          total={getMerchantsQuery?.data?.total}
          handleFilters={handleFilters}
          limit={limit}
        />
      </ContentWrapper>
    </>
  );
};
export default Merchants;
