/* eslint-disable react/destructuring-assignment */
import { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import Tabs from '../../components/Tabs';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import Button from '../../components/Button';
import CreateAgent from './components/CreateAgent';
import Accordion from '../../components/Accordion';
import { Input, PhoneInput, PasswordInput, Checkbox } from '../../components/inputs';
import { AppContext } from '../../utilities/context/AppContext';

const StyledAgents = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }

  .MuiCheckbox-root {
    margin-right: 10px;
    .MuiSvgIcon-root {
      color: #bbbbbb !important;
    }
  }
`;

const data = [
  {
    name: 'John Doez',
    email: 'johndoe@gmail.com',
    type: 'New Agent',
    status: 'Pending',
    country: 'Zimbabwe',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 20,
    commission: 2,
    date: 1610302993000,
  },
  {
    name: 'John Doem',
    email: 'johndoe@gmail.com',
    type: 'New Agent',
    status: 'Approved',
    country: 'Nigeria',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 1300,
    commission: 2,
    date: 1610302993000,
  },
  {
    name: 'John Doen',
    email: 'johndoe@gmail.com',
    type: 'Client Request',
    status: 'Pending',
    country: 'Zimbabwe',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 4.5,
    commission: 2,
    date: 1610302993000,
  },
  {
    name: 'John Doeb',
    email: 'johndoe@gmail.com',
    type: 'Sender ID',
    status: 'Approved',
    country: 'Kenya',
    phone_number: '+234 9056831073',
    service: 'Data',
    cost: 210100,
    commission: 2,
    date: 1610302993000,
  },
  {
    name: 'John Does',
    email: 'johndoe@gmail.com',
    type: 'Sender ID',
    status: 'Approved',
    country: 'Nigeria',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 300000,
    commission: 2,
    date: 1610302993000,
  },
  {
    name: 'John Doeo',
    email: 'johndoe@gmail.com',
    type: 'Agent Request',
    status: 'Denied',
    country: 'Nigeria',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 20,
    commission: 2,
    date: 1610302993000,
  },
  {
    name: 'John Doeu',
    email: 'johndoe@gmail.com',
    type: 'Client Request',
    status: 'Denied',
    country: 'Ghana',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 20,
    commission: 2,
    date: 1610302993000,
  },
];

const StyledAccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  color: #505050;
  font-size: 14px;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledAccordionBody = styled.div`
  padding-bottom: 20px;
`;

const AgentProfile = () => {
  const profile = useMemo(
    () => [
      {
        header: (
          <StyledAccordionHeader>
            <div>
              <Icon name="user-add" width={20} margin={[0, 20, 0, 0]} />
              Personal Information
            </div>
            <Accordion.Toggle value={<Icon name="caret-down" width={24} />} eventKey="0" />
          </StyledAccordionHeader>
        ),
        body: (
          <StyledAccordionBody>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="First Name"
                  placeholder="First Name"
                  name="first_name"
                  type="text"
                  validate="required"
                />
              </Col>
              <Col md={6} sm={6}>
                <Input
                  label="Last Name"
                  placeholder="Last Name"
                  name="last_name"
                  type="text"
                  validate="required"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="Email"
                  placeholder="Enter Email"
                  name="email"
                  type="email"
                  validate="required"
                />
              </Col>
              <Col md={6} sm={6}>
                <PhoneInput
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  name="phone_number"
                  validate="required"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <PasswordInput
                  label="Password"
                  placeholder="Password"
                  name="password"
                  validate="required|password"
                />
              </Col>
              <Col md={6} sm={6}>
                <PasswordInput
                  label="Verify Password"
                  placeholder="Verify Password"
                  name="verify_password"
                  validate="required|password"
                />
              </Col>
            </Row>
          </StyledAccordionBody>
        ),
        eventKey: '0',
      },
      {
        header: (
          <StyledAccordionHeader>
            <div>
              <Icon name="building" width={18} margin={[0, 20, 0, 0]} />
              Company Information
            </div>
            <Accordion.Toggle value={<Icon name="caret-down" width={24} />} eventKey="1" />
          </StyledAccordionHeader>
        ),
        body: (
          <StyledAccordionBody>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="Company Name"
                  placeholder="Company Name"
                  name="company_name"
                  type="text"
                  validate="required"
                />
              </Col>
              <Col md={6} sm={6}>
                <Input
                  label="Email"
                  placeholder="Enter Email"
                  name="email"
                  type="email"
                  validate="required"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <PhoneInput
                  label="Phone Number"
                  placeholder="Enter Phone Number"
                  name="phone_number"
                  validate="required"
                />
              </Col>
              <Col md={6} sm={6}>
                <Input
                  label="Address"
                  placeholder="Address"
                  name="address"
                  type="text"
                  validate="required"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="City"
                  placeholder="City"
                  name="city"
                  type="text"
                  validate="required"
                />
              </Col>
              <Col md={6} sm={6}>
                <Input
                  label="State"
                  placeholder="State"
                  name="state"
                  type="text"
                  validate="required"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="Country"
                  placeholder="Country"
                  name="country"
                  type="text"
                  validate="required"
                />
              </Col>
            </Row>
          </StyledAccordionBody>
        ),
        eventKey: '1',
      },
      {
        header: (
          <StyledAccordionHeader>
            <div>
              <Icon name="bank" width={16} margin={[0, 20, 0, 0]} />
              Bank Information
            </div>
            <Accordion.Toggle value={<Icon name="caret-down" width={24} />} eventKey="2" />
          </StyledAccordionHeader>
        ),
        body: (
          <StyledAccordionBody>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="Bank Name"
                  placeholder="Bank Name"
                  name="bank_name"
                  type="text"
                  validate="required"
                />
              </Col>
              <Col md={6} sm={6}>
                <Input
                  label="Bank Account Name"
                  placeholder="Bank Account Name"
                  name="bank_account_name"
                  type="text"
                  validate="required"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  label="Bank Account Number"
                  placeholder="Bank Account Number"
                  name="bank_account_number"
                  type="number"
                  validate="required"
                />
              </Col>
            </Row>
          </StyledAccordionBody>
        ),
        eventKey: '2',
      },
    ],
    []
  );
  return (
    <Row>
      <Col md={8} sm={8} style={{ paddingRight: 0, borderRight: '0.3px solid #ececec' }}>
        <Accordion data={profile} />
      </Col>
      <Col md={4} sm={4}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 30,
            paddingBottom: 60,
            alignItems: 'center',
          }}
        >
          <Icon name="avatar" width={160} margin={[0, 0, 20, 0]} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px',
                borderRight: '1px solid #e7e7e7',
                color: '#df374b',
                fontWeight: 600,
                fontSize: 12,
              }}
            >
              <Icon name="trash-circle" width={20} margin={[0, 5, 0, 0]} /> Delete
            </span>{' '}
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px',
                color: '#17568B',
                fontWeight: 600,
                fontSize: 12,
              }}
            >
              {' '}
              <Icon name="upload" width={20} margin={[0, 5, 0, 0]} /> Upload
            </span>{' '}
          </div>
        </div>
      </Col>
    </Row>
  );
};

const Services = () => {
  return (
    <Table
      header={[
        {
          key: 'service',
          label: 'Service',
          sort: true,
        },
        {
          key: 'cost',
          label: 'Cost of Service',
          sort: true,
          formatter: (cost) => (
            <NumberFormat displayType="text" thousandSeparator prefix="₦" value={cost} />
          ),
        },
        {
          key: 'menu',
          label: 'Service Option',
          formatter: () => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                value="Deactivate"
                group="secondary danger"
                size="sm"
                width={80}
                margin={[0, 10, 0, 0]}
              />
            </div>
          ),
        },
      ]}
      data={data.filter((d) => d.status === 'Denied')}
      theadBg="#ffffff"
      noBorder
    />
  );
};

const Commission = () => {
  return (
    <Table
      header={[
        {
          key: 'name',
          label: 'Name',
          sort: true,
          formatter: (name) => (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                <div>{name}</div>
              </div>
            </>
          ),
        },
        { key: 'email', label: 'Email' },
        {
          key: 'date',
          label: 'Date',
          formatter: (date) => <Moment format="D MMM, YYYY - hh:mma" date={date} />,
        },
        { key: 'service', label: 'Service' },
        {
          key: 'cost',
          label: 'Amount',
          formatter: (cost) => (
            <NumberFormat displayType="text" thousandSeparator prefix="₦" value={cost} />
          ),
        },
        {
          key: 'commission',
          label: 'Commission',
          formatter: (commission, { cost }) => (
            <>
              <span>{commission.toFixed(1)}%</span> (
              <NumberFormat
                displayType="text"
                thousandSeparator
                prefix="₦"
                value={(cost * commission) / 100}
              />
              )
            </>
          ),
        },
      ]}
      data={data.filter((d) => d.status === 'Approved')}
      noBorder
    />
  );
};

const Clients = () => {
  return (
    <Table
      header={[
        {
          key: 'name',
          label: 'Name',
          sort: true,
          formatter: (name, { email }) => (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                <div>{name}</div>
              </div>
            </>
          ),
        },
        { key: 'email', label: 'Email', sort: true },
        { key: 'country', label: 'Country', sort: true },
        { key: 'phone_number', label: 'Phone Number', sort: true },
        {
          key: 'menu',
          label: '',
          formatter: () => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                value="Details"
                group="secondary"
                size="sm"
                width={70}
                margin={[0, 10, 0, 0]}
              />
            </div>
          ),
        },
      ]}
      data={data.filter((d) => d.status === 'Approved')}
      noBorder
    />
  );
};

const WalletTransactions = () => {
  const menu = [
    {
      key: 'transaction',
      label: 'Transaction Name',
      formatter: (transaction, { company, value }) => (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name={value > 0 ? 'increase' : 'decrease'} margin={[0, 10, 0, 0]} width={36} />
            <div>
              <div style={{ fontWeight: 500 }}>{transaction}</div>
              <div className="text-muted">{company}</div>
            </div>
          </div>
        </>
      ),
    },
    {
      key: 'trx_id',
      label: 'Transaction ID',
    },
    {
      key: 'value',
      label: 'Debit/Credit',
      formatter: (value) => (
        <span style={{ color: value > 0 ? '#63b67f' : '#df374b' }}>
          <NumberFormat displayType="text" thousandSeparator prefix="₦" value={value} />
        </span>
      ),
    },
    {
      key: 'date',
      label: 'Date',
      formatter: (date) => <Moment format="D MMM, YYYY - hh:mma" date={date} />,
    },
  ];

  const d = [
    {
      transaction: 'Wallet TopUp',
      company: 'Anderson Paak Financials',
      trx_id: 'qmiYTFVBNaabvs',
      value: 1795000,
      role: 'Agent',
      date: 1610302993000,
    },
    {
      transaction: 'Data Campaign',
      company: 'Reliance HMO',
      trx_id: 'qmiYTFVBNaabvs',
      value: -43000,
      role: 'Client',
      date: 1610302993000,
    },
    {
      transaction: 'SMS Service',
      company: 'First Bank LTD',
      trx_id: 'qmiYTFVBNaabvs',
      value: -1795000,
      role: 'Client',
      date: 1610302993000,
    },
    {
      transaction: 'Commision Fee',
      company: 'Blusalt Financials',
      trx_id: 'qmiYTFVBNaabvs',
      value: 1795000,
      role: 'Client',
      date: 1610302993000,
    },
  ];

  return <Table noBorder header={menu} data={d} />;
};

const Agent = () => {
  return (
    <StyledAgents>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h5>Agent Information</h5>
        <Button
          value="Deactivate Agent"
          group="secondary danger round"
          icon="close-solid"
          width={150}
          margin={[0, 0, 0, 10]}
        />
      </div>

      <Tabs
        header={[
          {
            key: 'profile',
            label: 'Profile',
          },
          {
            key: 'wallet_trx',
            label: 'Wallet Transactions',
          },
          {
            key: 'commissions',
            label: 'Commissions',
          },
          {
            key: 'clients',
            label: 'Clients',
          },
          {
            key: 'services',
            label: 'Services',
          },
        ]}
        data={{
          profile: <AgentProfile />,
          wallet_trx: <WalletTransactions />,
          commissions: <Commission />,
          clients: <Clients />,
          services: <Services />,
        }}
      />
    </StyledAgents>
  );
};

const Agents = () => {
  const [showCreateAgentModal, setShowCreateAgentModal] = useState(false);
  const [showAgent, setShowAgent] = useState(false);
  const { setGoBackMethod } = useContext(AppContext);

  return (
    <StyledAgents>
      {showAgent && <Agent />}
      {!showAgent && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 30,
            }}
          >
            <h5>All agents</h5>
            <Button
              value="Create Agent"
              group="secondary round"
              icon="plus-circle"
              width={150}
              onClick={() => setShowCreateAgentModal(true)}
              margin={[0, 0, 0, 10]}
            />
          </div>

          <Row>
            <Col>
              <Table
                header={[
                  {
                    key: 'name',
                    label: (
                      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                        Name
                      </div>
                    ),
                    sort: true,
                    formatter: (name, { email }) => (
                      <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                          <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                          <div>
                            <div>{name}</div>
                            <div className="text-muted">{email}</div>
                          </div>
                        </div>
                      </>
                    ),
                  },
                  { key: 'email', label: 'Email', sort: true },
                  { key: 'country', label: 'Country', sort: true },
                  { key: 'phone_number', label: 'Phone Number', sort: true },
                  {
                    key: 'menu',
                    label: '',
                    formatter: () => (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          value="Details"
                          group="secondary"
                          size="sm"
                          width={70}
                          margin={[0, 10, 0, 0]}
                          onClick={() => {
                            setGoBackMethod(() => setShowAgent(false));
                            setShowAgent(true);
                          }}
                        />
                        <Icon name="trashcircle" width={26} margin={[0, 0, 0, 10]} />
                      </div>
                    ),
                  },
                ]}
                data={data.filter((d) => d.status === 'Approved')}
              />
            </Col>
          </Row>
          {showCreateAgentModal && (
            <CreateAgent
              show={showCreateAgentModal}
              onClose={() => setShowCreateAgentModal(false)}
            />
          )}
        </>
      )}
    </StyledAgents>
  );
};

export default Agents;
