/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prices: [],
  fetchingPrices: false,
  pricesMeta: {},
};

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    fetchPricesSuccessReducer: (state, { payload }) => {
      state.prices = payload.data;
      state.fetchingPrices = false;
      state.pricesMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingPricesReducer: (state) => {
      state.fetchingPrices = true;
      state.prices = [];
      state.pricesMeta = {};
    },
    fetchingPricesErrorReducer: (state) => {
      state.fetchingPrices = false;
      state.prices = [];
      state.pricesMeta = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { fetchPricesSuccessReducer, fetchingPricesReducer, fetchingPricesErrorReducer } =
  pricesSlice.actions;

export default pricesSlice.reducer;
