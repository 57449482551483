import Toast from 'react-hot-toast';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Country, State } from 'country-state-city';
import { useMutation, useQueryClient } from 'react-query';

// Components
import Button from '../../../../components/Button';
import { Input, PhoneInput, Select } from '../../../../components/inputs';

// Utils
import baseAPI from '../../../../api';
import { updateProvider } from '../../../../api/provider';
import { errorParser } from '../../../../utilities/helper';

const StyledAccordionBody = styled.div`
  padding-bottom: 20px;
`;

const CompanyProfileInfo = ({ provider }) => {
  const { companyInformation } = provider;
  const queryClient = useQueryClient();
  const [states, setStates] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({
    ...companyInformation?.[0],
  });

  const { mutate, isLoading } = useMutation(
    async (payload) => {
      const response = await baseAPI.put(updateProvider(provider?.id), { ...payload });

      return response;
    },
    {
      onSuccess: ({ data }) => {
        if (data.success) {
          queryClient.invalidateQueries(['providers']);
          Toast.success("Updated Successfully");
        } else Toast.error(data?.message);
      },
      onError: (error) => Toast.error(errorParser(error)),
    }
  );

  const getCountries = () => {
    const countries = Country.getAllCountries();

    return countries.map((data) => ({
      label: data?.name,
      value: data?.name,
      ...data,
    }));
  };

  const getState = (code) => {
    const parsedStates = State.getStatesOfCountry(code).map((data) => ({
      label: data?.name,
      value: data?.name,
      ...data,
    }));
    setStates([...parsedStates]);
  };

  useEffect(() => {
    const newFormData = { ...companyInfo };
    delete newFormData?.state;

    setCompanyInfo({ ...newFormData });
  }, [companyInfo.country]);

  useEffect(() => {
    if (companyInfo?.country) {
      const iso = Country.getAllCountries().find((c) => c.name === companyInfo.country).isoCode;
      getState(iso);
    }
  }, []);

  const handleCompanyChange = ({ name, value }) => {
    setCompanyInfo((v) => ({ ...v, [name]: value }));
    if (name === 'country') {
      const selectedCountry = getCountries().filter((cont) => cont.value === value);
      const { isoCode } = selectedCountry[0];

      getState(isoCode);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = { ...companyInfo };

    delete payload?.id;
    delete payload?.address;
    delete payload?.createdAt;
    delete payload?.createdBy;
    delete payload?.updatedAt;
    delete payload?.businessRegNumber;

    Object.keys(payload).forEach((key) => {
      if (!payload?.[key]) {
        delete payload?.[key];
      }
    });

    mutate(payload);
  };

  return (
    <StyledAccordionBody>
      <form style={{ width: '100%', margin: 'auto' }} onSubmit={handleSubmit}>
        <Row>
          <Col md={6} sm={6}>
            <Input
              label="Company Name"
              placeholder="Company Name"
              name="companyName"
              type="text"
              validate="required"
              defaultValue={companyInfo.companyName}
              onChange={handleCompanyChange}
            />
          </Col>
          <Col md={6} sm={6}>
            <Input
              label="Email"
              placeholder="Enter Email"
              name="companyEmail"
              type="email"
              validate="required"
              onChange={handleCompanyChange}
              defaultValue={companyInfo.companyEmail}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6}>
            <PhoneInput
              label="Phone Number"
              placeholder="Enter Phone Number"
              name="companyPhoneNumber"
              validate="required"
              onChange={handleCompanyChange}
              defaultValue={companyInfo.companyPhoneNumber}
            />
          </Col>
          <Col md={6} sm={6}>
            <Input
              label="Address"
              placeholder="Address"
              name="companyAddress"
              type="text"
              validate="required"
              onChange={handleCompanyChange}
              defaultValue={companyInfo.companyAddress}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={6}>
            <Select
              label="Country"
              placeholder="Country"
              name="country"
              type="text"
              validate="required"
              options={getCountries()}
              onChange={handleCompanyChange}
              defaultValue={companyInfo.country}
            />
          </Col>
          <Col md={6} sm={6}>
            <Select
              label="State"
              placeholder="State"
              name="state"
              type="text"
              validate="required"
              options={states}
              onChange={handleCompanyChange}
              defaultValue={companyInfo.state}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <Button type="submit" value="Apply Changes" size="lg" width={300} loading={isLoading} />
          </Col>
        </Row>
      </form>
    </StyledAccordionBody>
  );
};

export default CompanyProfileInfo;
