/* eslint-disable */
import Toast from 'react-hot-toast';
import api from '../../api';
import { APIfetchAdminAuditReports } from '../../api/backendRoutes';
import {
  fetchAdminAuditTrailsSuccessReducer,
  fetchingAdminAuditTrailsReducer,
  fetchingAdminAuditTrailsErrorReducer,
} from '../slices/audits';

export const fetchAdminAuditTrailsAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingAdminAuditTrailsReducer());
    try {
      const resp = await APIfetchAdminAuditReports(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchAdminAuditTrailsSuccessReducer(resp.data));
      } else {
        dispatch(fetchingAdminAuditTrailsErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'error');
    }
  };
