import toast from 'react-hot-toast';
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { APIUpdateRole } from '../../../api/backendRoutes';
import { errorParser } from '../../../utilities/helper';
import Button from '../../../components/Button';
import { Input, Select } from '../../../components/inputs';

const StyledDetail = styled.div`
  background-color: white;
`;
const StyledProfile = styled.div`
  padding: 20px;
`;

const UserDetail = ({ currentMember, roles }) => {
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const updateRole = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const resp = await APIUpdateRole({
        teammemberRole: role,
        memberId: currentMember.id,
      });
      if (resp.data.message === 'ok') {
        toast.success('Role updated successfully');
        setLoading(false);
      } else {
        setLoading(false);
        throw resp?.data?.error;
      }
    } catch (err) {
      setLoading(false);
      toast.error(errorParser(err) || 'Server error');
    }
  };
  return (
    <StyledDetail>
      {/* <Tabs
        header={[{ key: 'profile', label: 'Profile' }]}
        data={{
          profile: <Profile currentMember={currentMember} />,
        }}
      /> */}
      <form onSubmit={updateRole}>
        <StyledProfile>
          <Row>
            <Col md={6}>
              <Input
                label="Fullname name"
                name="firstName"
                defaultValue={currentMember.name}
                type="text"
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Input
                label="Email Address"
                placeholder="Enter Email Address"
                name="email"
                defaultValue={currentMember?.email}
                type="text"
                disabled
                validate="required"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {/* Current Role: {currentMember?.role} */}
              <Select
                label="Role"
                placeholder="Enter Role"
                name="Role"
                type="text"
                defaultValue={currentMember?.role}
                onChange={(e) => setRole(e?.value)}
                validate="required"
                options={roles?.map((r) => ({ value: r.id, label: r.roleName }))}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button value="Save changes" type="submit" loading={loading} />
            </Col>
          </Row>
        </StyledProfile>
      </form>
    </StyledDetail>
  );
};

export default UserDetail;
