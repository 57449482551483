/* eslint-disable */
import { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import Button from '../../../components/Button';
import { FormFields } from '../../../components/inputs';
import Modal from '../../../components/Modal';
import { createMerchant } from '../../../utilities/constants';
import { createMerchantAction } from '../../../redux/actions/merchant';
import Icon from '../../../components/Icon';
import { AppContext } from '../../../utilities/context/AppContext';

const StyledInputField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const CreateMerchant = ({ show = true, onClose, reload }) => {
  const { partnerInfo } = useContext(AppContext);
  const [error, setError] = useState(false);
  const [merchantData, setMerchantData] = useState(createMerchant);
  const [successModal, setSuccessModal] = useState(false);
  const [modalCtr, setModalCtr] = useState({
    title: 'Personal Information',
    start: 0,
    end: 10,
    button: 'Add Merchant',
    header: 'Create Merchant',
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const createMerch = useCallback((data) => dispatch(createMerchantAction(data)), []);
  const mapStateToProps = ({ merchant }) => {
    return {
      newMarch: merchant.merchantCreated,
      creatingMerchant: merchant.creatingMerchant,
      errorMarch: merchant.merchantCreationFailed,
    };
  };
  const { newMarch, creatingMerchant, errorMarch } = useSelector(mapStateToProps);
  // useEffect(() => {
  //   const resetForm = merchantData.map((data) => {
  //     return {
  //       ...data,
  //       value: '',
  //     };
  //   });
  //   setMerchantData(resetForm);
  // }, [show]);

  const resetMerchantForm = () => {
    const resetForm = merchantData.map((data) => {
      return {
        ...data,
        value: '',
      };
    });
    setMerchantData(resetForm);
  };
  useEffect(() => {
    if (newMarch?.success) {
      setSuccessModal(true);
      resetMerchantForm();
    }
  }, [newMarch]);

  const handleMerchantCreation = async (dataToSubmit) => {
    if (!error) {
      createMerch(dataToSubmit);
    }
  };
  const handleChange = ({ name, value, idx }) => {
    // setError(false)
    const newMerchantData = merchantData;
    newMerchantData[idx + modalCtr.start].value = value;
    setMerchantData(newMerchantData);
  };

  const goback = () => {
    setModalCtr({
      title: 'Personal Information',
      start: 0,
      end: 9,
      button: 'Continue',
      header: 'Create Merchant',
    });
  };

  const handleMerchantForm = () => {
    let computedData;
    if (modalCtr.button === 'Continue') {
      setModalCtr({
        title: 'Company Information',
        start: 9,
        end: 15,
        button: 'Add Merchant',
        header: '',
      });
    } else if (modalCtr.button === 'Add Merchant') {
      const computedError = merchantData.filter((data) => {
        return data.value === '';
      });

      if (computedError.length === 0) {
        const dataToSubmit = merchantData.reduce((acc, data) => {
          return (computedData = {
            ...acc,
            [data.name]: data.value,
          });
        }, {});

        handleMerchantCreation({
          ...dataToSubmit,
          companyId: partnerInfo?.id,
          state: partnerInfo?.state || 'N/A',
          address: partnerInfo?.address || 'new address',
          companyAddress: partnerInfo?.companyAddress,
          companyState: partnerInfo?.state || 'N/A',
          identificationNum: partnerInfo?.id,
        });
      } else {
        setError(true);
      }
    }
  };
  const handleError = (err) => {
    if (err.length > 0) {
      setError(true);
    } else {
      setError(false);
    }
    //
  };
  const ShowMerchantForm = () => {
    const merchantNfoForm = merchantData.slice(modalCtr.start, modalCtr.end);
    return (
      <StyledInputField>
        <Row>
          <Col style={{ fontSize: 18, fontWeight: 200, marginBottom: 15, opacity: 0.6 }}>
            {modalCtr.title}
          </Col>
        </Row>
        <Row>
          {merchantNfoForm.map((field, idx) => (
            <Col md={6}>
              <FormFields {...field} onChange={handleChange} idx={idx} handleError={handleError} />
            </Col>
          ))}
        </Row>
        <Row>
          <div style={{ width: '100%' }}>
            <Button
              value={modalCtr.button}
              onClick={handleMerchantForm}
              center
              size="md"
              width="175px"
              loading={creatingMerchant}
            />

            {error && (
              <div
                style={{
                  width: '100%',
                  color: 'red',
                  fontSize: '12px',
                  margin: '5px 0px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Please ensure you fill all fields
              </div>
            )}
            {errorMarch.error && (
              <div
                style={{
                  width: '100%',
                  color: 'red',
                  fontSize: '12px',
                  margin: '5px 0px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {errorMarch.error.message}
              </div>
            )}
          </div>
        </Row>
      </StyledInputField>
    );
  };
  const handleExit = () => {
    // onClose();
    // setSuccessModal(false);
    window.location.reload();
  };
  const ShowSuccessModal = () => {
    // onClose()
    return (
      <StyledSuccess>
        <Icon name="success" height="80px" />
        <div>Profile Successfully Created</div>
        <div
          style={{
            color: '#17568B',
            fontSize: '16px',
            cursor: 'pointer',
          }}
          onClick={handleExit}
        >
          View Merchants
        </div>
      </StyledSuccess>
    );
  };
  const closeSuccessModal = () => {
    // onClose();
    // setSuccessModal(false);
    window.location.reload();
  };
  return (
    <>
      {!successModal ? (
        <Modal
          size="lg"
          show={show}
          onClose={() => {
            resetMerchantForm();
            setTimeout(() => {
              onClose();
            }, 500);
          }}
          goBack={goback}
          title={modalCtr.header}
        >
          <ShowMerchantForm />
        </Modal>
      ) : (
        <Modal show={successModal} onClose={closeSuccessModal}>
          <ShowSuccessModal />
        </Modal>
      )}
    </>
  );
};

export default CreateMerchant;
