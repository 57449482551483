import { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ReactComponent as Caretdownsm } from '../../assets/icons/caret-down-sm.svg';
// import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg';
import DateInput from '../DateInput/DateInput';
import Menu from './Menu';
import Button from '../Button';
import { getDate } from '../../utilities/helper';
import { Input } from '../inputs';

const Filter = ({ action, filter, options, filterData, clearFilter, setFilterData }) => {
  const [filterOptions, setOptions] = useState();
  const [hasFiltered, setHasFiltered] = useState(false);
  const [menuCtr, setMenuCtr] = useState({
    anchor: null,
    show: null,
  });
  const [showDate, setShowDate] = useState(false);

  useEffect(() => {
    const computedFilter = Object.keys(filter).map((k) => ({
      name: k,
      options: filter[k],
      value: k,
      label: k,
    }));
    setOptions(computedFilter);
  }, []);

  const handleClear = () => {
    const computedFilter = Object.keys(filter).map((k) => ({
      name: k,
      options: filter[k],
      value: k,
      label: k,
    }));
    setOptions(computedFilter);

    clearFilter();
  };

  const handleMenu = (e, name) => {
    const temp = {
      anchor: e.currentTarget,
      show: name,
    };
    setMenuCtr(temp);
  };

  const handleCloseMenu = () => {
    setMenuCtr({
      anchor: null,
      show: null,
    });
  };

  const handleOptChange = (value, name, label) => {
    const temp = filterOptions.map((f) => {
      if (f.name === name) {
        return {
          ...f,
          value,
          label,
        };
      }
      return { ...f };
    });
    setOptions(temp);
    if (name === 'Date' && value !== 'Period') {
      const newDate = getDate(value);
      setFilterData((d) => ({
        ...d,
        from: newDate?.toISOString(),
        to: moment().toISOString(),
      }));
    } else if (value !== 'Period' && name !== value) {
      setFilterData((f) => ({ ...f, [name]: value }));
    }
    if (value === 'Period') {
      setShowDate(true);
    } else {
      setShowDate(false);
    }
    handleCloseMenu();
  };

  const handleChange = ({ name, value }) => {
    if (
      (name === 'search' || name === 'name' || name === 'keyword' || name === 'serviceName') &&
      value !== undefined
    ) {
      setFilterData((f) => ({ ...f, [name]: value }));
    } else if (name === 'from' || name === 'to') {
      setFilterData((f) => ({ ...f, [name]: value?.toISOString() }));
    }
    if (name === 'transactionId' && value !== undefined) {
      setFilterData((f) => ({ ...f, [name]: value }));
    }
  };
  const handleFilterTable = () => {
    action();
    setHasFiltered(true);
  };

  return (
    <Filter.Wrapper>
      <div className="filter-wrapper">
        <div className="filter">
          <div className="title">Filter</div>
          {filterOptions?.map((f, idx) => {
            switch (f.name) {
              case 'Date':
                return (
                  <>
                    <div key={idx} className="filterType">
                      <div className="date" onClick={(e) => handleMenu(e, 'date')}>
                        <div>{f.value}</div>
                        {/* <Calendar /> */}
                      </div>
                      <div className="caret">
                        <Caretdownsm width={15} />
                      </div>
                      <Menu
                        name={f.name}
                        open={menuCtr.show === 'date'}
                        handleClose={handleCloseMenu}
                        anchor={menuCtr.anchor}
                        handleChange={handleOptChange}
                        options={f.options}
                      />
                    </div>
                    {showDate && (
                      <>
                        <div className="filterType d-flex align-items-center">
                          <div className="mr-2">From:</div>
                          <DateInput
                            disableToolbar
                            name="from"
                            type="date"
                            id="outlined"
                            format="DD MMM YYYY"
                            onChange={handleChange}
                            maxDate={moment()}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </div>
                        <div className="filterType  d-flex align-items-center">
                          <div className="mr-2">To:</div>
                          <DateInput
                            disableToolbar
                            name="to"
                            type="date"
                            onChange={handleChange}
                            id="outlined"
                            format="DD MMM YYYY"
                            maxDate={moment()}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'campaignType':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'campaignType')}>
                            {f.label === 'campaignType' ? 'Bulk Type' : f.label}
                          </div>
                          <div className="caret">
                            <Caretdownsm width={15} />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'campaignType'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'country':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'country')}>
                            {f.value}
                          </div>
                          <div className="caret">
                            <Caretdownsm width={15} />
                          </div>
                          <Menu
                            name="countryName"
                            open={menuCtr.show === 'country'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'countryName':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'countryName')}>
                            {f.label}
                          </div>
                          <div className="caret">
                            <Caretdownsm width={15} />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'countryName'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'countryCode':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'countryCode')}>
                            {f.label}
                          </div>
                          <div className="caret">
                            <Caretdownsm width={15} />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'countryCode'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'activityType':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'activityType')}>
                            {f.value === 'activityType' ? 'Activity Type' : f.value}
                          </div>
                          <div className="caret">
                            <Caretdownsm width={15} />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'activityType'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'serviceType':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'serviceType')}>
                            {f.value === 'serviceType' ? 'Service Type' : f.value}
                          </div>
                          <div className="caret">
                            <Caretdownsm width={15} />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'serviceType'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'status':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'status')}>
                            {f?.label}
                          </div>
                          <div className="caret">
                            <Caretdownsm width={15} />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'status'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'category':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'category')}>
                            {f.value}
                          </div>
                          <div className="caret">
                            <Caretdownsm width={15} />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'category'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'limit':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'limit')}>
                            {f.value}
                          </div>
                          <div className="caret">
                            <Caretdownsm width={15} />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'limit'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'search':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div className="filterType">
                          <Input
                            name="search"
                            type="text"
                            placeholder="search by name"
                            icon="search"
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'name':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div className="filterType">
                          <Input
                            name="name"
                            type="text"
                            placeholder="search by name"
                            icon="search"
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'serviceName':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div className="filterType">
                          <Input
                            key={filterData?.serviceName}
                            name="serviceName"
                            type="text"
                            placeholder="search by service type"
                            defaultValue={filterData?.serviceName}
                            icon="search"
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'keyword':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div className="filterType">
                          <Input
                            key={filterData?.keyword}
                            name="keyword"
                            type="text"
                            placeholder="search by keyword"
                            value={filterData?.keyword}
                            icon="search"
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'transactionId':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div className="filterType">
                          <Input
                            name="transactionId"
                            type="text"
                            placeholder="search by id"
                            icon="search"
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              case 'service':
                return (
                  <>
                    {!showDate && (
                      <>
                        <div key={idx} className="filterType">
                          <div className="activity" onClick={(e) => handleMenu(e, 'service')}>
                            {f.value === 'service' ? 'Service' : f.value}
                          </div>
                          <div className="caret">
                            <Caretdownsm width={15} />
                          </div>
                          <Menu
                            name={f.name}
                            open={menuCtr.show === 'service'}
                            handleClose={handleCloseMenu}
                            anchor={menuCtr.anchor}
                            handleChange={handleOptChange}
                            options={f.options}
                          />
                        </div>
                      </>
                    )}
                  </>
                );
              default:
                return null;
            }
          })}
          <div className="filterType filterButton  d-flex">
            <Button value="Apply" size="lg" onClick={handleFilterTable} />
            {hasFiltered && (
              <div className="ml-2">
                <Button
                  value="Clear"
                  size="lg"
                  onClick={() => {
                    handleClear();
                    setHasFiltered(false);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Filter.Wrapper>
  );
};

export default Filter;

Filter.Wrapper = styled.div`
  .filter-wrapper {
    background-color: white;
    padding: 10px;
    width: 100%;
    color: #505050;
    .filter {
      font-size: 16px;
      align-items: center;
      align-content: center;
      width: 60vw;
      display: flex;
      gap: 10px;
      .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
        padding-top: 0;
      }
      .MuiTextField-root.MuiFormControl-marginNormal {
        margin-top: 10px;
      }
      .MuiSelect-iconOutlined {
        right: 7px;
        display: none !important;
      }
      .filterButton {
        button {
          width: 100px;
        }
      }
      .filterType {
        width: 100%;
        position: relative;
        .caret {
          position: absolute;
          right: 2px;
          top: 12px;
        }
        .dwpXEl {
          margin: 0px 5px !important;
        }
        .fJWLSK {
          margin-bottom: 0;
        }
      }
      .filterDate {
        display: flex;
      }
      .title {
        width: fit-content;
      }
    }
    div {
      padding-bottom: 0;
    }
    .date {
      padding: 13px;
      border: 1px solid #bdbdbd;
      font-size: 12px;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
    .activity {
      padding: 15px;
      border: 1px solid #bdbdbd;
      font-size: 12px;
      border-radius: 6px;
    }
    @media (min-width: 320px) and (max-width: 720px) {
      .filter {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
      }
      .filterType {
        margin-bottom: 10px;
      }
      .filterType button {
        width: 100% !important;
      }
    }
  }
`;
