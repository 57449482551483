import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { useState, useCallback, useEffect } from 'react';

// Components
import Card from '../Card';
import Icon from '../Icon';

// Utils
import { APIUserReport } from '../../api/backendRoutes';

const StyledData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .v-divide {
    height: 36px;
    border-right: 1px solid #e0e0e0;
    margin: 0 30px;
  }

  > div {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;
    width: 68px;
    border-radius: 7px;
    margin-right: 20px;

    &--blue {
      background: #17568b;
    }

    &--lightblue {
      background: #509fef;
    }

    &--vlightblue {
      background: #509fef80;
    }
  }

  .muted {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #aaaaaa;
  }

  .count {
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    display: flex;
    align-items: center;
    color: #505050;
  }
`;

const UserReport = () => {
  const [userReport, setUserReport] = useState();

  const getCount = (field) => {
    return (
      userReport.find((report) => report?.accountTypeName?.toLowerCase() === field.toLowerCase())
        ?.count || 0
    );
  };

  const getUserReport = useCallback(async () => {
    try {
      const response = await APIUserReport();

      if (response.data.message === 'ok' || response.data.message === 'successful') {
        setUserReport(response.data.data);
      } else {
        throw response?.data?.error;
      }
    } catch (error) {
      /* eslint-disable-no-empty */
    }
  }, []);

  useEffect(() => {
    getUserReport();

    return () => {
      setUserReport();
    };
  }, []);

  return (
    <>
      <Col md={12}>
        {userReport && (
          <Card>
            <StyledData>
              <div>
                <div className="box box--blue">
                  <Icon name="briefcase" width={30} color="#ffffff" />
                </div>
                <div>
                  <div className="muted">Total Clients</div>
                  <div className="count">
                    <NumberFormat thousandSeparator value={getCount("Merchant")} displayType="text" />
                  </div>
                </div>
              </div>
              <span className="v-divide" />
              <div>
                <div className="box box--lightblue">
                  <Icon name="shop" width={30} color="#ffffff" />
                </div>
                <div>
                  <div className="muted">Total Agents</div>
                  <div className="count">
                    <NumberFormat thousandSeparator value={getCount("Agents")} displayType="text" />
                  </div>
                </div>
              </div>
              <span className="v-divide" />
              <div>
                <div className="box box--vlightblue">
                  <Icon name="group" width={30} color="#ffffff" />
                </div>
                <div>
                  <div className="muted">Total Partners</div>
                  <div className="count">
                    <NumberFormat thousandSeparator value={getCount("Partner")} displayType="text" />
                  </div>
                </div>
              </div>
            </StyledData>
          </Card>
        )}
      </Col>
    </>
  );
};

export default UserReport;
