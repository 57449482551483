import { useHistory } from 'react-router-dom';
import Button from '../../../../../components/Button';

const UpgradeProviderBtn = ({ provider }) => {
  const { push } = useHistory();
  return (
    <>
      <Button
        group="secondary round"
        width={150}
        value="Become a partner"
        onClick={() =>
          push({
            pathname: `/admin/providers/${provider.id}/upgrade`,
          })
        }
      />
    </>
  );
};

export default UpgradeProviderBtn;
