import { useEffect, useState } from 'react';
import Icon from '../../../components/Icon';
import Table from '../../../components/Table';
// import Button from '../../../components/Button';
import { APIMerchantTeam } from '../../../api/backendRoutes';

const Users = ({ showTeamDetail, setTeamMate, merchant }) => {
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState([]);
  const singleMerchant = merchant[0];

  useEffect(async () => {
    const resp = await APIMerchantTeam({
      merchantOrganizationId: singleMerchant.merchantOrganizationId,
    });
    if (resp.data.message) {
      setLoading(false);
      setTeam(resp.data.data);
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <Table
      header={[
        {
          key: 'name',
          label: 'Name',
          sort: true,
          formatter: (name, { email }) => (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                <div>
                  <div>{name}</div>
                  <div className="text-muted">{email}</div>
                </div>
              </div>
            </>
          ),
        },
        { key: 'email', label: 'Email', sort: true },
        { key: 'country', label: 'Country', sort: true },
        { key: 'phone_number', label: 'Phone Number', sort: true },
        // {
        //   key: '',
        //   label: '',
        //   formatter: (name) => (
        //     <div style={{ display: 'flex', alignItems: 'center' }}>
        //       <Button
        //         value="Details"
        //         group="secondary"
        //         size="sm"
        //         width={70}
        //         margin={[0, 10, 0, 0]}
        //         onClick={() => {
        //           showTeamDetail(true);
        //           setTeamMate(name);
        //         }}
        //       />
        //     </div>
        //   ),
        // },
      ]}
      data={team.map((d) => ({
        name: `${d.user?.firstName} ${d.user?.lastName}`,
        email: d.user?.email,
        country: d.user?.country,
        phone_number: d.user?.phoneNumber,
      }))}
      loading={loading}
      noBorder
    />
  );
};

export default Users;
