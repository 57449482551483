import { useState } from 'react';
import Toast from 'react-hot-toast';
import { Row, Col } from 'react-bootstrap';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// Components
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { Select, Input } from '../../../../components/inputs';

// Utils
import baseAPI from '../../../../api';
import { getProvider } from '../../../../api/provider';
import { errorParser } from '../../../../utilities/helper';
import { APIUpdateService } from '../../../../api/backendRoutes';

function UpdateServiceModal({ show, closeModal, data: Service, provider }) {
  const queryClient = useQueryClient();

  const [providerInfo, setProviderInfo] = useState({});
  const [payload, setPayload] = useState(
    Object.keys(Service?.payload || {}).map((entry) => {
      return { key: entry, value: Service?.payload[entry] };
    })
  );
  const [header, setHeader] = useState(
    Object.keys(Service?.headers || {}).map((entry) => {
      return { key: entry, value: Service?.headers[entry] };
    })
  );
  const [serviceData, setServiceData] = useState({
    serviceName: '',
    apiEndpoint: '',
    ...Service,
    serviceType: Service?.serviceType?._id,
  });
  const [err, setErr] = useState([false, '']);

  useQuery(
    ['providers', provider?.id],
    async () => {
      const response = await baseAPI.get(getProvider(provider?.id));
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data?.success) {
          setProviderInfo(data?.data);
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const addPayload = () => {
    const newPayload = [...payload];
    newPayload.push({ key: '', value: '' });
    setPayload(newPayload);
  };
  const addHeader = () => {
    const newHeader = [...header];
    newHeader.push({ key: '', value: '' });
    setHeader(newHeader);
  };
  const showPayload = () => {
    const handlePayloadChange = ({ name, value, idx }) => {
      const newPayload = [...payload];
      const update = newPayload[idx];
      update[name] = value;
      newPayload[idx] = update;
      setPayload(newPayload);
    };
    return payload.map((load, idx) => (
      <Row key={idx}>
        <Col md={6}>
          <Input
            label="Key"
            placeholder="Enter Payload Key"
            name="key"
            type="text"
            defaultValue={payload[idx].key}
            validate="required"
            onChange={handlePayloadChange}
            idx={idx}
          />
        </Col>
        <Col md={6}>
          <Select
            label="payload"
            placeholder="Enter Payload Type"
            name="value"
            type="text"
            defaultValue={payload[idx].value}
            validate="required"
            onChange={handlePayloadChange}
            idx={idx}
            options={[
              { value: 'String', label: 'String' },
              { value: 'Number', label: 'Number' },
              { value: 'api', label: 'API' },
            ]}
          />
        </Col>
      </Row>
    ));
  };
  const showHeader = () => {
    const handleHeaderChange = ({ name, value, idx }) => {
      const newheader = [...header];
      const update = newheader[idx];
      update[name] = value;
      newheader[idx] = update;
      setHeader(newheader);
    };
    return header.map((head, idx) => (
      <Row key={idx}>
        <Col md={6}>
          <Input
            label="Header"
            placeholder="Enter Header"
            name="key"
            type="text"
            validate="required"
            value={header[idx].key}
            onChange={handleHeaderChange}
            idx={idx}
          />
        </Col>
        <Col md={6}>
          <Input
            label="value"
            placeholder="Enter Header Value"
            name="value"
            type="text"
            validate="required"
            value={header[idx].value}
            onChange={handleHeaderChange}
            idx={idx}
          />
        </Col>
      </Row>
    ));
  };
  const handleInputChange = ({ name, value }) => {
    setServiceData({
      ...serviceData,
      [name]: value,
    });
  };

  const { mutate, isLoading } = useMutation(
    async (obj) => {
      const response = await APIUpdateService(Service?._id, obj);

      return response;
    },
    {
      onSuccess: ({ data }) => {
        if (data.success) {
          queryClient.invalidateQueries(['services']);
          Toast.success('Service Updated');
          setServiceData({
            serviceName: '',
            apiEndpoint: '',
            serviceType: '',
            method: '',
            price: '',
          });
          closeModal();
          setPayload([{ key: '', value: '' }]);
          setHeader([{ key: '', value: '' }]);
        } else Toast.error("Error Can't Update Service");
      },
      onError: (error) => Toast.error(errorParser(error)),
    }
  );

  const handleAddService = async () => {
    const computedData = serviceData;

    delete computedData?._id;
    delete computedData?.__v;
    delete computedData?.deleted;
    delete computedData?.isActive;
    delete computedData?.companyId;
    delete computedData?.createdAt;
    delete computedData?.deletedAt;
    delete computedData?.updatedAt;
    delete computedData?.createdBy;
    delete computedData?.costModel;
    delete computedData?.priceModel;
    delete computedData?.serviceType;
    delete computedData?.encryptionKey;
    delete computedData?.serviceOwnerId;
    delete computedData?.encryptionAlgorithm;

    if (Object.values(computedData).includes('')) {
      setErr([true, 'Please fill all fields']);
      return;
    }
    
    header.forEach((data) => {
      computedData.headers = {
        ...computedData.headers,
        [data.key]: data.value,
      };
    });

    if (Object.values(computedData.headers).includes('')) {
      setErr([true, 'Please fill all fields']);
      return;
    }

    payload.forEach((data) => {
      computedData.payload = {
        ...computedData.payload,
        [data.key]: data.value,
      };
    });

    if (Object.values(computedData.payload).includes('')) {
      setErr([true, 'Please fill all fields']);
      return;
    }

    if (computedData?.pricingType === 'Discount' && computedData?.pricingValue > 100) {
      setErr([true, "Pricing Value can't be more than 100 if discount has been selected"]);
      return;
    }

    const newServicedata = {
      ...computedData,
      providerName: providerInfo.firstName,
      validationAPIMethod: serviceData.method,
      sampleResponse: {},
    };

    delete newServicedata?.pricingType;
    delete newServicedata?.pricingValue;

    mutate(newServicedata);
  };
  return (
    <Modal size="lg" show={show} onClose={closeModal} title="Update Service">
      <div style={{ padding: '20px' }}>
        <Row>
          <Col md={8}>
            <Input
              label="Service Name"
              placeholder="Enter Service Name"
              name="serviceName"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={serviceData.serviceName}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Select
              label="Method"
              placeholder="Enter Service Method"
              name="method"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={serviceData.method}
              options={[
                { label: 'POST', value: 'POST' },
                { label: 'GET', value: 'GET' },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Input
              label="Api End Point"
              placeholder="Enter End Point"
              name="apiEndpoint"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={serviceData.apiEndpoint}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Input
              label="Price"
              placeholder="Enter Service Price"
              name="price"
              type="number"
              validate="required"
              onChange={handleInputChange}
              defaultValue={serviceData.price}
            />
          </Col>
        </Row>

        <Row>
          <Col md={8}>{showHeader()}</Col>
          <Col
            md={2}
            style={{
              alignSelf: 'center',
            }}
          >
            <Button value="Add Header" group="secondary" onClick={() => addHeader()} />
          </Col>
        </Row>
        <Row>
          <Col md={8}>{showPayload()}</Col>
          <Col
            md={2}
            style={{
              alignSelf: 'center',
            }}
          >
            <Button value="Add Payload" group="secondary" onClick={() => addPayload()} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button value="Update Service" onClick={() => handleAddService()} loading={isLoading} />
          </Col>
        </Row>
        {err && (
          <div
            style={{
              color: 'red',
              fontSize: '12px',
            }}
          >
            {err[1]}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default UpdateServiceModal;
