// import { useState } from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { APIchangePassword } from '../../../api/backendRoutes';
import Button from '../../../components/Button';
import { PasswordInput } from '../../../components/inputs';
import { ROUTES } from '../../../components/router/routes/routes';
import { errorParser } from '../../../utilities/helper';

const StyledPassword = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 30px;
`;
const Password = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
    fetching: false,
    error: false,
    errorMsg: '',
  });
  const handleInputChange = ({ name, value }) => {
    // console.log({ name, value });
    setFormData((v) => ({ ...v, [name]: value }));
  };
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const { oldPassword, password, confirmPassword } = formData;
    if (password === confirmPassword) {
      setFormData((d) => ({ ...d, fetching: true }));
      try {
        const resp = await APIchangePassword({
          oldpassword: oldPassword,
          password,
          confirmPassword,
        });
        if (resp.data.message === 'ok') {
          toast.success('Passwords changed');
          setFormData((d) => ({ ...d, fetching: false }));
          localStorage.clear();
          history.push(ROUTES.SIGN_IN.path);
        } else {
          setFormData((d) => ({
            ...d,
            fetching: false,
            error: true,
            errorMsg: resp?.data?.error?.data?.error,
          }));
          throw resp?.data?.error;
        }
      } catch (err) {
        setFormData((d) => ({ ...d, fetching: false }));
        toast.error(errorParser(err) || 'Error changing password');
      }
    } else {
      setFormData((d) => ({ ...d, error: 'Passwords do not match' }));
      toast.error('Passwords do not match');
    }
  };
  return (
    <StyledPassword>
      <Row>
        <Col>
          <div>Change Password</div>
        </Col>
      </Row>
      <form onSubmit={handlePasswordChange}>
        <Row>
          <Col md={6}>
            <PasswordInput
              label="Current Password"
              name="oldPassword"
              onChange={handleInputChange}
              defaultValue={formData.oldPassword}
              validate="required"
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <PasswordInput
              label="New Password"
              name="password"
              validate="required"
              onChange={handleInputChange}
              value={formData.password}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <PasswordInput
              label="confirm Password"
              name="confirmPassword"
              validate="required"
              onChange={handleInputChange}
              value={formData.confirmPassword}
            />
          </Col>
        </Row>
        {/* {state.fetchingUser ? (
          <></>
        ) : (
          <Row>
            <Col md={6}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: '20px 0px',
                }}
              >
                <div> Two factor Authentication</div>
                <Switch name="twofa" value={user['2FA']} onChange={update2FA} />
              </div>
            </Col>
          </Row>
        )} */}
        <Row>
          <Col className="btn-align-left">
            <Button value="Save changes" type="submit" loading={formData.fetching} />
          </Col>
        </Row>
        {formData.error && (
          <div
            style={{
              fontSize: '12px',
              color: 'red',
              margin: '10px 0px',
            }}
          >
            {formData.errorMsg}
          </div>
        )}
      </form>
    </StyledPassword>
  );
};
export default Password;
