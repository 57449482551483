import { Fade, Menu as Mmenu, MenuItem } from '@material-ui/core';

const Menu = ({
  open = false,
  handleClose,
  anchor,
  name,
  meta,
  handleChange,
  options = [
    { value: 'name', label: 'name' },
    { value: 'name', label: 'name' },
    { value: 'name', label: 'name' },
  ],
  icon,
}) => {
  return (
    <div>
      <Mmenu
        id="simple-menu"
        open={open}
        onClose={() => handleClose()}
        anchorEl={anchor}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            boxShadow: '0px 0px 3px rgb(222 215 215)',
            marginTop: '40px',
            marginRight: '50px',
          },
        }}
      >
        {options.map((opt, idx) => (
          <MenuItem onClick={() => handleChange(opt.value, name, opt.label)} key={idx}>
            {icon && (
              <>
                <div style={{ margin: '0px 5px', fontWeight: 'lighter' }}>{opt.label}</div>
              </>
            )}
            {!icon && <div style={{ margin: '0px 5px', fontWeight: 'lighter' }}>{opt.label}</div>}
          </MenuItem>
        ))}
      </Mmenu>
    </div>
  );
};

export default Menu;
