import { useState } from 'react';
import styled from 'styled-components';
import MuiPhoneNumber from 'material-ui-phone-number';
import {
  Switch as MSwitch,
  TextField,
  Select as MSelect,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  InputAdornment,
  Checkbox as MCheckbox,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core';
import { ColorPicker as MColorPicker } from 'material-ui-color';

import {
  Visibility,
  VisibilityOff,
  CheckBox as ICheckBox,
  RadioButtonChecked,
  CheckBoxOutlineBlank,
  RadioButtonUnchecked,
} from '@material-ui/icons';
import { validator } from '../../utilities/helper';

const StyledInput = styled.div`
  padding-bottom: ${({ padding }) => (padding !== undefined ? padding : '20px')};
  font-weight: 300;

  label {
    font-size: 12px;
    font-weight: 300;
    padding-left: 3px;
    margin-top: 2px !important;
  }

  ul li {
    font-size: 12px;
    font-weight: 300;
  }

  input,
  select {
    height: ${({ size }) => (size === 'tiny' ? '30px' : '40px')};
  }

  textarea {
  }

  input,
  select,
  textarea {
    border-radius: ${({ size }) => (size === 'tiny' ? '5px' : '5px')};
    font-weight: 300;
    cursor: pointer;
  }

  .MuiOutlinedInput-root {
    min-height: ${({ size }) => (size === 'tiny' ? '30px' : '40px')};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #cccccc !important;
  }

  fieldset {
    border: 1px solid #cccccc;
    border-radius: ${({ size }) => (size === 'tiny' ? '5px' : '5px')};
  }

  > div {
    width: 100%;
  }

  .MuiSelect-select {
    box-sizing: border-box;
    height: ${({ size }) => (size === 'tiny' ? '30px' : '40px')};

    &:focus {
      border-radius: ${({ size }) => (size === 'tiny' ? '5px' : '10px')};
    }
  }

  .MuiInputBase-root.color-white {
    .MuiInputBase-input {
      color: #ffffff;
    }
  }

  .MuiOutlinedInput-input {
    padding: ${({ size }) => (size === 'tiny' ? '7.5px 8px' : '11.5px 14px')};
  }

  .MuiSelect-iconOutlined {
    right: ${({ size }) => (size === 'tiny' ? '0' : '4px')};
  }

  .MuiInputBase-input {
    font-size: 13px;
  }

  .MuiMenu-list {
    font-size: 12px !important;
  }

  .MuiOutlinedInput-multiline {
    height: auto !important;
  }

  .MuiFormControlLabel-root {
    align-items: flex-start;
    span {
      font-size: 12px;
    }
  }

  .MuiCheckbox-root {
    padding: 0;
    margin-right: 10px;

    .MuiSvgIcon-root {
      // color: #4185e9 !important;
      color: #bbbbbb !important;
    }
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: 5px !important;
  }

  .MuiOutlinedInput-inputAdornedStart {
    padding-left: 0 !important;
  }
`;

const StyledHint = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 14px;
`;

const Input = ({
  label,
  placeholder = '',
  disabled = false,
  type = 'text',
  variant = 'outlined',
  hint,
  value,
  name,
  size = 'small',
  defaultValue,
  multiline = false,
  rows = 3,
  onChange,
  validate,
  padding,
  handleError,
  idx,
  ...rest
}) => {
  const [errors, setErrors] = useState([]);
  // const [data, setData] = useState(value || defaultValue);

  const handleValidation = (e) => {
    const { value: v } = e.target;
    setErrors(validate ? validator(v, validate) : []);
    if (handleError) {
      handleError(validate ? validator(v, validate) : []);
    }
  };

  const handleOnChange = (e) => {
    const { name: n, value: v } = e.target;
    // setData(v);
    if (onChange) {
      onChange({ name: n, value: v, idx });
    }
    if (handleError) {
      handleError(errors);
    }
  };

  return (
    <StyledInput padding={padding}>
      <TextField
        size={size}
        name={name}
        defaultValue={defaultValue}
        label={label}
        type={type}
        placeholder={placeholder || label}
        disabled={disabled}
        variant={variant}
        aria-label={label}
        aria-describedby={label}
        multiline={multiline}
        rows={rows}
        value={value || defaultValue}
        onChange={handleOnChange}
        onBlur={handleValidation}
        error={errors.length > 0}
        helperText={errors.length > 0 ? errors[0] : ''}
        required={!!validate}
        {...rest}
      />
      {hint && <StyledHint>{hint}</StyledHint>}
    </StyledInput>
  );
};

const PasswordInput = ({
  label,
  placeholder = '',
  disabled = false,
  variant = 'outlined',
  size = 'small',
  hint,
  value,
  name,
  defaultValue,
  onChange,
  validate,
  padding,
  ...rest
}) => {
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState(value || defaultValue);
  const [showPassword, setShowPassword] = useState(false);

  const handleValidation = (e) => {
    const { value: v } = e.target;
    setErrors(validate ? validator(v, validate) : []);
  };

  const handleOnChange = (e) => {
    const { name: n, value: v } = e.target;
    setData(v);
    if (onChange) {
      onChange({ name: n, value: v });
    }
  };

  const onShowPassword = () => {
    setShowPassword((t) => !t);
  };

  return (
    <StyledInput padding={padding}>
      <TextField
        name={name}
        defaultValue={defaultValue}
        label={label}
        size={size}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder || label}
        disabled={disabled}
        variant={variant}
        aria-label={label}
        aria-describedby={label}
        value={data}
        onChange={handleOnChange}
        onBlur={handleValidation}
        error={errors.length > 0}
        helperText={errors.length > 0 ? errors[0] : ''}
        required={!!validate}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={onShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
      {hint && <StyledHint>{hint}</StyledHint>}
    </StyledInput>
  );
};

const Select = ({
  label,
  placeholder = '',
  disabled = false,
  variant = 'outlined',
  hint,
  value = '',
  size = 'small',
  name,
  validate,
  onChange,
  defaultValue,
  handleError,
  idx,
  options = [
    { value: 'a', label: 'a' },
    { value: 'b', label: 'b' },
    { value: 'c', label: 'c' },
  ],
  styleContainer = {},
  padding,
  style,
  ...rest
}) => {
  const [errors, setErrors] = useState([]);
  // const [data, setData] = useState(value || defaultValue);

  const handleValidation = (e) => {
    const { value: v } = e.target;
    setErrors(validate ? validator(v, validate) : []);
    if (handleError) {
      handleError(validate ? validator(v, validate) : []);
    }
  };

  const handleOnChange = (e) => {
    const { name: n, value: v } = e.target;
    // setData(v);
    if (onChange) {
      onChange({ name: n, value: v, idx });
    }
  };

  return (
    <StyledInput padding={padding} style={styleContainer} size={size}>
      <FormControl
        defaultValue={defaultValue}
        variant={variant}
        aria-label={label}
        aria-describedby={label}
        size={size}
      >
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
        <MSelect
          name={name}
          placeholder={placeholder ?? label}
          disabled={disabled}
          id={name}
          label={label}
          value={value || defaultValue}
          onChange={handleOnChange}
          onBlur={handleValidation}
          error={errors.length > 0}
          // helperText={errors.length > 0 ? errors[0] : ''}
          required={!!validate}
          style={{ minWidth: 100, ...style }}
          {...rest}
        >
          {options.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.label}
            </MenuItem>
          ))}
        </MSelect>
        {errors.length > 0 && (
          <FormHelperText style={{ color: '#f44336' }}>
            {errors.length > 0 ? errors[0] : ''}
          </FormHelperText>
        )}
      </FormControl>
      {hint && <StyledHint>{hint}</StyledHint>}
    </StyledInput>
  );
};

const Checkbox = ({
  label,
  disabled = false,
  variant = 'outlined',
  hint,
  value,
  name,
  defaultValue,
  onChange,
  validate,
  radio = true,
  padding = 0,
  color = 'primary',
  ...rest
}) => {
  const handleOnChange = (e) => {
    const { name: n, checked: v } = e.target;
    if (onChange) {
      onChange({ name: n, value: v });
    }
  };

  return (
    <StyledInput padding={padding} style={{ padding: 0 }} {...rest}>
      <FormControlLabel
        control={
          <MCheckbox
            color={color}
            checked={value}
            onChange={handleOnChange}
            name={name}
            icon={radio ? <RadioButtonUnchecked /> : <CheckBoxOutlineBlank />}
            checkedIcon={radio ? <RadioButtonChecked /> : <ICheckBox />}
            required={!!validate}
          />
        }
        disabled={disabled}
        label={label}
        aria-label={label}
        aria-describedby={label}
        style={{ margin: 0 }}
      />
    </StyledInput>
  );
};

const PhoneInput = ({
  label,
  placeholder = '',
  disabled = false,
  type = 'text',
  variant = 'outlined',
  hint,
  value,
  name,
  size = 'small',
  defaultValue,
  multiline = false,
  rows = 3,
  onChange,
  validate,
  accept = '',
  defaultCountry = 'ng',
  padding,
  idx,
  handleError,
  ...rest
}) => {
  const [data, setData] = useState(value || defaultValue);
  const [errors, setErrors] = useState([]);

  const handleValidation = (e) => {
    const { value: v } = e.target;
    setErrors(validate ? validator(v, validate) : []);
    if (handleError) {
      handleError(validate ? validator(v, validate) : []);
    }
  };

  const handleOnChange = (v, { dialCode }) => {
    setData(v);

    if (onChange) {
      onChange({ name, value: `+${dialCode} ${v.replace(`+${dialCode}`, '')}`, idx });
    }
  };

  return (
    <StyledInput padding={padding}>
      <MuiPhoneNumber
        defaultCountry={defaultCountry}
        countryCodeEditable={false}
        enableSearch
        size={size}
        name={name}
        defaultValue={defaultValue}
        label={label}
        type={type}
        placeholder={placeholder || label}
        disabled={disabled}
        variant={variant}
        aria-label={label}
        aria-describedby={label}
        multiline={multiline}
        rows={rows}
        value={data}
        autocomplete={false}
        onChange={handleOnChange}
        onBlur={handleValidation}
        error={errors.length > 0}
        helperText={errors.length > 0 ? errors[0] : ''}
        required={!!validate}
        inputProps={{ accept, autoComplete: 'none', minLength: 14, maxLength: 14 }}
        {...rest}
      />
      {hint && <StyledHint>{hint}</StyledHint>}
    </StyledInput>
  );
};

const Switch = ({
  label,
  disabled = false,
  variant = 'outlined',
  hint,
  value,
  name,
  defaultValue,
  onChange,
  validate,
  radio = true,
  padding = 0,
  color = 'secondary',
  ...rest
}) => {
  const handleOnChange = (e) => {
    const { name: n, checked: v } = e.target;
    if (onChange) {
      onChange({ name: n, value: v });
    }
  };

  return (
    <StyledInput padding={padding} style={{ padding: 0 }} {...rest}>
      <MSwitch
        disabled={disabled}
        inputProps={{ 'aria-label': label, 'aria-describedby': label }}
        style={{ margin: 0 }}
        color={color}
        checked={value}
        onChange={handleOnChange}
        name={name}
        required={!!validate}
      />
    </StyledInput>
  );
};

const ColorPicker = ({
  label,
  disabled = false,
  variant = 'outlined',
  hint,
  value,
  name,
  defaultValue = 'transparent',
  onChange,
  validate,
  radio = true,
  padding = 0,
  ...rest
}) => {
  const [color, setColor] = useState(defaultValue);
  const handleOnChange = (c) => {
    if (onChange) {
      onChange({ name, value: c });
      setColor(c);
    }
  };

  return (
    <StyledInput padding={padding} style={{ padding: 0 }} {...rest}>
      <label htmlFor={name}>
        <span style={{ marginLeft: 6 }}>{label}</span>
        <MColorPicker onChange={handleOnChange} value={color} defaultValue={defaultValue} />
      </label>
    </StyledInput>
  );
};
const FormFields = (fields) => {
  // eslint-disable-next-line react/destructuring-assignment
  switch (fields.element) {
    case 'input':
      return <Input {...fields} />;
    case 'select':
      return <Select {...fields} />;
    case 'phoneInput':
      return <PhoneInput {...fields} />;
    default:
      return null;
  }
};

export { Input, PasswordInput, PhoneInput, Select, Checkbox, Switch, ColorPicker, FormFields };
