import { useState } from 'react';
import { useQuery } from 'react-query';

// Components
import { Select } from '../../inputs';

// Utils
import baseAPI from '../../../api';
import { getPartner } from '../../../api/partners';
import { APIlistProviderService } from '../../../api/backendRoutes';

const PartnerServiceSelect = ({
  label,
  placeholder,
  name,
  validate,
  defaultValue,
  onChange,
  partner,
  disabled,
}) => {
  const [options, setOptions] = useState([]);
  const [partnerInfo, setPartnerInfo] = useState();
  const [isEnabled, setIsEnabled] = useState(false);

  useQuery(
    ['partners', partner?.id],
    async () => {
      const response = await baseAPI.get(getPartner(partner?.id));
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data?.success) {
          setPartnerInfo(data?.data);
          setIsEnabled(true);
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    ['services', partner?.id],
    async () => {
      const response = await APIlistProviderService({
        params: {
          companyId: partnerInfo?.companyInformation?.[0]?.id,
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data?.data) {
          setOptions(
            data?.data?.map((service) => ({
              label: service?.serviceName,
              value: service?._id,
              ...service,
            }))
            /* eslint no-underscore-dangle: 0 */
          );
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEnabled,
    }
  );

  return (
    <Select
      label={label}
      placeholder={placeholder}
      name={name}
      validate={validate}
      defaultValue={defaultValue}
      onChange={(e) =>
        onChange(
          e,
          options.find((option) => option?.value === e?.value)
        )
      }
      options={options}
      disabled={disabled}
    />
  );
};

export default PartnerServiceSelect;
