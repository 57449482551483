/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fetchModels: {
    CostModel: {},
    PriceModel: {},
    error: false,
  },
  APIcreateProvider: {
    createdProvider: {},
    loading: false,
    error: false,
  },
};

export const merchantSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    fetchCostModelReducer: (state, { payload }) => {
      state.fetchModels.CostModel = payload.data;
    },
    fetchPriceModelReducer: (state, { payload }) => {
      state.fetchModels.PriceModel = payload.data;
    },
    errorfetchingCostModelReducer: (state) => {
      state.fetchModels.errorCost = true;
    },

    creatingProviderReducer: (state) => {
      state.APIcreateProvider.loading = true;
      state.APIcreateProvider.createdProvider = {};
      state.APIcreateProvider.error = false;
    },
    providerCreatedReducer: (state, { payload }) => {
      state.APIcreateProvider.loading = false;
      state.APIcreateProvider.createdProvider = payload.data;
      state.APIcreateProvider.error = false;
    },
    errorCreatingProviderReducer: (state, { payload }) => {
      state.APIcreateProvider.loading = false;
      state.APIcreateProvider.createdProvider = {};
      state.APIcreateProvider.error = true;
    },
  },
});
export const {
  fetchCostModelReducer,
  errorfetchingCostModelReducer,
  fetchPriceModelReducer,
  creatingProviderReducer,
  providerCreatedReducer,
  errorCreatingProviderReducer,
} = merchantSlice.actions;

export default merchantSlice.reducer;
