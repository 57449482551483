import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Input, Select } from '../../../../components/inputs';
import Button from '../../../../components/Button';

const StyledProfile = styled.div`
  width: 500px;
  padding: 20px;
`;

const Profile = () => {
  return (
    <StyledProfile>
      <Row>
        <Col>
          <Input
            label="Last Name"
            placeholder="Last Name"
            name="last_name"
            type="text"
            validate="required"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Last Name"
            placeholder="Last Name"
            name="last_name"
            type="text"
            validate="required"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            label="Last Name"
            placeholder="Last Name"
            name="last_name"
            type="text"
            validate="required"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            label="role"
            placeholder="Role"
            name="role"
            type="text"
            validate="required"
            options={[
              { key: 'Admin', label: 'Admin' },
              { key: 'operations', label: 'Operations' },
              { key: 'customer_Service', label: 'customer_Service' },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button value="Save Changes" />
        </Col>
      </Row>
    </StyledProfile>
  );
};

export default Profile;
