/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  transactions: [],
  fetchingTransactions: false,
  transactionsMeta: {},
};

export const reportSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    fetchAdminTransactionsSuccessReducer: (state, { payload }) => {
      state.transactions = payload.data;
      state.fetchingTransactions = false;
      state.transactionsMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingAdminTransactionsReducer: (state) => {
      state.fetchingTransactions = true;
      state.transactions = [];
      state.transactionsMeta = {};
    },
    fetchingAdminTransactionsErrorReducer: (state) => {
      state.fetchingTransactions = false;
      state.transactions = [];
      state.transactionsMeta = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchAdminTransactionsSuccessReducer,
  fetchingAdminTransactionsReducer,
  fetchingAdminTransactionsErrorReducer,
} = reportSlice.actions;

export default reportSlice.reducer;
