import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Table from '../../../components/Table';
import { Select } from '../../../components/inputs';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import Chart from '../../../components/Chart';

const data = [
  {
    transaction: 'Wallet Top-Up',
    email: 'johndoe@gmail.com',
    total_debit: 100000,
    transaction_id: 'qgD5XB160755',
    cost: -2000,
    date: 1610302993000,
  },
  {
    transaction: 'Wallet Top-Up',
    email: 'johndoe@gmail.com',
    total_debit: 100000,
    transaction_id: 'qgD5XB160755',
    cost: 60700,
    date: 1610302993000,
  },
  {
    transaction: 'Wallet Top-Up',
    email: 'johndoe@gmail.com',
    total_debit: 100000,
    transaction_id: 'qgD5XB160755',
    cost: -13000,
    date: 1610302993000,
  },
  {
    transaction: 'Wallet Top-Up',
    email: 'johndoe@gmail.com',
    total_debit: 100000,
    transaction_id: 'qgD5XB160755',
    cost: 2000,
    date: 1610302993000,
  },
  {
    transaction: 'John Doe',
    email: 'johndoe@gmail.com',
    total_debit: 100000,
    transaction_id: 'qgD5XB160755',
    cost: 2000,
    date: 1610302993000,
  },
  {
    transaction: 'Wallet Top-Up',
    email: 'johndoe@gmail.com',
    total_debit: 100000,
    transaction_id: 'qgD5XB160755',
    cost: -13000,
    date: 1610302993000,
  },
  {
    transaction: 'Wallet Top-Up',
    email: 'johndoe@gmail.com',
    total_debit: 100000,
    transaction_id: 'qgD5XB160755',
    cost: 2000,
    date: 1610302993000,
  },
];

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  color: #ffffff;
  padding: 30px 20px 10px 20px;
  font-size: 14px;
  font-weight: 600;
  background: #17568b;

  > div {
    display: flex;
    align-items: center;
  }

  h6 {
    background: #ffffff;
    color: #000000;
    border-radius: 5px;
    padding: 7px 20px;
    font-weight: 600;
    font-size: 24px;
    margin: 10px 0;
  }
`;

const SingleWallet = () => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 30,
        }}
      >
        <h5>John Doe</h5>
      </div>

      <Row style={{ marginBottom: 30 }}>
        <Col md={8}>
          <Card>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h4>Average Transactions</h4>
              <Select
                size="tiny"
                options={[
                  { label: 'Daily', value: 'daily' },
                  { label: 'Weekly', value: 'weekly' },
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'Yearly', value: 'yearly' },
                ]}
                defaultValue="monthly"
                style={{ minWidth: 80 }}
                padding={0}
              />
            </div>
            <div>
              <Chart bar />
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <div>
            <StyledCard className="blue-bg">
              <div>Total Wallet Balance:</div>
              <h6 style={{ color: '#555555' }}>N397,011,250.35</h6>
            </StyledCard>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
            <Button group="secondary" size="sm" value="Fund Wallet" />
            <Button group="secondary danger" size="sm" value="Debit Wallet" />
          </div>
          <div style={{ marginTop: 25 }}>
            <div style={{ fontWeight: 500, marginBottom: 5 }}>Linked Bank Info</div>
            <div
              style={{
                fontWeight: 300,
                border: '1px solid #E0E0E0',
                padding: '8px 13px',
                borderRadius: 10,
              }}
            >
              <div>Account Name - John Doe</div>
              <div style={{ marginTop: 10 }}>Account Number - 1102233145678</div>
              <div style={{ marginTop: 10 }}>Bank Name - United Bank for Africa</div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Table
            noBorder={false}
            header={[
              {
                key: 'transaction',
                label: 'Transaction Name',
                formatter: (transaction, { email, cost }) => (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon
                        name={cost > 0 ? 'increase' : 'decrease'}
                        margin={[0, 10, 0, 0]}
                        width={36}
                      />
                      <div>
                        <div style={{ fontWeight: 500 }}>{transaction}</div>
                        <div className="text-muted">{email}</div>
                      </div>
                    </div>
                  </>
                ),
              },
              {
                key: 'transaction_id',
                label: 'Transaction ID',
                sort: true,
              },
              {
                key: 'cost',
                label: 'Debit/Credit',
                sort: true,
                formatter: (cost) => (
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    prefix="₦"
                    value={cost}
                    className={cost > 0 ? 'green' : 'red'}
                  />
                ),
              },
              {
                key: 'date',
                label: 'Date',
                sort: true,
                formatter: (date) => (
                  <div style={{ display: 'flex' }}>
                    <Moment format="D MMM, YYYY - hh:mma" date={date} />
                  </div>
                ),
              },
              {
                key: 'menu',
                label: '',
                formatter: () => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon name="trashcircle" width={26} margin={[0, 0, 0, 10]} />
                  </div>
                ),
              },
            ]}
            data={data}
          />
        </Col>
      </Row>
    </>
  );
};

export default SingleWallet;
