import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import Table from '../../../components/Table';

const data = [
  {
    name: 'John Doe',
    email: 'johndoe@gmail.com',
    type: 'New Agent',
    status: 'Pending',
    country: 'Zimbabwe',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 20,
    date: 1610302993000,
  },
  {
    name: 'John Doe',
    email: 'johndoe@gmail.com',
    type: 'New Provider',
    status: 'Approved',
    country: 'Nigeria',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 1300,
    date: 1610302993000,
  },
  {
    name: 'John Doe',
    email: 'johndoe@gmail.com',
    type: 'Client Request',
    status: 'Pending',
    country: 'Zimbabwe',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 4.5,
    date: 1610302993000,
  },
  {
    name: 'John Doe',
    email: 'johndoe@gmail.com',
    type: 'Sender ID',
    status: 'Approved',
    country: 'Kenya',
    phone_number: '+234 9056831073',
    service: 'Data',
    cost: 210100,
    date: 1610302993000,
  },
  {
    name: 'John Doe',
    email: 'johndoe@gmail.com',
    type: 'Sender ID',
    status: 'Approved',
    country: 'Nigeria',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 300000,
    date: 1610302993000,
  },
  {
    name: 'John Doe',
    email: 'johndoe@gmail.com',
    type: 'Agent Request',
    status: 'Denied',
    country: 'Nigeria',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 20,
    date: 1610302993000,
  },
  {
    name: 'John Doe',
    email: 'johndoe@gmail.com',
    type: 'Client Request',
    status: 'Denied',
    country: 'Ghana',
    phone_number: '+234 9056831073',
    service: 'Airtime',
    cost: 20,
    date: 1610302993000,
  },
];

const Usage = () => {
  return (
    <Table
      header={[
        {
          key: 'service',
          label: 'Service',
          sort: true,
        },
        {
          key: 'cost',
          label: 'Amount Consumed',
          sort: true,
          formatter: (cost) => (
            <NumberFormat displayType="text" thousandSeparator prefix="₦" value={cost} />
          ),
        },
        {
          key: 'date',
          label: 'Date',
          sort: true,
          formatter: (date) => (
            <div style={{ display: 'flex' }}>
              <Moment format="D MMM, YYYY - hh:mma" date={date} />
            </div>
          ),
        },
      ]}
      data={data.filter((d) => d.status === 'Approved')}
      theadBg="#ffffff"
      noBorder
    />
  );
};

export default Usage;
