import { createContext, useEffect, useState } from 'react';
import { APIfetchAccountTypes } from '../../api/backendRoutes';
import { fetchUserData } from '../storage';

export const UserTypesContext = createContext({});

const UserTypesProvider = ({ children }) => {
  const [userTypes, setUserTypes] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const fetchAccountTypes = async () => {
    try {
      setDataLoading(true);
      const res = await APIfetchAccountTypes();
      setUserTypes(res.data?.data);
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
    }
  };

  // const APIfetchUserProfile = async () => {
  //   try {
  //     setFetchingUser(true);
  //     const res = await APIfetchUserProfile();
  //     setUser(res?.data?.data || {});
  //     setFetchingUser(false);
  //   } catch (error) {
  //     setFetchingUser(false);
  //   }
  // };

  useEffect(() => {
    fetchAccountTypes();
    // APIfetchUserProfile();
  }, []);

  const userData = fetchUserData();

  const value = {
    userTypes,
    setUserTypes,
    dataLoading,
    user: userData,
  };

  return <UserTypesContext.Provider value={value}>{children}</UserTypesContext.Provider>;
};

export default UserTypesProvider;
