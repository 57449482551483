import { atom } from 'recoil';
import { Link } from 'react-router-dom';
import Icon from '../components/Icon';
import LaptopImage from '../assets/images/authentication.svg';
import SecurityImage from '../assets/images/secure-lock.svg';
import countries from '../assets/data/countries.json';
import { ROUTES } from '../components/router/routes/routes';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
// export const BASE_URL = 'http://oldallmessagingapi-env.eba-r96rxjid.eu-west-1.elasticbeanstalk.com';

export const API_URL = {
  LOG_IN: `${BASE_URL}/auth/login`,
  SIGN_UP: `${BASE_URL}/clients/register`,
  RESEND_OTP: `${BASE_URL}/otp`,
  VERIFY_OTP: `${BASE_URL}/otp/verify`,
  VERIFY_ACCOUNT: `${BASE_URL}/auth/confirm-account`,
  CLIENTS: `${BASE_URL}/clients`,
  UPLOAD_AVATAR: `${BASE_URL}/clients/upload`,
  SEND_SMS: `${BASE_URL}/sms/messages`,
  SEND_SMS_UPLOAD: `${BASE_URL}/sms/messages/upload`,
  SEND_AIRTIME: `${BASE_URL}/topup`,
  SEND_AIRTIME_UPLOAD: `${BASE_URL}/topup/upload`,
  SEND_WHATSAPP: `${BASE_URL}/whatsapp/messages`,
  CHANGE_PASSWORD: `${BASE_URL}/auth/change-password`,
  FORGOT_PASSWORD: `${BASE_URL}/auth/forgot-password`,
  RESET_PASSWORD: `${BASE_URL}/auth/reset-password`,
  REVERIFY_EMAIL: `${BASE_URL}/auth/verify/resend-email`,
  CREATE_PROVIDER: `${BASE_URL}/admin/provider/register`,
  GET_DEFAULT_SERVICES: `${BASE_URL}/admin/default/services`,
  GET_PROVIDER_SERVICES: `${BASE_URL}/admin/services`,
  CREATE_PROVIDER_SERVICE: `${BASE_URL}/admin/service/register`,
  GET_COST_MODELS: `${BASE_URL}/core/list/cost/model`,
  GET_PRICE_MODELS: `${BASE_URL}/admin/models/fetch?type=price`,
};

export const BRAND_LIST = [
  {
    value: 'hospitality',
    label: 'Hospitality',
  },
  { value: 'technology', label: 'Technology' },
  { value: 'financial', label: 'Financial' },
  { value: 'real_estate', label: 'Real Estate' },
  { value: 'education', label: 'Education' },
];

export const STORAGE = {
  USER: 'blu-user',
  TOKEN: 'blu-token',
  TOKEN_EXPIRE: 'blu-token-exp',
};

export const AUTH_DATA = {
  [ROUTES.LANDING.path]: {
    name: 'Landing',
    title_mobile: 'Administrative privilege to monitor all user activity',
    title: ['Administrative privilege to,', 'monitor all user activity'],
    subtitle: '',
    image: LaptopImage,
  },
  [ROUTES.SIGN_IN.path]: {
    name: 'Login',
    title_mobile: 'Welcome Back, Login',
    navigation: (
      <>
        New User? <Link to={ROUTES.SIGN_UP.path}>Sign Up</Link>
      </>
    ),
    title: ['Welcome back,', 'Login to continue'],
    subtitle: '',
    image: LaptopImage,
  },
  [ROUTES.SIGN_UP.path]: {
    name: 'Register',
    title_mobile: 'Hello, Sign Up',
    navigation: (
      <>
        Already have an account? <Link to={ROUTES.SIGN_IN.path}>Login</Link>
      </>
    ),
    title: 'All-In-One platform for businesses to access digital services',
    subtitle: 'Airtime, Data bundles, Messaging, Bill payments and more across borders',
    image: LaptopImage,
  },
  [ROUTES.FORGOT_PASSWORD.path]: {
    name: 'Forgot Password',
    title_mobile: 'Forgot Your Password?',
    navigation: null,
    title: 'Enter email to reset your password',
    subtitle: '',
    image: SecurityImage,
  },
  [ROUTES.RESET_PASSWORD.path]: {
    name: 'Reset Password',
    title_mobile: 'Reset Your Password',
    navigation: null,
    title: 'Enter new password for your account',
    subtitle: '',
    image: SecurityImage,
  },
};

export const SIDE_MENU = [
  {
    title: 'Dashboard',
    itemId: ROUTES.DASHBOARD.path,
    elemBefore: () => <Icon width={14} name="bar" color="#ffffff" />,
  },
  {
    title: 'Requests',
    itemId: ROUTES.REQUESTS.path,
    elemBefore: () => <Icon width={14} name="chat" color="#ffffff" />,
  },
  {
    title: 'Agents',
    itemId: `#${ROUTES.AGENTS.path}`,
    elemBefore: () => <Icon width={14} name="store" color="#ffffff" />,
    subNav: [
      {
        title: 'View All Agents',
        itemId: ROUTES.AGENTS.path,
      },
      {
        title: 'Create Agent',
        itemId: '#createagent',
      },
    ],
  },
  {
    title: 'Merchants',
    itemId: `#${ROUTES.MERCHANTS.path}`,
    elemBefore: () => <Icon width={14} name="briefcase" color="#ffffff" />,
    subNav: [
      {
        title: 'View All Merchants',
        itemId: ROUTES.MERCHANTS.path,
      },
      // {
      //   title: 'Create Merchant',
      //   itemId: '#createmerchant',
      // },
    ],
  },
  {
    title: 'Partners',
    itemId: `#${ROUTES.PARTNERS.path}`,
    elemBefore: () => <Icon width={14} name="group" color="#ffffff" />,
    subNav: [
      {
        title: 'View All Partners',
        itemId: ROUTES.PARTNERS.path,
      },
    ],
  },
  {
    title: 'Providers',
    itemId: ROUTES.PROVIDERS.path,
    elemBefore: () => <Icon width={14} name="group" color="#ffffff" />,
  },
  {
    title: 'Prices',
    itemId: ROUTES.PRICES.path,
    elemBefore: () => <Icon width={14} name="cashblue" color="#ffffff" />,
  },
  {
    title: 'Commissions',
    itemId: ROUTES.COMMISSIONS.path,
    elemBefore: () => <Icon width={14} name="moneybag" color="#ffffff" />,
  },
  {
    title: 'Reports',
    // itemId: ROUTES.REPORTS.path,
    elemBefore: () => <Icon width={14} name="report" color="#ffffff" />,
    subNav: [
      {
        title: 'Audit Trail',
        itemId: ROUTES.AUDIT_TRAIL.path,
      },
      {
        title: 'Service Report',
        itemId: ROUTES.SERVICE_REPORT.path,
      },
      {
        title: 'Transaction History',
        itemId: ROUTES.TRANSACTION_REPORT.path,
      },
    ],
  },
  {
    title: 'Wallet',
    itemId: ROUTES.WALLET.path,
    elemBefore: () => <Icon width={14} name="card" color="#ffffff" />,
  },
  {
    title: 'Settings',
    itemId: ROUTES.SETTINGS.path,
    elemBefore: () => <Icon width={14} name="cog" color="#ffffff" />,
  },
];

export const COUNTRIES = countries;

export const personalInfoState = atom({
  key: 'personalInfo',
  default: {},
});

export const companyInfoState = atom({
  key: 'companyInfo',
  default: {},
});

export const providerCreateState = atom({
  key: 'APIcreateProvider',
  default: {
    partner_details: {},
  },
});

export const agentCreateState = atom({
  key: 'createAgent',
  default: {},
});

export const merchantCreateState = atom({
  key: 'createAgent',
  default: {},
});

export const permissions = [
  {
    name: 'create_teammates',
    label: 'Can Create Teammates',
    checked: false,
  },
  {
    name: 'manage_teammates',
    label: 'Can Manage Teammates',
    checked: false,
  },
  {
    name: 'manage_merchants',
    label: 'Can Manage Merchants',
    checked: false,
  },
  {
    name: 'fund_wallet',
    label: 'Can Fund Wallet',
    checked: false,
  },
  {
    name: 'create_merchants',
    label: 'Can Create Merchants',
    checked: false,
  },
  {
    name: 'invite_agents',
    label: 'Can Invite Agents',
    checked: false,
  },
  {
    name: 'manage_agents',
    label: 'Can Manage Agents',
    checked: false,
  },
  {
    name: 'view_report',
    label: 'Can View Reports',
    checked: false,
  },
  {
    name: 'create_providers',
    label: 'Can Create Providers',
    checked: false,
  },
  {
    name: 'manage_providers',
    label: 'Can Manage Providers',
    checked: false,
  },
  {
    name: 'manage_partners',
    label: 'Can Manage Partners',
    checked: false,
  },
  {
    name: 'view_transactions',
    label: 'Can View All Transactions',
    checked: false,
  },
  {
    name: 'view_pricing',
    label: 'Can View Pricing',
    checked: false,
  },
  {
    name: 'view_wallet',
    label: 'Can View Wallet Transaction',
    checked: false,
  },
  {
    name: 'view_settings',
    label: 'Can View Settings',
    checked: false,
  },
  {
    name: 'view_campaign',
    label: 'Can View All Campaigns',
    checked: false,
  },
  {
    name: 'view_service',
    label: 'Can View All Services',
    checked: false,
  },
];

export const adminPermission = [
  'manage_merchants',
  'manage_partners',
  'manage_merchants',
  'fund_wallet',
  'create_merchants',
  'invite_agents',
  'manage_agents',
  'view_report',
  'create_providers',
  'manage_providers',
  'view_transactions',
  'view_pricing',
  'view_wallet',
  'view_settings',
  'view_campaign',
  'view_service',
];
export const operationPermission = [
  'manage_agents',
  'view_report',
  'view_transactions',
  'view_wallet',
  'view_settings',
];
export const customerServicePermission = [
  'create_merchants',
  'manage_merchants',
  'invite_agents',
  'manage_agents',
  'view_report',
  'view_transactions',
  'view_pricing',
  'view_wallet',
  'view_settings',
];
export const createMerchant = [
  {
    element: 'input',
    label: 'First Name',
    placeholder: 'Enter First Name',
    name: 'firstName',
    type: 'text',
    value: '',
    validate: 'required',
  },
  {
    element: 'input',
    label: 'Last Name',
    placeholder: 'Enter First Name',
    name: 'lastName',
    type: 'text',
    value: '',
    validate: 'required',
  },
  {
    element: 'input',
    label: 'Email',
    placeholder: 'Enter Email',
    name: 'email',
    type: 'email',
    value: '',
    validate: 'email',
  },
  {
    element: 'phoneInput',
    placeholder: 'Enter Phone Number',
    name: 'phoneNumber',
    value: '',
    validate: 'phone_number',
  },
  {
    element: 'input',
    label: 'Address',
    placeholder: 'Enter Address',
    name: 'address',
    type: 'text',
    value: '',
    validate: 'required',
  },
  {
    element: 'input',
    label: 'State',
    placeholder: 'Select State',
    name: 'state',
    type: 'text',
    value: '',
    validate: 'required',
  },

  {
    element: 'select',
    label: 'Country',
    placeholder: 'Select Country',
    name: 'country',
    type: 'text',
    value: '',
    validate: 'required',
    options: countries,
  },
  // {
  //   element: 'select',
  //   label: 'identity',
  //   placeholder: 'Proof of identity',
  //   name: 'proofOfId',
  //   type: 'text',
  //   value: '',
  //   validate: 'required',
  //   options: [
  //     { value: 'passport', label: 'passport' },
  //     { value: 'drivers_license', label: 'drivers license' },
  //     { value: 'state', label: 'state' },
  //   ],
  // },
  // {
  //   element: 'input',
  //   label: 'Identity Number',
  //   placeholder: 'Identity Number',
  //   name: 'identificationNum',
  //   type: 'number',
  //   value: '',
  //   validate: 'required',
  // },
  {
    element: 'input',
    label: 'Company Name',
    placeholder: 'Enter Company Name',
    name: 'businessName',
    type: 'text',
    value: '',
    validate: 'required',
  },
  {
    element: 'input',
    label: 'Business Reg. Number',
    placeholder: 'Enter Business Reg. Name',
    name: 'businessRegNumber',
    type: 'text',
    value: '',
    validate: 'required',
  },
  {
    element: 'select',
    label: 'sector',
    placeholder: 'Select sector',
    name: 'businessType',
    type: 'text',
    value: '',
    validate: 'required',
    options: BRAND_LIST,
  },
  // {
  // element: 'select',
  // label: 'Country',
  // placeholder: 'Select Country',
  // name: 'companyCountry',
  // type: 'text',
  // value:"",
  // validate: 'required',
  // options: [
  //   { value: 'state', label: 'state' },
  //   { value: 'stat', label: 'state' },
  //   { value: 'state', label: 'state' },
  // ],
  // },
];

export const ACTIVATE = 'ACTIVATE';
export const DEACTIVATE = 'DEACTIVATE';
export const EDIT = 'EDIT';
