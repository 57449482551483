import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

// Components
import Icon from '../../../../components/Icon';
import BankProfileInfo from './BankProfileInfo';
import CompanyProfileInfo from './CompanyProfileInfo';
import Accordion from '../../../../components/Accordion';
import RepresentativeProfileInfo from './RepresentativeProfileInfo';

const StyledAccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  color: #505050;
  font-size: 14px;

  > div {
    display: flex;
    justify-content: space-between;
  }
`;

const ProviderProfile = ({ provider }) => {
  const profile = [
    {
      header: (
        <StyledAccordionHeader>
          <div>
            <Icon name="user-add" width={20} margin={[0, 20, 0, 0]} />
            Representative Information
          </div>
          <Accordion.Toggle value={<Icon name="caret-down" width={24} />} eventKey="0" />
        </StyledAccordionHeader>
      ),
      body: <RepresentativeProfileInfo provider={provider} />,

      eventKey: '0',
    },
    {
      header: (
        <StyledAccordionHeader>
          <div>
            <Icon name="user-lock" width={18} margin={[0, 20, 0, 0]} />
            Company Information
          </div>
          <Accordion.Toggle value={<Icon name="caret-down" width={24} />} eventKey="1" />
        </StyledAccordionHeader>
      ),
      body: <CompanyProfileInfo provider={provider} />,
      eventKey: '1',
    },

    {
      header: (
        <StyledAccordionHeader>
          <div>
            <Icon name="bank" width={16} margin={[0, 20, 0, 0]} />
            Bank Information
          </div>
          <Accordion.Toggle value={<Icon name="caret-down" width={24} />} eventKey="2" />
        </StyledAccordionHeader>
      ),
      body: <BankProfileInfo provider={provider} />,
      eventKey: '2',
    },
  ];

  return (
    <Row>
      <Col md={8} sm={8} style={{ paddingRight: 0, borderRight: '0.3px solid #ececec' }}>
        <Accordion data={profile} />
      </Col>
      <Col md={4} sm={4}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 30,
            paddingBottom: 60,
            alignItems: 'center',
          }}
        >
          <Icon name="avatar" width={160} margin={[0, 0, 20, 0]} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px',
                borderRight: '1px solid #e7e7e7',
                color: '#df374b',
                fontWeight: 600,
                fontSize: 12,
              }}
            >
              <Icon name="trash-circle" width={20} margin={[0, 5, 0, 0]} /> Delete
            </span>{' '}
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px',
                color: '#17568B',
                fontWeight: 600,
                fontSize: 12,
              }}
            >
              {' '}
              <Icon name="upload" width={20} margin={[0, 5, 0, 0]} /> Upload
            </span>{' '}
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default ProviderProfile;
