import Toast from 'react-hot-toast';
import { APIcreateNewMerchant } from '../../api/backendRoutes';
import { errorParser } from '../../utilities/helper';
import {
  createMerchantReducer,
  creatingMerchantReducer,
  errorcreatingMerchantReducer,
} from '../slices/merchant';

export const createMerchantAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(creatingMerchantReducer());
    try {
      const resp = await APIcreateNewMerchant(params);
      // console.log(resp)
      if (resp.data.message === 'ok') {
        // console.log(resp)
        dispatch(createMerchantReducer(resp));
      } else {
        dispatch(errorcreatingMerchantReducer(resp));
        throw resp?.data?.error;
      }
    } catch (error) {
      dispatch(errorcreatingMerchantReducer(error?.response?.data));
      Toast.error(errorParser(error) || 'error');
    }
  };
