import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { configureForage } from './utilities/forage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import App from './App';
import { store } from './redux/store';
import UserTypesProvider from './utilities/context/UserTypesContext';

configureForage();

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <UserTypesProvider>
        <App />
      </UserTypesProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
