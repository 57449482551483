import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Components
import Usage from './Usage';
import Services from './Services';
import Tabs from '../../../components/Tabs';
import PricingModel from './PricingModel/PricingModel';
import ContentWrapper from '../../../components/layout/ContentWrapper';
import CreateServiceBtn from './Buttons/CreateServiceBtn/CreateServiceBtn';
import UpgradeProviderBtn from './Buttons/UpgradeProviderBtn/UpgradeProviderBtn';
import ProviderProfileContent from './ProviderProfileContent/ProviderProfileContent';

// Utils
import baseAPI from '../../../api';
import { getProvider } from '../../../api/provider';
import { AppContext } from '../../../utilities/context/AppContext';
import CreatePriceModelBtn from './PricingModel/buttons/CreatePriceModelBtn';

const StyledProviders = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }
`;

const Provider = () => {
  const { id } = useParams();
  const { push } = useHistory();
  const [key, setKey] = useState('profile');

  const { setGoBackMethod } = useContext(AppContext);

  useEffect(() => {
    setGoBackMethod(() =>
      push({
        pathname: `/admin/providers/`,
      })
    );
  }, []);

  const getProviderQuery = useQuery(
    ['providers', id],
    async () => {
      const response = await baseAPI.get(getProvider(id));
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (Object.keys(data?.data).length < 1) {
          push({
            pathname: `/admin/providers/`,
          });
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const hashMap = {
    profile: <UpgradeProviderBtn provider={getProviderQuery?.data?.data} />,
    services: <CreateServiceBtn provider={getProviderQuery?.data?.data} />,
    pricingModel: <CreatePriceModelBtn partner={getProviderQuery?.data?.data} />,
  };

  return (
    <StyledProviders>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h5>Provider Information</h5>
        <ContentWrapper
          errorTitle="Error fetching provider profile"
          currentQuery={getProviderQuery}
          showError={false}
          showLoading={false}
        >
          {getProviderQuery?.isSuccess && hashMap[key]}
        </ContentWrapper>
      </div>

      <Tabs
        header={[
          {
            key: 'profile',
            label: 'Profile',
          },
          {
            key: 'services',
            label: 'Services',
          },
          {
            key: 'usage',
            label: 'Usage',
          },
          {
            key: 'pricingModel',
            label: 'Pricing Model',
          },
        ]}
        data={{
          profile: (
            <ContentWrapper
              errorTitle="Error fetching provider profile"
              currentQuery={getProviderQuery}
            >
              <ProviderProfileContent provider={getProviderQuery?.data?.data} />
            </ContentWrapper>
          ),
          services: (
            <ContentWrapper
              errorTitle="Error fetching provider profile"
              currentQuery={getProviderQuery}
            >
              <Services provider={getProviderQuery?.data?.data} />
            </ContentWrapper>
          ),
          usage: <Usage />,
          pricingModel: (
            <ContentWrapper
              errorTitle="Error fetching provider profile"
              currentQuery={getProviderQuery}
            >
              <PricingModel provider={getProviderQuery?.data?.data} />
            </ContentWrapper>
          ),
        }}
        onSetKey={(value) => setKey(value)}
      />
    </StyledProviders>
  );
};

export default Provider;
