import { useQuery } from 'react-query';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Table from '../../components/Table';
import { Select } from '../../components/inputs';
import Icon from '../../components/Icon';
import Card from '../../components/Card';
import Chart from '../../components/Chart';
import ContentWrapper from '../../components/layout/ContentWrapper';
import baseAPI from '../../api';
import {
  getCommissionActivities,
  getCommissionRevenue,
  getRecentCommission,
} from '../../api/commission';
import { toCurrency } from '../../utilities/helper';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 30px 20px 10px 20px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.04);

  > div {
    display: flex;
    align-items: center;
    color: #aaaaaa;
  }

  h6 {
    color: #000000;
    border-radius: 5px;
    padding: 7px 0;
    font-weight: 600;
    font-size: 24px;
    margin: 10px 0;
  }

  &.orange-bg {
    color: #ffffff !important;
    h6,
    > div {
      color: #ffffff !important;
    }
    background: rgba(242, 153, 74, 1);
  }
`;

const options = (data = []) => {
  const a = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      itemWidth: 10,
      itemHeight: 10,
      data: ['Clients', 'Agents', 'Partners'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        axisLine: { color: '#999999' },
        axisTick: { show: false },
        type: 'category',
        data: data.map((datum) => datum?.day || datum?.month || datum?.year),
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Aggregrate',
        type: 'line',
        barWidth: 7,
        itemStyle: {
          emphasis: {
            borderRadius: [50, 50],
          },
          normal: {
            borderRadius: [50, 50, 50, 50],
          },
        },
        color: '#509FEF',
        emphasis: {
          focus: 'series',
        },
        data: data.map((datum) => datum?.amount),
      },
    ],
  };

  return a;
};

const Commission = () => {
  const [aggregate, setAggregate] = useState([]);

  const [period, setPeriod] = useState({ label: 'Daily', value: 'daily' });

  const partnerInfo = aggregate?.find((info) => info?.type === 'Partner');
  const agentInfo = aggregate?.find((info) => info?.type === 'Agent');

  const partnerChange =
    (partnerInfo?.amount || 0) -
    ((partnerInfo?.amount || 0) / (100 + (partnerInfo?.percentageIncrement || 0))) * 100;

  const agentChange =
    (agentInfo?.amount || 0) -
    ((agentInfo?.amount || 0) / (100 + (agentInfo?.percentageIncrement || 0))) * 100;

  const getCommissionsQuery = useQuery(
    ['commissions'],
    async () => {
      const response = await baseAPI.get(getRecentCommission, {
        params: {
          limit: 6,
        },
      });
      return response.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const getCommissionsAggQuery = useQuery(
    ['commissions_aggregrate'],
    async () => {
      const response = await baseAPI.get(getCommissionRevenue);
      return response.data;
    },
    {
      onSuccess: (data) => {
        setAggregate(data?.data?.data);
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const getCommissionsActQuery = useQuery(
    ['commissions_activities', period],
    async () => {
      const response = await baseAPI.get(getCommissionActivities, {
        params: {
          period: period?.value,
        },
      });
      return response.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <Row style={{ margin: '50px 0' }}>
        <ContentWrapper
          errorTitle="Error fetching commission aggregate"
          currentQuery={getCommissionsAggQuery}
        >
          <Col md={4}>
            <StyledCard className="orange-bg">
              <div>Revenue Aggregate</div>
              <h6>NGN {toCurrency((partnerInfo?.amount || 0) + (agentInfo?.amount || 0))}</h6>
              <div>
                <Icon
                  name={partnerChange + agentChange > 0 ? 'trending-up' : 'trending-down'}
                  width={22}
                  margin={[0, 10, 0, 0]}
                />{' '}
                NGN {toCurrency(Math.abs(partnerChange + agentChange))} This Month
              </div>
            </StyledCard>
          </Col>
          <Col md={4}>
            <StyledCard className="blue-bg">
              <div>Total Commission Accured(Partners)</div>
              <h6>NGN {toCurrency(partnerInfo?.amount)}</h6>
              <div>
                <Icon
                  name={partnerChange > 0 ? 'trending-up' : 'trending-down'}
                  width={22}
                  margin={[0, 10, 0, 0]}
                  color="#aaaaaa"
                />{' '}
                NGN {toCurrency(Math.abs(partnerChange))} This Month
              </div>
            </StyledCard>
          </Col>
          <Col md={4}>
            <StyledCard className="blue-bg">
              <div>Total Commission Incurred(Agents)</div>
              <h6>NGN {toCurrency(agentInfo?.amount)}</h6>
              <div>
                <Icon
                  name={agentChange > 0 ? 'trending-up' : 'trending-down'}
                  width={22}
                  margin={[0, 10, 0, 0]}
                  color="#aaaaaa"
                />{' '}
                NGN {toCurrency(Math.abs(agentChange))} This Month
              </div>
            </StyledCard>
          </Col>
        </ContentWrapper>
      </Row>

      <Row style={{ marginBottom: 30 }}>
        <ContentWrapper
          errorTitle="Error fetching commissions activities"
          currentQuery={getCommissionsActQuery}
        >
          <Col md={12}>
            <Card>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h4>Aggregate</h4>
                <Select
                  onChange={(value) => setPeriod(value)}
                  size="tiny"
                  options={[
                    { label: 'Daily', value: 'daily' },
                    { label: 'Monthly', value: 'monthly' },
                    { label: 'Yearly', value: 'yearly' },
                  ]}
                  defaultValue={period?.value}
                  style={{ minWidth: 80 }}
                  padding={0}
                />
              </div>
              <div>
                <Chart line option={options(getCommissionsActQuery?.data?.data)} />
              </div>
            </Card>
          </Col>
        </ContentWrapper>
      </Row>

      <Row>
        <ContentWrapper errorTitle="Error fetching commissions" currentQuery={getCommissionsQuery}>
          <Col md={12}>
            <Table
              noBorder={false}
              header={[
                {
                  key: '_id',
                  label: 'ID',
                  hidden: true,
                },
                {
                  key: 'serviceName',
                  label: 'Service Name',
                  formatter: (service) => <div style={{ fontWeight: 500 }}>{service}</div>,
                },
                {
                  key: 'provider',
                  label: 'Provider',
                  sort: true,
                },
                {
                  key: 'price',
                  label: 'Price',
                  sort: true,
                  formatter: (price) => (
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="₦"
                      value={price}
                      className={price > 0 ? 'green' : 'red'}
                    />
                  ),
                },
                {
                  key: 'commission',
                  label: 'Commission',
                  sort: true,
                  formatter: (commission) => <>{commission?.toFixed(2)}%</>,
                },
              ]}
              data={getCommissionsQuery?.data?.data}
            />
          </Col>
        </ContentWrapper>
      </Row>
    </>
  );
};

export default Commission;
