/* eslint-disable */
import { useEffect, useCallback } from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../components/Icon';
import Table from '../../components/Table';
import { Checkbox, Select } from '../../components/inputs';
import Button from '../../components/Button';
import { fetchAdminAuditTrailsAction } from '../../redux/actions/audits';

const StyledAudits = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }

  .MuiCheckbox-root {
    margin-right: 10px;
    .MuiSvgIcon-root {
      color: #bbbbbb !important;
    }
  }
`;

const mapStateToProps = ({ audits }) => {
  return {
    fetchingAuditTrails: audits.fetchingAuditTrails,
    auditTrails: audits.auditTrails,
    meta: audits.auditTrailsMeta,
  };
};

const Audits = () => {
  const dispatch = useDispatch();

  const { fetchingAuditTrails, auditTrails, meta } = useSelector(mapStateToProps);

  const fetchAdminAuditTrails = useCallback((params = { limit: 25 }) =>
    dispatch(fetchAdminAuditTrailsAction(params), [dispatch])
  );

  useEffect(() => {
    fetchAdminAuditTrails();
  }, []);

  return (
    <StyledAudits>
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 30,
          }}
        >
          <h5>Audit Trail</h5>

          <div style={{ display: 'none' }}>
            <div style={{ marginRight: 10 }}>
              <Select
                size="tiny"
                options={[{ label: 'Merchants', value: 'merchants' }]}
                defaultValue="merchants"
                style={{ minWidth: 90 }}
                padding={0}
              />
            </div>
            <Button value="Download CSV" size="sm" />
          </div>
        </div>

        <Row>
          <Col>
            <Table
              filterAction={fetchAdminAuditTrails}
              filter={{
                Date: [
                  { value: 'Today', label: 'Today' },
                  { value: 'This Week', label: 'This Week' },
                  { value: 'This Month', label: 'This Month' },
                  { value: 'Last 30 Days', label: 'Last 30 Days' },
                  { value: 'This Year', label: 'This Year' },
                  { value: 'Period', label: 'Specific Period' },
                ],
                activityType: [
                  { value: 'Wallet Credit', label: 'Wallet Credit' },
                  { value: 'Wallet Debit', label: 'Wallet Debit' },
                  { value: 'Wallet Topup', label: 'Wallet Topup' },
                  { value: 'Insufficient Balance', label: 'Insufficient Balance' },
                  { value: 'SMS', label: 'SMS' },
                  { value: 'Airtime Purchase', label: 'Airtime Purchase' },
                  { value: 'Data Topup Purchase', label: 'Data Topup Purchase' },
                  { value: 'Log In', label: 'Log In' },
                  { value: 'Create Merchant', label: 'Create Merchant' },
                ],
                search: 'search',
              }}
              showFilter
              header={[
                {
                  key: 'firstName',
                  label: (
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                      Name
                    </div>
                  ),
                  sort: true,
                  formatter: (firstName, record) => (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox radio={false} style={{ marginRight: 20 }} color="default" />
                        <Icon name="avatar" margin={[0, 10, 0, 0]} width={42} />
                        <div>
                          <div>{firstName}</div>
                          <div className="text-muted">{record.lastName}</div>
                        </div>
                      </div>
                    </>
                  ),
                },
                { key: 'activityType', label: 'Activity Type', sort: true },
                { key: 'description', label: 'Description', sort: true },
                {
                  key: 'createdAt',
                  label: 'Date',
                  sort: true,
                  formatter: (date) => new Date(date).toDateString(),
                },
                {
                  key: 'createdAt',
                  label: 'Time',
                  sort: true,
                  formatter: (date) => new Date(date).toLocaleTimeString(),
                },
              ]}
              data={auditTrails}
              loading={fetchingAuditTrails}
              paginate={Boolean(Object.keys(meta).length)}
              meta={meta}
            />
          </Col>
        </Row>
      </>
    </StyledAudits>
  );
};

export default Audits;
