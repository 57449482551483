import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  APIDeativateUserAccount,
  APIEditUserData,
  APIfetchUserProfile,
} from '../../../api/backendRoutes';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import { Input, Select } from '../../../components/inputs';
import { ROUTES } from '../../../components/router/routes/routes';
import { BRAND_LIST, COUNTRIES } from '../../../utilities/constants';
import { errorParser } from '../../../utilities/helper';
import { fetchUserData, storeUserData } from '../../../utilities/storage';
import ConfirmDeactivateModal from './ConfirmDeactivateModal';

const StyledProfile = styled.div`
  padding: 20px;
`;
const StyledButton = styled.div`
  display: flex;
  gap: 30px;
`;
const Profile = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const user = fetchUserData();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    ...user,
    companyAddress: user?.companyInformation?.[0]?.companyAddress,
    country: user?.country,
    state: user?.companyInformation?.[0]?.state,
  });

  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };
  const deactivateAccount = async () => {
    try {
      setLoading(true);
      const resp = await APIDeativateUserAccount();
      if (resp.data.message === 'successful') {
        setLoading(false);
        localStorage.clear();
        history.push(ROUTES.SIGN_IN.path);
      } else {
        setLoading(false);
        throw resp?.data?.error || 'Error updating profile';
      }
    } catch (error) {
      toast.error(errorParser(error));
      setLoading(false);
    }
  };
  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    const { country, companyAddress, state, businessType, businessName } = formData;
    try {
      setLoading(true);
      const resp = await APIEditUserData({
        country,
        companyAddress,
        state,
        businessType,
        businessName,
      });
      if (resp.data.message === 'successful') {
        const newUserProfile = await APIfetchUserProfile();
        storeUserData({ ...user, ...newUserProfile.data.data });
        toast.success('Profile Updated successfully');
        setLoading(false);
      } else {
        setLoading(false);
        throw resp?.data?.error || 'Error updating profile';
      }
    } catch (error) {
      toast.error(errorParser(error));
      setLoading(false);
    }
  };
  return (
    <StyledProfile>
      <Row>
        <Col>
          <div>
            <div style={{ margin: '20px 0px', display: 'flex' }}>
              <Icon name="building" width={18} margin={[0, 20, 0, 0]} />
              <div>Company Information</div>
            </div>
            <Row>
              <Col>
                <Input
                  label="First name"
                  defaultValue={formData?.firstName}
                  placeholder="Enter First name"
                  name="firstName"
                  type="text"
                  validate="required"
                  readonly
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="Last name"
                  defaultValue={formData?.lastName}
                  placeholder="Enter Last name"
                  name="lastName"
                  type="text"
                  validate="required"
                  readonly
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="Email"
                  defaultValue={formData?.email}
                  placeholder="Enter Email"
                  name="email"
                  type="text"
                  validate="required"
                  readonly
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="Role"
                  defaultValue={formData?.role?.roleName}
                  placeholder="Enter Role"
                  name="role"
                  type="text"
                  validate="required"
                  disabled
                />
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Select
                  label="Sector"
                  placeholder="Enter Sector"
                  name="businessType"
                  onChange={handleInputChange}
                  value={formData?.businessType}
                  type="text"
                  validate="required"
                  options={BRAND_LIST}
                />
              </Col>
            </Row> */}
          
            <Row>
              <Col>
                <Select
                  label="Country"
                  placeholder="Enter Country"
                  name="country"
                  type="text"
                  validate="required"
                  value={formData?.country}
                  onChange={handleInputChange}
                  options={[
                    ...COUNTRIES.map(({ label }) => ({
                      label,
                      value: (label.split('(')[0] || '').trim(),
                    })),
                  ]}
                  disabled
                />
              </Col>
            </Row>
          </div>
          {/* <Col>
            <StyledButton>
              <Button value="Update Profile" loading={loading} onClick={handleProfileUpdate} />
              <Button
                value="De-activate Profile"
                onClick={() => setShowModal(true)}
                group="outline_danger"
              />
            </StyledButton>
          </Col> */}
        </Col>
        <Col>
          <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
            <div style={{ position: 'relative', height: 'fit-content' }}>
              <Icon name="avatar" width={180} margin={[20, 0, 20, 0]} />
              <Icon name="pencil" className="profile_pic" width={18} />
            </div>
          </div>
        </Col>
      </Row>
      <ConfirmDeactivateModal
        show={showModal}
        closeModal={() => setShowModal(false)}
        action={deactivateAccount}
      />
    </StyledProfile>
  );
};

export default Profile;
