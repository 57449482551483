import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Tabs from '../../../components/Tabs';
import MerchantProfile from './MerchantProfile';
import WalletTransactions from './Wallet';
import Users from './User';
import TeamDetail from './Team/index';

const StyledMerchants = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }

  .MuiCheckbox-root {
    margin-right: 10px;
    .MuiSvgIcon-root {
      color: #bbbbbb !important;
    }
  }
`;
const Merchant = ({ merchant, walletDetails }) => {
  const [showTeam, setShowTeam] = useState(false);
  const [teamMate, setTeamMate] = useState();
  useEffect(() => {}, [merchant]);
  return (
    <>
      {showTeam && <TeamDetail name={teamMate} />}
      {!showTeam && (
        <StyledMerchants>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h5>Merchant Information</h5>
          </div>

          <Tabs
            header={[
              {
                key: 'profile',
                label: 'Profile',
              },
              {
                key: 'wallet_trx',
                label: 'Wallet Transactions',
              },
              {
                key: 'users',
                label: 'Team',
              },
            ]}
            data={{
              profile: <MerchantProfile merchant={merchant} walletDetails={walletDetails} />,
              wallet_trx: <WalletTransactions merchant={merchant} />,
              users: (
                <Users showTeamDetail={setShowTeam} setTeamMate={setTeamMate} merchant={merchant} />
              ),
            }}
          />
        </StyledMerchants>
      )}
    </>
  );
};

export default Merchant;
