import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// Components
import Tabs from '../../../../components/Tabs';
import UpgradeProviderForm from './UpgradeProviderForm';
import ContentWrapper from '../../../../components/layout/ContentWrapper';

// Utils
import baseAPI from '../../../../api';
import { getProvider } from '../../../../api/provider';
import { AppContext } from '../../../../utilities/context/AppContext';

const StyledProviders = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }
`;

const UpgradeProvider = () => {
  const { id } = useParams();
  const { push } = useHistory();

  const { setGoBackMethod } = useContext(AppContext);

  useEffect(() => {
    setGoBackMethod(() =>
      push({
        pathname: `/admin/providers/${id}`,
      })
    );
  }, []);

  const getProviderQuery = useQuery(
    ['providers', id],
    async () => {
      const response = await baseAPI.get(getProvider(id));
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (Object.keys(data?.data).length < 1) {
          push({
            pathname: `/admin/providers/`,
          });
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <StyledProviders>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h5>Become a Partner</h5>
      </div>

      <Tabs
        header={[
          {
            key: 'profile',
            label: 'Company Information',
          },
        ]}
        data={{
          profile: (
            <ContentWrapper
              errorTitle="Error fetching provider profile"
              currentQuery={getProviderQuery}
            >
              <UpgradeProviderForm provider={getProviderQuery?.data?.data} />
            </ContentWrapper>
          ),
        }}
      />
    </StyledProviders>
  );
};

export default UpgradeProvider;
