const baseURL = `/auth/provider`;

export const {
  getProvider,
  getProviders,
  createProvider,
  updateProvider,
  deleteProvider,
  upgradeProvider,
  activateProvider,
  deactivateProvider,
  updateBankInformation
} = {
  getProviders: `${baseURL}/list`,
  createProvider: `${baseURL}/create`,
  getProvider: (id) => `${baseURL}/${id}/single`,
  updateProvider: (id) => `${baseURL}/${id}/edit`,
  deleteProvider: (id) => `${baseURL}/${id}/delete`,
  upgradeProvider: (id) => `${baseURL}/${id}/partner`,
  activateProvider: (id) => `${baseURL}/${id}/activate`,
  updateBankInformation: (id) => `/auth/bank/${id}/edit`,
  deactivateProvider: (id) => `${baseURL}/${id}/deactivate`,
};
