/* eslint-disable */
import Toast from 'react-hot-toast';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

// Components
import Icon from '../../../components/Icon';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import BankInfo from './CreateProviderForms/BankInfo';
import CompanyInfo from './CreateProviderForms/CompanyInfo';
import { Checkbox, Input, Select } from '../../../components/inputs';
import RepresentativeInfo from './CreateProviderForms/RepresentativeInfo';

// Utils
import baseAPI from '../../../api';
import { createProvider } from '../../../api/provider';
import { providerCreateState } from '../../../utilities/constants';
import { fetchCostModel, fetchPriceModel } from '../../../redux/actions/provider';
import { errorParser } from '../../../utilities/helper';

const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const ServiceInfo = ({ moveToNext, loading }) => {
  const [providerInfo, setProviderInfo] = useRecoilState(providerCreateState);
  const [formData, setFormData] = useState({ ...providerInfo });
  const [services, setServices] = useState([]);
  const [costModels, setCostModels] = useState([]);
  const [priceModels, setPriceModels] = useState([]);
  // const [loading,setLoading] =useState(false)
  const dispatch = useDispatch();
  const { CostModel, error, PriceModel } = useSelector(({ provider }) => {
    return provider.fetchModels;
  });
  const fetchCostM = useCallback(() => dispatch(fetchCostModel()), [dispatch]);
  const fetchPriceM = useCallback(() => dispatch(fetchPriceModel()), [dispatch]);
  useEffect(() => {
    fetchCostM();
    fetchPriceM();
  }, []);

  useEffect(() => {
    if (CostModel.length > 0 && PriceModel.length > 0) {
      const newCostModel = CostModel?.map((model) => {
        return {
          label: model.modelName,
          value: model._id,
        };
      });
      setCostModels(newCostModel);
      const newPriceModel = PriceModel?.map((model) => {
        return {
          label: model.priceModelName,
          value: model._id,
        };
      });
      setPriceModels(newPriceModel);
    }
  }, [CostModel, PriceModel]);
  // useEffect(()=>{

  // },[loading])
  const handleInputChange = ({ name, value }) => {
    setFormData((v) => ({ ...v, [name]: value }));
  };
  //  const handleLoadingState = ()=>{
  //    setLoading(loadingState)
  //  }
  return (
    <form
      style={{ width: '100%', margin: 'auto' }}
      onSubmit={(e) => {
        e.preventDefault();
        setProviderInfo((p) => ({ ...p, ...formData }));
        moveToNext({ ...providerInfo, ...formData });
      }}
    >
      <Row>
        <Col style={{ fontSize: 16, fontWeight: 200, marginBottom: 15, opacity: 0.6 }}>
          Service Information
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Service Name"
            placeholder="Enter Service Name"
            name="serviceName"
            validate="required"
            defaultValue={formData.service_name}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Provider Name"
            placeholder="Enter Provider Name"
            name="providerName"
            type="text"
            validate="required"
            defaultValue={formData.provider_name}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="API end point"
            placeholder="Enter API end point"
            name="apiEndpoint"
            type="text"
            validate="required"
            defaultValue={formData.api_endpoint}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Encryption key"
            placeholder="Enter Encryption key"
            name="encryptionKey"
            type="text"
            validate="required"
            defaultValue={formData.encryption_key}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            label="Cost Model *"
            placeholder="Select Cost Model"
            name="costModel"
            validate="required"
            options={costModels}
            defaultValue={formData.cost_model}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            label="Price Model *"
            placeholder="Select Price Model"
            name="priceModel"
            // validate="required"
            options={priceModels}
            defaultValue={formData.price_model}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      {/* 
      {formData.cost_model === 'commision' && (
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Col>
            <Input
              label="%"
              placeholder="Enter Percentage"
              name="discounted_price"
              type="number"
              validate="required"
              defaultValue={formData.discounted_price}
              onChange={handleInputChange}
            />
          </Col>
          <span style={{ paddingBottom: 20, color: '#777777' }}>For</span>
          <Col>
            <Input
              label="Set Value"
              placeholder="Set Value"
              name="cost_model_value"
              type="number"
              validate="required"
              defaultValue={formData.cost_model_value}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
      )} */}
      {/* 
      {formData.cost_model === 'flat_fee' && (
        <Row>
          <Col>
            <Input
              label="Amount"
              placeholder="Enter Amount"
              name="cost_model_value"
              type="number"
              validate="required"
              defaultValue={formData.cost_model_value}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
      )} */}

      {/* {formData.price_model && (
        <Row>
          <Col>
            <Input
              label="Set Value"
              placeholder="Enter Value"
              name="price_model_value"
              type="number"
              validate="required"
              defaultValue={formData.price_model_value}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
      )} */}
      <Row>
        <Col>Payload Information</Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            label="Phone Number"
            name="payloadPhoneNumber"
            // onChange={handleInputChange}
            defaultValue={formData.payload_phn_num}
            radio={false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            label="Amount"
            name="payloadAmount"
            // onChange={handleInputChange}
            defaultValue={formData.payload_amount}
            radio={false}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            label="Plan"
            name="payloadPlan"
            // onChange={handleInputChange}
            defaultValue={formData.payload_plan}
            radio={false}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button type="submit" value="Submit" size="md" width={100} loading={loading} />
        </Col>
      </Row>
    </form>
  );
};

const CreateProvider = ({ show, onClose }) => {
  const [, setProviderInfo] = useRecoilState(providerCreateState);
  const queryClient = useQueryClient();
  const [index, setIndex] = useState(0);
  const [showSuccess, setSuccess] = useState(false);
  const goBack = () => {
    setIndex((i) => (i - 1 > -1 ? i - 1 : 0));
  };

  const moveToNext = (data) => {
    setIndex((i) => i + 1);
  };

  const handleClose = () => {
    setProviderInfo({});
    onClose();
  };

  const { mutate, isLoading } = useMutation(
    async (payload) => {
      setSuccess(false);
      const response = await baseAPI.post(createProvider, { ...payload });

      return response;
    },
    {
      onSuccess: ({ data }) => {
        if (data.success) {
          queryClient.invalidateQueries(['providers']);
          setSuccess(true);
          setProviderInfo({});
          Toast.success(data?.message);
        } else Toast.error("Error Can't Create Provider");
      },
      onError: (error) => Toast.error(errorParser(error)),
    }
  );

  const addNewProvider = async (data) => {
    const payload = { ...data };
    // if (Object.keys(payload?.partner_details).length < 1) {
    // }
      delete payload?.partner_details;
    delete payload?.fetching;

    mutate(payload);
  };
  const handleCloseSuccessmodal = () => {
    setSuccess(false);
    onClose();
  };
  const ShowSuccessModal = () => {
    return (
      <StyledSuccess>
        <Icon name="success" height="80px" />
        <div>Profile Successfully Created</div>
        <div
          style={{
            color: '#17568B',
            fontSize: '16px',
            cursor: 'pointer',
          }}
          onClick={handleCloseSuccessmodal}
        >
          View Providers
        </div>
      </StyledSuccess>
    );
  };
  return (
    <>
      {!showSuccess && (
        <Modal
          show={show}
          onClose={handleClose}
          goBack={goBack}
          title={index === 0 ? 'Create Provider' : ''}
        >
          {index === 0 && <CompanyInfo moveToNext={moveToNext} />}
          {index === 1 && <RepresentativeInfo moveToNext={moveToNext} />}
          {index === 2 && <BankInfo moveToNext={addNewProvider} loading={isLoading} />}
        </Modal>
      )}
      {showSuccess && (
        <Modal show={showSuccess} onClose={handleCloseSuccessmodal}>
          <ShowSuccessModal />
        </Modal>
      )}
    </>
  );
};

export default CreateProvider;
