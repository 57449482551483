import ReactECharts from 'echarts-for-react';
import { useMemo } from 'react';

const Chart = ({
  bar = false,
  line = false,
  option,
  notMerge = true,
  lazyUpdate = true,
  onChartReady,
  onEvents,
  theme = '',
  ...rest
}) => {
  const options = useMemo(() => {
    const a = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        itemWidth: 10,
        itemHeight: 10,
        data: ['Clients', 'Agents', 'Partners'],
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          axisLine: { color: '#999999' },
          axisTick: { show: false },
          type: 'category',
          data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: 'Agents',
          type: 'bar',
          barWidth: 7,
          stack: '搜索引擎',
          itemStyle: {
            emphasis: {
              barBorderRadius: [50, 50],
            },
            normal: {
              barBorderRadius: [50, 50, 50, 50],
            },
          },
          color: '#509FEF',
          emphasis: {
            focus: 'series',
          },
          data: [60, 72, 71, 74, 190, 130, 110, 71, 74, 190, 130, 110],
        },
        {
          name: 'Clients',
          type: 'bar',
          stack: '搜索引擎',
          itemStyle: {
            emphasis: {
              barBorderRadius: [50, 50],
            },
            normal: {
              barBorderRadius: [50, 50, 50, 50],
            },
          },
          color: '#17568B',
          emphasis: {
            focus: 'series',
          },
          data: [120, 132, 101, 134, 210, 130, 220, 101, 134, 210, 130, 220],
        },
        {
          name: 'Partners',
          type: 'bar',
          stack: '搜索引擎',
          itemStyle: {
            emphasis: {
              barBorderRadius: [50, 50],
            },
            normal: {
              barBorderRadius: [50, 50, 50, 50],
            },
          },
          color: 'rgba(101, 177, 255, 0.26)',
          emphasis: {
            focus: 'series',
          },
          data: [62, 82, 91, 84, 109, 110, 120, 91, 84, 109, 110, 120],
        },
      ],
    };

    if (bar) {
      a.series = [
        {
          type: 'bar',
          barWidth: 7,
          itemStyle: {
            emphasis: {
              barBorderRadius: [50, 50],
            },
            normal: {
              barBorderRadius: [50, 50, 50, 50],
            },
          },
          color: '#509FEF',
          emphasis: {
            focus: 'series',
          },
          data: [60, 72, 71, 74, 10, 40, 110, 71, 74, 100, 80, 110],
        },
      ];
    } else if (line) {
      a.series = [
        {
          name: 'Partners',
          type: 'line',
          barWidth: 7,
          itemStyle: {
            emphasis: {
              barBorderRadius: [50, 50],
            },
            normal: {
              barBorderRadius: [50, 50, 50, 50],
            },
          },
          color: '#509FEF',
          emphasis: {
            focus: 'series',
          },
          data: [60, 72, 71, 74, 10, 40, 110, 71, 74, 100, 80, 110],
        },
        {
          name: 'Agents',
          type: 'line',
          stack: '搜索引擎',
          itemStyle: {
            emphasis: {
              barBorderRadius: [50, 50],
            },
            normal: {
              barBorderRadius: [50, 50, 50, 50],
            },
          },
          color: '#17568B',
          emphasis: {
            focus: 'series',
          },
          data: [62, 82, 91, 84, 109, 110, 120, 91, 84, 109, 110, 120],
        },
      ];
    }

    return a;
  }, [bar]);

  return (
    <ReactECharts
      option={option || options}
      notMerge={notMerge}
      lazyUpdate={lazyUpdate}
      theme={theme}
      onChartReady={onChartReady}
      onEvents={onEvents}
      opts={rest}
    />
  );
};

export default Chart;
