import styled from 'styled-components';

const StyledLogo = styled.div`
  margin-bottom: 10px;

  img {
    height: 30px;
  }
`;

const Logo = ({ src, name = '', width, height = 30, ...rest }) => {
  return (
    <StyledLogo {...rest}>
      <img
        src={src}
        alt={name}
        width={width ? `${width}px` : 'auto'}
        height={height ? `${height}px` : 'auto'}
      />
    </StyledLogo>
  );
};

export default Logo;
