import styled from 'styled-components';
import { Tabs as BTabs, Tab } from 'react-bootstrap';

const StyledTabs = styled.section`
  padding: 20px 0;

  .nav-tabs {
    border: none;
    border-radius: 10px 10px 0 0;
    position: relative;
    padding: 10px 0 0 0;
    border: 1px solid #dddddd;
    border-bottom: 1px solid #ebebeb !important;
  }

  .nav-tabs .nav-link {
    color: #bdbdbd;
    background-color: inherit;
    border: none;
    font-size: 14px;
    font-weight: 500;
    transition: color 250ms ease;

    img {
      opacity: 0.3;
      transition: opacity 300ms ease;
    }

    &.active {
      border-bottom: 3px solid #17568b !important;
    }

    &.active,
    &:hover {
      color: #17568b;

      img {
        opacity: 1;
      }
    }
  }

  .tab-content {
    border: 1px solid #ebebeb;
    border-top: none;
    border-radius: 0 0 10px 10px;
  }
`;

const Tabs = ({ header, data, activeKey, onSetKey = () => {} }) => {
  return (
    <StyledTabs>
      <BTabs
        onSelect={(key) => onSetKey(key)}
        defaultActiveKey={activeKey || header[0].key}
        id="uncontrolled-tab-example"
      >
        {header.map(({ key, label }) => (
          <Tab eventKey={key} title={label} key={key}>
            {data[key]}
          </Tab>
        ))}
      </BTabs>
    </StyledTabs>
  );
};

export default Tabs;
