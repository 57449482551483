import Button from '../../../components/Button';
import Modal from '../../../components/Modal';

function ConfirmDeactivateModal({ show, closeModal, action }) {
  return (
    <Modal show={show} onClose={closeModal} title="Confirm Action Deactivation">
      <h3 className="text-center">Please proceed with caution!</h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '20px 0px',
          borderTop: '1px solid #F2F2F2',
          margin: '10px 0px',
        }}
      >
        <Button value="Cancel" group="outline" onClick={closeModal} />
        <Button value="Deactivate" group="outline_danger" onClick={action} />
      </div>
    </Modal>
  );
}

export default ConfirmDeactivateModal;
