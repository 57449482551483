import { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
// import axios from 'axios';
import Image from './Image';
import Modal from './Modal';
import Button from './Button';
import { Input } from './inputs';

const StyledCard = styled.div`
  .fundwallet-wrapper {
    display: flex;
    justify-content: space-between;
    > div {
      padding: 15px;
    }
    .left {
      background: rgba(241, 241, 241, 0.42);
    }
    .right {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .error {
      display: flex;
      justify-content: center;
      font-size: 12px;
      color: red;
    }
  }
`;

const EnterAmount = ({ email, onChange, onSubmit, onClose, error, loading }) => {
  const handleInputChange = ({ name, value }) => {
    if (onChange) {
      onChange({ name, value });
    }
  };

  return (
    <StyledCard>
      <div className="fundwallet-wrapper">
        <div className="left">
          <Image src="/logo-blue.svg" width={100} />
        </div>
        <div className="right">
          <div>
            <small>{email}</small>
          </div>
          <div>How much would you like to debit this wallet with?</div>
          <form onSubmit={onSubmit}>
            <Row>
              <Col>
                <Input
                  placeholder="Enter Amount (₦)"
                  label="Enter Amount (₦)"
                  type="number"
                  onChange={handleInputChange}
                  name="amount"
                  required
                  validate="required"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  value="Fund"
                  type="submit"
                  margin={[10, 10, 20, 10]}
                  center
                  size="lg"
                  width="100%"
                  loading={loading}
                />
              </Col>
            </Row>
            <div style={{ textAlign: 'center', padding: '2rem 0' }}>
              <span style={{ cursor: 'pointer' }} onClick={onClose}>
                Cancel
              </span>
            </div>
            {error[0] && <div className="error">{error[1]}</div>}
          </form>
        </div>
      </div>
    </StyledCard>
  );
};

const DebitWallet = ({ wallet, refetch, show, onClose }) => {
  const [formData, setFormData] = useState({ sender_id: '', country: '', fetching: false });
  const [error, setError] = useState([false, '']);

  const handleRefetch = () => {
    refetch();
    onClose();
  };

  const handleDebit = async (e) => {
    e.preventDefault();

    // if (formData.amount >= 1000) {
    //   setFormData((prev) => ({ ...prev, fetching: true }));
    //   const payload = {
    //     amount: parseInt(formData.amount, 10),
    //     currency: 'NGN',
    //   };

    //   const url = `${process.env.REACT_APP_CHECKOUT_URL}/checkout/initialize`;

    //   const Authorization = process.env.REACT_APP_PAYMENT_API_KEY;
    //   const response = await axios.post(url, payload, {
    //     headers: { Authorization, 'X-Api-Key': process.env.REACT_APP_X_API_KEY },
    //   });
    //   const { status, data } = response;

    //   if (status === 200) {
    //     setCheckoutData({
    //       url: data?.data?.authorization_url,
    //       refetch: handleRefetch,
    //     });
    //     onClose();
    //   } else {
    //     setFormData((prev) => ({ ...prev, fetching: false }));
    //     setError([true, data.message]);
    //   }
    // } else {
    //   setError([true, 'Please enter an amount greater than or equal to 1000']);
    // }
  };
  const handleInputChange = ({ name, value }) => {
    setError([false, '']);
    setFormData((v) => ({ ...v, [name]: value }));
  };

  return (
    <Modal show={show} onClose={onClose} showHeader={false} nopadding size="md">
      <EnterAmount
        email={wallet?.Customer?.email}
        onChange={handleInputChange}
        onSubmit={handleDebit}
        onClose={onClose}
        error={error}
        loading={formData.fetching}
      />
    </Modal>
  );
};

export default DebitWallet;
