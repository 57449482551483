import { createContext, useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { APIgetCompanyDetails } from '../../api/backendRoutes';
import Notify from '../../components/Notify';

const AppContext = createContext({});

const initialState = { showSideMenu: null, goBackMethod: null };

const AppProvider = ({ children }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState({});
  const [checkoutData, setCheckoutData] = useState({});
  const { url, refetch } = checkoutData;

  const [data, setData] = useState(initialState);

  const [notification, setNotification] = useState({
    show: false,
    type: 'success',
    title: '',
    children: <></>,
  });

  const toggleSideMenu = () => {
    setData((d) => ({ ...d, showSideMenu: !d.showSideMenu }));
  };

  const setGoBackMethod = (method) => {
    setData((d) => ({ ...d, goBackMethod: method }));
  };

  const goBack = () => {
    if (data.goBackMethod) {
      data.goBackMethod();
    }
  };

  const notify = (title, type = 'success', c) => {
    setNotification({ show: true, type, title, children: c });
  };
  useEffect(async () => {
    // const url = window.location.href;
    setDataLoading(true);
    try {
      const resp = await APIgetCompanyDetails({
        companyName: 'Blu Intouch',
      });
      if (resp.data.message === 'successful') {
        const newData = resp.data.data;
        setPartnerInfo(newData);
        setDataLoading(false);
      }
    } catch (err) {
      setDataLoading(false);
    }
  }, []);

  const receiveMessage = async (event) => {
    const { data: response } = event || {};
    const { name } = response || {};

    if (name === 'close') setCheckoutData({});
    else if (name === 'done') {
      // eslint-disable-next-line no-unused-expressions
      refetch && refetch();
      setCheckoutData({});
    }
    // }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', receiveMessage, false);
    }
    // eslint-disable-next-line

    return () => {
      window.addEventListener('message', receiveMessage, false);
    };
  });
  return (
    <AppContext.Provider
      value={{
        checkoutData,
        setCheckoutData,
        toggleSideMenu,
        setGoBackMethod,
        goBack,
        notify,
        ...data,
        partnerInfo,
        dataLoading,
      }}
    >
      {children}
      {notification.show && (
        <Notify
          {...notification}
          onClose={() =>
            setNotification({ show: false, type: 'success', title: '', children: <></> })
          }
        />
      )}
      {url && <Iframe url={url} id="checkout" />}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
