/* eslint-disable */
import { useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table';
import { fetchPricesAction } from '../../redux/actions/prices';
import Button from '../../components/Button';
import CreatePriceModal from './components/CreateServiePrice';
import EditPriceModal from './components/EditServicePrice';
import UpdatePriceModelModal from '../providers/components/PricingModel/modals/UpdatePriceModelModal';

const StyledPrices = styled.div``;

const Prices = () => {
  const [showCreatePriceModal, setShowCreatePriceModal] = useState(false);
  const [editPriceModal, setEditPriceModal] = useState({
    show: false,
    data: {},
  });

  const { prices, meta, fetching } = useSelector((state) => {
    return {
      prices: state.prices.prices,
      meta: state.prices.pricesMeta,
      fetching: state.prices.fetchingPrices,
    };
  });

  const dispatch = useDispatch();

  const FetchPrices = useCallback(
    (params = { limit: 25 }) => {
      const filters = { ...params };

      if (params?.serviceName) {
        filters.service == params?.serviceName;
        delete filters?.serviceName;
      }

      delete filters?.serviceName;

      dispatch(fetchPricesAction(filters));
    },
    [dispatch]
  );

  useEffect(() => {
    FetchPrices();
  }, []);

  return (
    <StyledPrices>
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 30,
          }}
        >
          <h5>Prices</h5>
          {/* <Button
            value="Create Price"
            group="secondary round"
            icon="plus-circle"
            width={150}
            onClick={() => setShowCreatePriceModal(true)}
            // margin={[0, 0, 0, 10]}
          /> */}
        </div>

        <Row>
          <Col>
            <Table
              filterAction={FetchPrices}
              filter={{
                keyword: 'keyword',
                serviceName: 'service',
                Date: [
                  { value: 'Today', label: 'Today' },
                  { value: 'This Week', label: 'This Week' },
                  { value: 'This Month', label: 'This Month' },
                  { value: 'Last 30 Days', label: 'Last 30 Days' },
                  { value: 'This Year', label: 'This Year' },
                  { value: 'Period', label: 'Specific Period' },
                ],
              }}
              showFilter
              header={[
                {
                  key: 'priceModelName',
                  label: 'Name',
                  formatter: (name) => name || 'Not Available',
                },
                {
                  key: 'country',
                  label: 'Country',
                },
                {
                  key: 'countryCode',
                  label: 'Country Code',
                },
                {
                  key: 'price',
                  label: 'Price/Value',
                  sort: false,
                  formatter: (_, model) =>
                    model?.type === 'Discount'
                      ? `${model?.value || 0}%`
                      : `${model?.currency} ${model?.price || 0}`,
                },
                {
                  key: 'id',
                  label: '',
                  formatter: (value, record) => {
                    return (
                      <Button
                        value="Edit"
                        group="secondary round"
                        width={150}
                        onClick={() => setEditPriceModal({ show: true, data: record })}
                        // margin={[0, 0, 0, 10]}
                      />
                    );
                  },
                },
              ]}
              data={prices}
              loading={fetching}
              paginate={Boolean(Object.keys(meta).length)}
              meta={meta}
            />
          </Col>
        </Row>
        {showCreatePriceModal && (
          <CreatePriceModal
            show={showCreatePriceModal}
            listPrice={FetchPrices}
            onClose={() => setShowCreatePriceModal(false)}
            reload={() => {}}
          />
        )}
        {editPriceModal.show && (
          <UpdatePriceModelModal
            show={editPriceModal.show}
            model={editPriceModal.data}
            onClose={() => setEditPriceModal((prev) => ({ ...prev, show: false }))}
            reload={FetchPrices}
          />
        )}
      </>
    </StyledPrices>
  );
};

export default Prices;
