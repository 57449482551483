import { useCallback, useEffect } from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/Table';
import { Select } from '../../components/inputs';
import Button from '../../components/Button';
import { fetchAdminTransactionsAction } from '../../redux/actions/transactions';

const StyledTransactions = styled.div`
  .pending {
    button {
      background: #dddddd;
      color: #878787;
      font-weight: 500;
      width: 120px;
    }
  }

  .approved {
    button {
      background: rgba(70, 172, 80, 0.15);
      color: #63b67f;
      font-weight: 500;
      width: 120px;
    }
  }

  .denied {
    button {
      background: rgba(223, 55, 75, 0.15);
      color: #df374b;
      font-weight: 500;
      width: 120px;
    }
  }

  .MuiCheckbox-root {
    margin-right: 10px;
    .MuiSvgIcon-root {
      color: #bbbbbb !important;
    }
  }
`;

const mapStateToProps = ({ transactions }) => {
  return {
    fetchingTransactions: transactions.fetchingTransactions,
    transactions: transactions.transactions,
    meta: transactions.transactionsMeta,
  };
};
const Transactions = () => {
  const dispatch = useDispatch();

  const { fetchingTransactions, transactions, meta } = useSelector(mapStateToProps);

  const fetchTransactions = useCallback((params = { limit: 25 }) =>
    dispatch(fetchAdminTransactionsAction(params), [dispatch])
  );

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <StyledTransactions>
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 30,
          }}
        >
          <h5>Transaction Trail</h5>

          <div style={{ display: 'none' }}>
            <div style={{ marginRight: 10 }}>
              <Select
                size="tiny"
                options={[{ label: 'Merchants', value: 'merchants' }]}
                defaultValue="merchants"
                style={{ minWidth: 90 }}
                padding={0}
              />
            </div>
            <div style={{ marginRight: 10 }}>
              <Select
                size="tiny"
                options={[{ label: 'Monthly', value: 'monthly' }]}
                defaultValue="monthly"
                style={{ minWidth: 80 }}
                padding={0}
              />
            </div>
            <Button value="Download CSV" size="sm" />
          </div>
        </div>

        <Row>
          <Col>
            <Table
              // eslint-disable-next-line react/jsx-no-bind
              filterAction={fetchTransactions}
              filter={{
                Date: [
                  { value: 'Today', label: 'Today' },
                  { value: 'This Week', label: 'This Week' },
                  { value: 'This Month', label: 'This Month' },
                  { value: 'Last 30 Days', label: 'Last 30 Days' },
                  { value: 'This Year', label: 'This Year' },
                  { value: 'Period', label: 'Specific Period' },
                ],
                status: [
                  { value: 'Successful', label: 'Successful' },
                  { value: 'Failed', label: 'Failed' },
                  { value: 'Pending', label: 'Pending' },
                ],
                category: [
                  { value: 'SMS', label: 'SMS' },
                  { value: 'Airtime', label: 'Airtime' },
                  { value: 'Data', label: 'Data' },
                  { value: 'Data Topup', label: 'Data Topup' },
                ],
                transactionId: 'transactionId',
              }}
              showFilter
              header={[
                {
                  key: 'date',
                  label: 'Date',
                  sort: true,
                  formatter: (date) => (
                    <div>
                      <Moment format="D MMM, YYYY" date={date} />
                      <br />
                      <Moment format="h:mm:ssa" date={date} />
                    </div>
                  ),
                },
                {
                  key: 'firstName',
                  label: 'User',
                  sort: true,
                  formatter: (firstName, record) => (
                    <span>
                      {firstName} {record.lastName}
                    </span>
                  ),
                },
                {
                  key: 'transactionReference',
                  label: 'Transaction ID',
                  sort: true,
                },
                {
                  key: 'amount',
                  label: 'Amount',
                  sort: true,
                  formatter: (amount) => (
                    <NumberFormat
                      displayType="text"
                      thousandSeparator
                      prefix="₦"
                      value={amount}
                      className={amount > 0 ? 'green' : amount < 0 ? 'red' : ''}
                    />
                  ),
                },
                {
                  key: 'category',
                  label: 'Category',
                  sort: true,
                },
                {
                  key: 'status',
                  label: 'Status',
                  sort: true,
                  formatter: (status) => (
                    <span className={`bold ${status === 'Successful' ? 'green' : 'red'}`}>
                      {status}
                    </span>
                  ),
                },
              ]}
              data={transactions}
              loading={fetchingTransactions}
              paginate={Boolean(Object.keys(meta).length)}
              meta={{ ...meta, limit: 25 }}
            />
          </Col>
        </Row>
      </>
    </StyledTransactions>
  );
};

export default Transactions;
