import { useState } from 'react';
import { useQuery } from 'react-query';

// Components
import { Select } from '../../inputs';

// Utils
import { APIlistService } from '../../../api/backendRoutes';

const ServiceTypeSelect = ({ label, placeholder, name, validate, defaultValue, onChange }) => {
  const [options, setOptions] = useState([]);

  useQuery(
    ['service_type'],
    async () => {
      const response = await APIlistService();
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data.success) {
          const types = data?.data?.map((type) => ({
            label: type?.serviceTypeCategory || type?.serviceTypeName,
            value: type?._id,
            /* eslint no-underscore-dangle: 0 */
          }));
          setOptions([...types]);
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Select
      label={label}
      placeholder={placeholder}
      name={name}
      validate={validate}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
    />
  );
};

export default ServiceTypeSelect;
