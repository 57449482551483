import Tabs from '../../../../components/Tabs';
import Password from './Password';
import Profile from './Profile';

const TeamDetail = ({ name }) => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h5>{name}</h5>
      </div>
      <Tabs
        header={[
          { key: 'profile', label: 'Profile' },
          { key: 'password', label: 'Password' },
        ]}
        data={{
          profile: <Profile />,
          password: <Password />,
        }}
      />
    </>
  );
};

export default TeamDetail;
