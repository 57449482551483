import styled from 'styled-components';
import { NavDropdown } from 'react-bootstrap';
import Image from './Image';

const StyledAvatar = styled.div`
  height: inherit;

  .dropdown-toggle.nav-link {
    display: flex;
    align-items: center;
    background: #f5f5f5 45%;
    border-radius: 5px;
    padding: 5px !important;
  }

  @media screen and (max-width: 700px) {
    .dropdown-toggle::after {
      display: none !important;
    }
  }
`;

const StyledImage = styled.span`
  display: flex;
  align-items: center;
  margin-right: 10px;

  span {
    font-size: 14px;
  }
  @media screen and (max-width: 700px) {
    .username {
      display: none !important;
    }
  }
`;

const Avatar = ({ name, src, email, margin, options = [] }) => {
  return (
    <StyledAvatar>
      {options.length > 0 ? (
        <NavDropdown
          id="profile"
          title={
            <StyledImage>
              <Image src={src} width={38} height={38} round margin={margin || [0, 10, 0, 0]} />
              {name && (
                <div className="username" style={{ fontSize: 11 }}>
                  <div style={{ fontWeight: 600, color: '#555555' }}>{name}</div>
                  <div className="text-muted">{email}</div>
                </div>
              )}
            </StyledImage>
          }
        >
          {options.map(({ label, action }, key) => (
            <NavDropdown.Item onClick={() => action()} key={key}>
              {label}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      ) : (
        <StyledImage>
          <Image src={src} width={36} height={36} round margin={margin || [0, 10]} />
          {name && <div>{name}</div>}
        </StyledImage>
      )}
    </StyledAvatar>
  );
};

export default Avatar;
