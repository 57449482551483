/* eslint-disable */
import Toast from 'react-hot-toast';
import { APIServiceReports } from '../../api/backendRoutes';
import { errorParser } from '../../utilities/helper';
import {
  fetchAdminReportsSuccessReducer,
  fetchingAdminReportsReducer,
  fetchingAdminReportsErrorReducer,
} from '../slices/reports';

export const fetchAdminReportsAction =
  (params = {}) =>
  async (dispatch) => {
    dispatch(fetchingAdminReportsReducer());
    try {
      const resp = await APIServiceReports(params);
      if (resp.data.message === 'ok') {
        dispatch(fetchAdminReportsSuccessReducer(resp.data));
      } else {
        dispatch(fetchingAdminReportsErrorReducer());
        throw resp?.data?.error;
      }
    } catch (error) {
      Toast.error(errorParser(error) || 'error');
    }
  };
