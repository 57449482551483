/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auditTrails: [],
  fetchingAuditTrails: false,
  auditTrailsMeta: {},
};

export const auditsSlice = createSlice({
  name: 'audits',
  initialState,
  reducers: {
    fetchAdminAuditTrailsSuccessReducer: (state, { payload }) => {
      state.auditTrails = payload.data;
      state.fetchingAuditTrails = false;
      state.auditTrailsMeta = {
        limit: payload.limit,
        page: payload.page,
        total: payload.total,
      };
    },
    fetchingAdminAuditTrailsReducer: (state) => {
      state.fetchingAuditTrails = true;
      state.auditTrails = [];
      state.auditTrailsMeta = {};
    },
    fetchingAdminAuditTrailsErrorReducer: (state) => {
      state.fetchingAuditTrails = false;
      state.auditTrails = [];
      state.auditTrailsMeta = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchAdminAuditTrailsSuccessReducer,
  fetchingAdminAuditTrailsReducer,
  fetchingAdminAuditTrailsErrorReducer,
} = auditsSlice.actions;

export default auditsSlice.reducer;
