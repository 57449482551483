import { useContext } from 'react';
import { Route } from 'react-router-dom';
import { AppContext } from '../../../utilities/context/AppContext';
import { deleteUserData } from '../../../utilities/storage';
import AppWrapper from '../../layout/AppWrapper';
import Container from '../../layout/Container';
import { ROUTES } from '../routes/routes';

const PrivateRoute = ({
  component: Component,
  allowNavigation = false,
  showHeader = true,
  showSideMenu = true,
  onComponent,
  path,
  meta,
  ...rest
}) => {
  // useEffect(() => {
  const expiration = localStorage.getItem('expiration');
  if (expiration && expiration < Date.now()) {
    deleteUserData();
    localStorage.clear();
    setTimeout(() => {
      window.location.pathname = ROUTES.SIGN_IN.path;
    }, 200);
    // history.push(ROUTES.SIGN_IN.path);
  }
  // }, []);
  const { dataLoading } = useContext(AppContext);

  return (
    <>
      {dataLoading ? (
        <AppWrapper />
      ) : (
        <Route
          {...rest}
          path={path}
          render={(props) => (
            <Container showHeader={showHeader} showSideMenu={showSideMenu} meta={meta}>
              <Component {...props} {...onComponent} />
            </Container>
          )}
        />
      )}
    </>
  );
};

export { PrivateRoute };
