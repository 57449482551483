/* eslint-disable */
import styled from 'styled-components';
import BTable from 'react-bootstrap-table-next';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Flex, Box } from 'rebass';
import Icon from './Icon';
import Button from './Button';
import Pagination from './Pagination';
import { Input, Select } from './inputs';
import Loader from './Loader';
import { Spinner } from './Spinner';
import { Paginator } from './Paginator';
import { useState } from 'react';
import Filter from './filter/Filter';
import { ReactComponent as NoTransaction } from '../assets/images/no-transaction.svg';

const StyledTable = styled.div`
  height: inherit;
  border-radius: ${(props) => (!props.noBorder ? props.radius : 0)};
  overflow: hidden;
  position: relative;
  ${(props) => (props.noBorder ? 'border: none !important;' : 'border: 1px solid #dddddd;')}

  .react-bootstrap-table table {
    table-layout: auto !important;
  }

  table {
    margin-bottom: 0;
  }

  th,
  td {
    padding-left: 20px;
    height: 50px;
  }

  thead {
    // color: #ffffff;
    background: ${(props) => props.theadBg};
    // background: #eef1f3;
    // box-shadow: 0 5px 20px 0px rgb(0 0 0 / 10%);

    th {
      padding-top: 13px;
      padding-bottom: 13px;
      font-weight: 500;
      font-size: 13px;
      border: none;
      color: #17568b;
      // background: #f2f2f259;
      background: #509fef08;
    }

    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid #dddddd;
      border-right: 3px solid transparent;
      border-left: 3px solid transparent;
    }

    .dropup,
    .dropdown {
      position: relative;
    }

    .dropup .caret,
    .navbar-fixed-bottom .dropdown .caret {
      border-top: 0;
      border-bottom: 4px dashed;
      border-bottom: 4px solid #dddddd;
      content: '';
    }
  }

  tbody {
    background: #ffffff;

    tr {
      ${(props) => (props.rowEvents ? 'cursor: pointer;' : '')};

      &:first-child td {
        border-top: none;
      }

      &:hover {
        background-color: ${(props) => (props.rowEvents ? '#f4fdfc;' : '#ffffff')};
      }

      td {
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 12px;
        font-weight: 300;
        border-top: 1px solid #ebebeb;
        vertical-align: middle;
        background: #ffffff;
      }
    }
  }
`;

const Table = ({
  header,
  data,
  rowEvents,
  radius = '10px',
  theadBg = '#509fef08',
  hideHeader,
  noBorder,
  paginate,
  filterOptions,
  showFilter,
  filterAction = () => {},
  loading,
  filter,
  options,
  meta,
  emptyStateMessage,
  // handlePagination,
}) => {
  const [filterData, setFilterData] = useState({ ...meta });
  const handleFilter = () => {
    filterAction({ ...filterData, page: 0 });
  };
  const handlePagination = ({ selected }) => {
    filterAction({ ...filterData, page: selected });
  };
  const clearFilter = () => {
    filterAction({ limit: 25 });
    setFilterData({ ...meta, limit: 25 });
  };
  return (
    <>
      {showFilter && (
        <Filter
          action={handleFilter}
          filterData={filterData}
          filter={{
            ...filter,
            limit: [
              { value: 25, label: '25' },
              { value: 50, label: '50' },
              { value: 100, label: '100' },
            ],
          }}
          options={options}
          setFilterData={setFilterData}
          clearFilter={clearFilter}
        />
      )}
      <StyledTable noBorder={noBorder} radius={radius} theadBg={theadBg} rowEvents={!!rowEvents}>
        <BTable
          wrapperClasses="table-responsive"
          hover
          rowEvents={rowEvents}
          striped={false}
          bordered={false}
          keyField={header[0].key}
          // filter = {filterFactory()}
          columns={header.map(({ key: dataField, label: text, ...r }) => ({
            dataField,
            text,
            ...r,
            // filter:textFilter()
          }))}
          data={data}
        />
        {data?.length == 0 && (
          <Box p="2rem">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 40,
              }}
            >
              <NoTransaction />
              <div style={{ marginTop: 30 }}>{emptyStateMessage || ''}</div>
            </div>
          </Box>
        )}

        {loading && (
          <Flex p="2rem" alignItems="center" justifyContent="center">
            <Spinner />
          </Flex>
        )}
      </StyledTable>
      {/* {paginate && <Table.Pagination paginate={filterOptions} />} */}
      {paginate && (
        <Flex mt="1rem">
          <Paginator
            pageCount={Math.ceil(meta?.total / meta?.limit)}
            forcePage={meta.page}
            onPageChange={handlePagination}
          />
        </Flex>
      )}
    </>
  );
};

const StyledAction = styled.span.attrs(({ className }) => ({ className }))`
  cursor: pointer;
  color: #2659a2;
  background: #e0eefc;
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  &:not(last-child) {
    margin-right: 10px;
  }

  &.danger {
    color: #bd2a2a;
    background: #fce0e3;
  }
`;

Table.Actions = ({ actions = [] }) => {
  return (
    <div style={{ display: 'flex' }}>
      {actions.map(({ icon, type = '', name, title = '', action }, key) => (
        <StyledAction className={type} key={key} title={title}>
          {icon && <Icon name={icon} />}
          {name}
        </StyledAction>
      ))}
    </div>
  );
};

const StyledFilter = styled.div.attrs(({ className }) => ({ className }))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 4px;

  &:not(last-child) {
    margin-right: 10px;
  }

  &.danger {
    color: #bd2a2a;
    background: #fce0e3;
  }
`;

Table.Filter = ({ options = [{ type: 'number', name: 'search', label: '' }] }) => {
  return (
    <form style={{ display: 'flex', alignItems: 'center' }}>
      {options.map(({ type = 'text', ...option }, key) => (
        <StyledFilter key={key}>
          {type !== 'select' && <Input type={type} {...option} />}
          {type === 'select' && <Select {...option} />}
        </StyledFilter>
      ))}
      <Button value="Filter" size="md" icon="filter" margin={[0, 0, 20, 0]} />
    </form>
  );
};

const StyledPagination = styled.div.attrs(({ className }) => ({ className }))`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

Table.Pagination = ({ paginate }) => {
  return (
    <StyledPagination>
      <Pagination paginate={paginate} />
    </StyledPagination>
  );
};

export default Table;
