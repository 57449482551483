import { useState } from 'react';
import Toast from 'react-hot-toast';
import { Col, Row } from 'react-bootstrap';
import { Country as Countries } from 'country-state-city';
import { useMutation, useQueryClient } from 'react-query';

// Components
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import { Input, Select } from '../../../../components/inputs';
import ServiceTypeSelect from '../../../../components/Select/ServiceTypeSelect/ServiceTypeSelect';

// Utils
import { errorParser } from '../../../../utilities/helper';
import { APIcreateService } from '../../../../api/backendRoutes';

const CreateServiceModal = ({ show, closeModal, partner }) => {
  const queryClient = useQueryClient();

  const [payload, setPayload] = useState([{ key: '', value: '' }]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [header, setHeader] = useState([{ key: '', value: '' }]);
  const [serviceData, setServiceData] = useState({
    serviceName: '',
    apiEndpoint: '',
  });
  const [err, setErr] = useState([false, '']);

  const countries = Countries.getAllCountries().map((data) => ({
    label: data?.name,
    value: data?.name,
    ...data,
  }));

  const addPayload = () => {
    const newPayload = [...payload];
    newPayload.push({ key: '', value: '' });
    setPayload(newPayload);
  };
  const addHeader = () => {
    const newHeader = [...header];
    newHeader.push({ key: '', value: '' });
    setHeader(newHeader);
  };
  const showPayload = () => {
    const handlePayloadChange = ({ name, value, idx }) => {
      const newPayload = [...payload];
      const update = newPayload[idx];
      update[name] = value;
      newPayload[idx] = update;
      setPayload(newPayload);
    };
    return payload.map((load, idx) => (
      <Row key={idx}>
        <Col md={6}>
          <Input
            label="Key"
            placeholder="Enter Payload Key"
            name="key"
            type="text"
            defaultValue={payload[idx].key}
            validate="required"
            onChange={handlePayloadChange}
            idx={idx}
          />
        </Col>
        <Col md={6}>
          <Select
            label="payload"
            placeholder="Enter Payload Type"
            name="value"
            type="text"
            defaultValue={payload[idx].value}
            validate="required"
            onChange={handlePayloadChange}
            idx={idx}
            options={[
              { value: 'String', label: 'String' },
              { value: 'Number', label: 'Number' },
              { value: 'api', label: 'API' },
            ]}
          />
        </Col>
      </Row>
    ));
  };
  const showHeader = () => {
    const handleHeaderChange = ({ name, value, idx }) => {
      const newheader = [...header];
      const update = newheader[idx];
      update[name] = value;
      newheader[idx] = update;
      setHeader(newheader);
    };
    return header.map((head, idx) => (
      <Row key={idx}>
        <Col md={6}>
          <Input
            label="Header"
            placeholder="Enter Header"
            name="key"
            type="text"
            validate="required"
            value={header[idx].key}
            onChange={handleHeaderChange}
            idx={idx}
          />
        </Col>
        <Col md={6}>
          <Input
            label="value"
            placeholder="Enter Header Value"
            name="value"
            type="text"
            validate="required"
            value={header[idx].value}
            onChange={handleHeaderChange}
            idx={idx}
          />
        </Col>
      </Row>
    ));
  };
  const handleInputChange = ({ name, value }) => {
    if (name === 'country') {
      setSelectedCountry(countries.find((cont) => cont.value === value));
    }
    setServiceData({
      ...serviceData,
      [name]: value,
    });
  };

  const { mutate, isLoading } = useMutation(
    async (obj) => {
      const response = await APIcreateService(obj);

      return response;
    },
    {
      onSuccess: ({ data }) => {
        if (data.success) {
          queryClient.invalidateQueries(['services']);
          queryClient.invalidateQueries(['pricing_model']);
          Toast.success('Service Created');
          setServiceData({
            serviceName: '',
            apiEndpoint: '',
            serviceType: '',
            method: '',
            price: '',
            pricingType: '',
            pricingValue: '',
          });
          setPayload([{ key: '', value: '' }]);
          setHeader([{ key: '', value: '' }]);
          setSelectedCountry({});
          closeModal();
        } else Toast.error("Error Can't Create Service");
      },
      onError: (error) => Toast.error(errorParser(error)),
    }
  );

  const handleAddService = async () => {
    setErr([false, '']);

    const computedData = serviceData;
    if (Object.values(computedData).includes('')) {
      setErr([true, 'Please fill all fields']);
      return;
    }
    header.map((data) => {
      return (computedData.headers = {
        ...computedData.header,
        [data.key]: data.value,
      });
    });

    if (Object.values(computedData.headers).includes('')) {
      setErr([true, 'Please fill all fields']);
      return;
    }

    payload.map((data) => {
      return (computedData.payload = {
        ...computedData.payload,
        [data.key]: data.value,
      });
    });

    if (Object.values(computedData.payload).includes('')) {
      setErr([true, 'Please fill all fields']);
      return;
    }

    if (computedData?.pricingType === 'Discount' && computedData?.pricingValue > 100) {
      setErr([true, "Pricing Value can't be more than 100 if discount has been selected"]);
      return;
    }

    const newServicedata = {
      ...computedData,
      serviceOwnerId: partner.id,
      providerName: partner.firstName,
      companyId: partner?.companyInformation?.[0]?.id,
      validationAPIMethod: serviceData.method,
      priceModel: {
        type: computedData?.pricingType,
        [computedData?.pricingType === 'Discount' ? 'value' : 'price']: computedData?.pricingValue,
        country: selectedCountry.name,
        currency: selectedCountry.currency,
        countryCode: selectedCountry.phonecode,
      },
      sampleResponse: {},
    };

    delete newServicedata?.country;

    if (computedData?.pricingType === 'Flat') {
      newServicedata.priceModel.cost = newServicedata.price;
    }

    delete newServicedata?.pricingType;
    delete newServicedata?.pricingValue;

    mutate(newServicedata);
  };

  return (
    <Modal size="lg" show={show} onClose={closeModal} title="Create Service">
      <div style={{ padding: '20px' }}>
        <Row>
          <Col md={8}>
            <Input
              label="Service Name"
              placeholder="Enter Service Name"
              name="serviceName"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={serviceData.serviceName}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <ServiceTypeSelect
              label="Service Type"
              placeholder="Enter Service Type"
              name="serviceType"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={serviceData.serviceType}
            />
          </Col>
          <Col md={8}>
            <Select
              label="Country"
              placeholder="Select Country"
              name="country"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={serviceData.country}
              options={countries}
            />
          </Col>
          <Col md={8}>
            <Select
              label="Method"
              placeholder="Enter Service Method"
              name="method"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={serviceData.method}
              options={[
                { label: 'POST', value: 'POST' },
                { label: 'GET', value: 'GET' },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Input
              label="Api End Point"
              placeholder="Enter End Point"
              name="apiEndpoint"
              type="text"
              validate="required"
              onChange={handleInputChange}
              defaultValue={serviceData.apiEndpoint}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Input
              label="Cost"
              placeholder="Enter Service Cost"
              name="price"
              type="number"
              validate="required"
              onChange={handleInputChange}
              defaultValue={serviceData.price}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Select
              label="Select Pricing Type *"
              placeholder="Select Pricing Type"
              name="pricingType"
              validate="required"
              defaultValue={serviceData.pricingType}
              onChange={handleInputChange}
              options={[
                { label: 'Discount', value: 'Discount' },
                { label: 'Flat', value: 'Flat' },
              ]}
            />
          </Col>
          <Col md={4}>
            <Input
              label="Enter Pricing Value"
              placeholder="Enter Pricing Value"
              name="pricingValue"
              type="number"
              validate="required"
              defaultValue={serviceData.pricingValue}
              onChange={handleInputChange}
            />
          </Col>
        </Row>

        {/* {serviceData.pricingType === 'Flat' && (
        <>
          <Row>
            <Col>
              <Input
                label="Price"
                placeholder="Enter Price"
                name="pricingPrice"
                type="number"
                validate="required"
                defaultValue={serviceData.pricingPrice}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Input
                label="Cost"
                placeholder="Enter Cost"
                name="cost"
                type="number"
                validate="required"
                defaultValue={formData.cost}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </>
      )} */}

        <Row>
          <Col md={8}>{showHeader()}</Col>
          <Col
            md={2}
            style={{
              alignSelf: 'center',
            }}
          >
            <Button value="Add Header" group="secondary" onClick={() => addHeader()} />
          </Col>
        </Row>
        <Row>
          <Col md={8}>{showPayload()}</Col>
          <Col
            md={2}
            style={{
              alignSelf: 'center',
            }}
          >
            <Button value="Add Payload" group="secondary" onClick={() => addPayload()} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button value="Add Service" onClick={() => handleAddService()} loading={isLoading} />
          </Col>
        </Row>
        {err && (
          <div
            style={{
              color: 'red',
              fontSize: '12px',
            }}
          >
            {err[1]}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateServiceModal;
