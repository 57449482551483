import { useState } from 'react';
import Button from '../../../../../components/Button';
import CreatePriceModelModal from '../modals/CreatePriceModelModal';

const CreatePriceModelBtn = ({ partner }) => {
  const [isOpen, setIsOpen] = useState();

  const toggle = () => setIsOpen((prev) => !prev);
  return (
    <>
      <Button group="secondary round" width={150} value="Create Pricing Model" onClick={toggle} />
      {isOpen && <CreatePriceModelModal show={isOpen} onClose={toggle} partner={partner} />}
    </>
  );
};

export default CreatePriceModelBtn;
